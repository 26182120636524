import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';
import { InputButton, InputPassword } from '../../components/controls';
import { ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useForm } from '../../hooks';
import { mutationChangePassword } from '../../services';
import { GENERAL_CONSTANTS, REGEX_PATTERNS } from '../../utilities/constants';

let hintText = `Password must be at least 8 characters long, contain at least one lower case letter, one upper case letter, one digit, and one special character.`;
const pageTitle = [{ key: 'Change Password', content: 'Change Password', active: true }];

type ChangePasswordForm = {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const navigate = useNavigate();

  const [isPasswordValid, setPasswordValid] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const { user: currentLoggedInUser } = useContext(GlobalContext);

  const renderCurrentPasswordError = () => {
    return errorMessage && errorMessage.includes('Invalid Password.') ? 'Password entered does not match current password.' : '';
  };
  const [changePassword, { loading }] = useMutation(mutationChangePassword, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message } = graphQLErrors[0];

        if (message === 'Invalid User details.' || message === 'Invalid Password.') {
          setErrorMessage(message);
        } else {
          setErrorMessage(GENERAL_CONSTANTS.unexpectedErrorReachSupport);
        }
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        } else {
          setErrorMessage(GENERAL_CONSTANTS.unexpectedErrorReachSupport);
        }
      }
    },
  });

  const { onChange, formState } = useForm<ChangePasswordForm>({} as ChangePasswordForm);

  const { currentPassword, newPassword, confirmPassword } = formState;

  const isFormValid = (): boolean => {
    let isValid = true;

    if (!currentPassword || !newPassword || !confirmPassword || !isPasswordValid) {
      isValid = false;
    }

    if (newPassword !== confirmPassword) {
      isValid = false;
    }

    return isValid;
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!isFormValid()) {
      return;
    }
    renderCurrentPasswordError();

    const result = await changePassword({
      variables: {
        changePasswordInput: {
          currentPassword,
          newPassword: confirmPassword,
          email: currentLoggedInUser?.email,
        },
      },
    });

    if (result.data) {
      return navigate('/', { replace: true });
    }
  };

  return (
    <>
      <Item as="div" className="Provider-Form-Page body-content">
        <ProviderSubHeader pageTitle={pageTitle} />
        <Container fluid>
          <Item as="div" className="content" style={{ padding: 25 }}>
            <Header as="h5" textAlign="center">
              Enter your current password, enter a new password, and confirm your new password.
            </Header>
            <Form>
              {errorMessage && errorMessage !== 'Invalid Password.' && (
                <Header block className="error" image={errorIcon} color="red" content={errorMessage} />
              )}
              <InputPassword
                name="currentPassword"
                label="CURRENT PASSWORD"
                placeholder="Current Password"
                required
                value={currentPassword}
                onChange={({ target: { name, value } }) => {
                  onChange(name, value);
                }}
                error={formSubmitted && renderCurrentPasswordError()}
              />
              <InputPassword
                AddClass={formSubmitted && !isPasswordValid ? 'error-field' : ''}
                name="newPassword"
                label="NEW PASSWORD"
                placeholder="New Password"
                required
                hint={hintText}
                value={newPassword}
                onChange={({ target: { name, value } }) => {
                  setPasswordValid(REGEX_PATTERNS.password.test(value));
                  onChange(name, value);
                }}
              />
              <InputPassword
                name="confirmPassword"
                label="CONFIRM PASSWORD"
                placeholder="Confirm Password"
                required
                value={confirmPassword}
                onChange={({ target: { name, value } }) => {
                  onChange(name, value);
                }}
                error={formSubmitted && newPassword !== confirmPassword && 'Passwords must match.'}
              />

              <InputButton
                loading={loading}
                text="CONTINUE"
                fluid
                requiredHintText
                addCssClasses="mb-0 empty-label"
                onClick={handleFormSubmit}
                disabled={!currentPassword || !newPassword || !confirmPassword}
              />
              <InputButton
                text="CANCEL"
                fluid
                addCssClasses="btn-secondary empty-label mb-0"
                onClick={() => navigate('/', { replace: true })}
              />
            </Form>
          </Item>
        </Container>
      </Item>
    </>
  );
};

export default ChangePassword;
