import { createContext, useState } from 'react';
import { User } from '../../../types';

type AccountManagementContextType = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

type AccountManagementContextProviderProps = {
  children: React.ReactNode;
};

export const AccountManagementContext = createContext<AccountManagementContextType | null>(null);

export const AccountManagementContextProvider = ({ children }: AccountManagementContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);

  return <AccountManagementContext.Provider value={{ user, setUser }}>{children}</AccountManagementContext.Provider>;
};
