import { gql } from '@apollo/client/core';

export default gql`
  query FetchRoles {
    roles {
      id
      code
      name
    }
  }
`;
