import { gql } from '@apollo/client/core';

export default gql`
  query FetchAudits($searchOptions: SearchAuditDto!) {
    audits(searchOptions: $searchOptions) {
      totalRecords
      audits {
        id
        createdBy
        createdAt
        updatedBy
        updatedAt
        actionType {
          id
          code
          description
        }
        patient {
          id
          fullName
          brightreePatientId
          brightreeLink
        }
        provider {
          id
          name
          originalName
          brightreeNavigationLink
        }
        user {
          id
          fullName
          email
        }
        additionalInfo
      }
    }
  }
`;
