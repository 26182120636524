import { Link } from 'react-router-dom';
import { Checkbox, Form, Item } from 'semantic-ui-react';
import { PAGE_LINKS } from '../../../../utilities';
import './InputTermPolicy.scss';

interface InputTermPolicyProps {
  label?: string;
  inline?: any;
  required?: any;
  addClasses?: string;
  onChange: (isChecked: boolean) => void;
  error?: React.ReactNode;
}

export function InputTermPolicy({ label, inline, addClasses, onChange, error }: InputTermPolicyProps) {
  return (
    <Form.Field className={`input-term-policy ${addClasses}`} inline={inline}>
      {inline ? <label>{label}</label> : ''}
      <Item>
        <Item as="div" className="checkboxItem">
          <Checkbox
            onChange={(_, data) => onChange(data.checked || false)}
            label={
              <>
                <span className="star">*</span>
                <label></label>
              </>
            }
          />
          <label>
            I agree to the{' '}
            <Link to={PAGE_LINKS.termOfUse} target="_blank">
              Terms of Use
            </Link>{' '}
            and
            <Link to={`${PAGE_LINKS.termOfUse}#betterNight-policy`} target="_blank">
              {' '}
              Privacy Policy
            </Link>{' '}
          </label>
        </Item>
        {error && (
          <Item as="div" className="error">
            {error}
          </Item>
        )}
      </Item>
    </Form.Field>
  );
}
