import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Image, Item } from 'semantic-ui-react';
import EditIcon from '../../assets/img/edit-icon.svg';
import { Loading, ProviderSubHeader } from '../../components/shared';
import { mutationUpdateGlossaryPatientStatus, queryPatientStatusGlossary } from '../../services';
import { GlossaryPatientStatus } from '../../types';
import './Glossary.scss';
import { GlossaryEdit, GlossaryEditProp } from './GlossaryEdit';

const pageTitle = [{ key: 'Glossary', content: 'Glossary', active: true }];

const glossaryEditOptions = {
  showPopup: false,
  closeOnEscape: false,
  closeOnDimmerClick: false,
  data: {} as GlossaryPatientStatus,
};

function Glossary() {
  const [isLoading, setLoading] = useState(true);
  const toastId = useRef<string | number | null>(null);

  const [GlossaryEditModal, setGlossaryEditModal] = useState<GlossaryEditProp>(glossaryEditOptions);
  const [glossaries, setGlossaries] = useState<Array<GlossaryPatientStatus>>({} as Array<GlossaryPatientStatus>);

  const [fetchPatientStatusGlossary, { loading }] = useLazyQuery(queryPatientStatusGlossary, {
    fetchPolicy: 'no-cache',
  });

  const [updatePatientStatus] = useMutation(mutationUpdateGlossaryPatientStatus);

  useEffect(() => {
    const getGlossaries = async () => {
      const result = await fetchPatientStatusGlossary();

      const glossaryPatientStatuses = result?.data?.patientStatuses;
      setGlossaries(glossaryPatientStatuses);
    };
    getGlossaries();
  }, [fetchPatientStatusGlossary]);

  const groupedData = _.chain(glossaries).groupBy('patientStatus').value();

  const result = new Map();
  for (const patientStatus in groupedData) {
    const group = groupedData[patientStatus];

    result.set(patientStatus, {
      id: _.uniq(_.map(group, 'id')).join(', '),
      patientStatus: patientStatus,
      patientStatusDefinition: _.uniq(_.map(group, 'patientStatusDefinition')).join(', '),
      stateId: _.uniq(_.map(group, 'stateId')).join(', ') || 'N/A',
      stateCode: _.uniq(group.map((rec) => rec.stateCode)).join(', '),
      stateDescription: _.uniq(_.map(group, 'stateDescription')).join(', '),
    });
  }

  const handleEditClick = (editRecord: GlossaryPatientStatus) => {
    setGlossaryEditModal({
      showPopup: true,
      closeOnEscape: true,
      closeOnDimmerClick: true,
      data: editRecord,
      onSave: async (updatedData: GlossaryPatientStatus[]) => {
        setLoading(true);
        const masterGlossaries = [...glossaries];

        const updatedRecords: unknown[] = [];

        updatedData.forEach((updatedPatientStatus) => {
          const updatedRecordIndex = glossaries.findIndex((rec) => rec.stateCode === updatedPatientStatus.stateCode);

          if (updatedRecordIndex !== -1) {
            masterGlossaries[updatedRecordIndex].patientStatus = updatedPatientStatus.patientStatus;
            masterGlossaries[updatedRecordIndex].patientStatusDefinition = updatedPatientStatus.patientStatusDefinition;

            updatedRecords.push(
              updatePatientStatus({
                variables: {
                  id: masterGlossaries[updatedRecordIndex].id,
                  patientStatus: updatedPatientStatus.patientStatus,
                  patientStatusDefinition: updatedPatientStatus.patientStatusDefinition,
                },
              }),
            );
          }
        });

        const updatedRecordsResult = await Promise.all(updatedRecords);

        if (updatedRecordsResult) {
          setGlossaries([
            ...masterGlossaries.sort((a, b) => {
              const aValue = a.patientStatus.toLowerCase().trim();
              const bValue = b.patientStatus.toLowerCase().trim();

              if (aValue < bValue) {
                return -1;
              } else if (aValue > bValue) {
                return 1;
              }

              return 0;
            }),
          ]);
          setLoading(false);
          setGlossaryEditModal({ ...glossaryEditOptions, showPopup: false, data: {} as GlossaryPatientStatus });

          const message = `Saved`;

          if (toastId.current !== null) {
            toast.dismiss(toastId.current);
          }

          toastId.current = toast(message);
        }
      },
      onClose: () => {
        setGlossaryEditModal({ ...glossaryEditOptions, showPopup: false, data: {} as GlossaryPatientStatus });

        const message = `Canceled`;

        if (toastId.current !== null) {
          toast.dismiss(toastId.current);
        }

        toastId.current = toast(message);
      },
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Item as="div" className="glossary body-content">
      <ProviderSubHeader pageTitle={pageTitle} />
      {isLoading || loading ? (
        <Loading show={true} />
      ) : (
        <Container fluid>
          <Item as="div" className="content">
            <Item as="ul">
              {result &&
                Array.from(result.entries()).map(([key, value]) => {
                  return (
                    <Item as="li" key={key}>
                      <label htmlFor="">{key}</label>
                      <p>{value.patientStatusDefinition}</p>
                      <Image src={EditIcon} onClick={() => handleEditClick(value)} />
                    </Item>
                  );
                })}
            </Item>
          </Item>
        </Container>
      )}

      {GlossaryEditModal.showPopup && <GlossaryEdit {...GlossaryEditModal} />}
    </Item>
  );
}
export default Glossary;
