import { Button, Form, Item, SemanticSIZES, SemanticShorthandItem, IconProps } from 'semantic-ui-react';
import './InputButton.scss';

export interface InputButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'reset' | 'submit',
  name?: string;
  text?: string;
  size?: SemanticSIZES;
  loading?: boolean;
  fluid?: boolean;
  inline?: boolean;
  addCssClasses?: string;
  requiredHintText?: Boolean;
  circular?: boolean;
  icon?: SemanticShorthandItem<IconProps>;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
}

export const InputButton = ({
  loading,
  type,
  text,
  fluid,
  size,
  inline = true,
  addCssClasses,
  requiredHintText,
  circular,
  icon,
  disabled,
  onClick,
  name,
}: InputButtonProps) => {
  return (
    <Form.Field className={addCssClasses} inline={inline} key={name}>
      {inline ? <label></label> : ''}
      <Item as="div">
        {requiredHintText ? <small>* indicates a required field</small> : ''}
        <Button
          type={type}
          loading={loading}
          onClick={onClick}
          disabled={disabled}
          fluid={fluid}
          size={size}
          circular={circular}
          content={text}
          icon={icon}
        />
      </Item>
    </Form.Field>
  );
};
