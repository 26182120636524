import { gql } from '@apollo/client/core';

export default gql`
  query FetchUsers($searchOptions: SearchUsersDto!) {
    users(searchOptions: $searchOptions) {
      totalRecords
      users {
        id
        firstName
        lastName
        fullName
        email
        isActive
        lastLoggedInAt
        isProvider
        updatedAt
        termsAcceptedAt
        useSalutation
        brightreeLoginId
        provider {
          id
          name
          group {
            id
            name
            originalName
            isCatchGroup
          }
        }
        role {
          id
          code
          name
        }
      }
    }
  }
`;
