import { Container, Item } from 'semantic-ui-react';
import { ProviderFooter, ProviderHeader, ProviderSubHeader } from '../../components/shared';

const PageTitle = [{ key: 'Two-Factor authentication', content: 'Two-Factor authentication ', active: true }];

const LoginInstruction = () => {
  const goBack = () => {
    window.close();
  };

  return (
    <Item as="div" className="two-factor-auth">
      <ProviderHeader />
      <ProviderSubHeader pageTitle={PageTitle} />
      <Container fluid>
        <Item as="div" className="content">
          <p>
            An email has been sent to the email address that you used to login with secure links into the BetterNight Provider Portal. Check your
            spam or junk folder if you do not see it in your inbox.
            <span className="link" onClick={goBack}>
              {' '}
              Close tab
            </span>
          </p>
        </Item>
      </Container>
      <ProviderFooter />
    </Item>
  );
};
export default LoginInstruction;
