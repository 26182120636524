import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Form, Item } from 'semantic-ui-react';

interface InputSelectProps {
  name: string;
  placeholder?: string;
  type?: string;
  label?: string;
  addClass?: string;
  inline?: boolean;
  required?: boolean;
  loading?: boolean;
  hint?: any;
  fluid?: boolean;
  options: DropdownItemProps[];
  error?: any;
  value?: string | number;
  compact?: boolean;
  icon?: string;
  selection?: 'selection' | 'basic';
  search?: boolean;
  upward?: boolean;
  clearable?: boolean;
  onChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
}

export const InputSelect = ({
  loading = false,
  name,
  placeholder,
  addClass,
  inline = true,
  required,
  label,
  options,
  error,
  value,
  compact,
  icon,
  selection,
  search,
  clearable,
  upward,
  onChange,
}: InputSelectProps) => {
  return (
    <Form.Field className={addClass} inline={inline} required={required}>
      {label ? <label>{label}</label> : ''}
      <Item as="div">
        <Dropdown
          loading={loading}
          compact={compact}
          search={search}
          selection={selection}
          scrolling
          fluid
          clearable={clearable}
          icon={icon}
          upward={upward}
          name={name}
          value={value ? value?.toString() : ''}
          placeholder={placeholder}
          options={options}
          selectOnNavigation={false}
          selectOnBlur={false}
          onChange={onChange}
        />
        {error ? (
          <Item as="div" className="error">
            {error}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </Form.Field>
  );
};
