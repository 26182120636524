import { Modal, ModalProps } from 'semantic-ui-react';
import { InputButton, InputButtonProps } from '../../controls';
import './ModalPopup.scss';

export type ModalPopupProps = {
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  title?: string;
  body: React.ReactNode;
  showPopup: boolean;
  closeOnEscape?: boolean;
  closeOnDimmerClick?: boolean;
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>, data?: ModalProps) => void;
  actionButtons?: InputButtonProps[];
  defaultModal?: string;
  inline?: boolean;
};

const ModalPopup = ({
  showPopup,
  size = 'small',
  title,
  body,
  closeOnDimmerClick = false,
  closeOnEscape = false,
  onClose,
  actionButtons,
  defaultModal,
  inline,
}: ModalPopupProps) => {
  const renderActionButtons = () => {
    if (!actionButtons) {
      return <InputButton addCssClasses="" name="close" text="Close" onClick={(e) => onClose && onClose(e)} inline={inline} />;
    }

    const buttons = actionButtons.map(({ name, text, onClick, addCssClasses: AddClass }, index) => {
      return <InputButton key={index} addCssClasses={AddClass} name={name} text={text} onClick={onClick} inline={inline} />;
    });

    return <>{buttons}</>;
  };

  return (
    <Modal
      size={size}
      closeOnEscape={closeOnEscape}
      closeOnDimmerClick={closeOnDimmerClick}
      open={showPopup}
      onClose={onClose}
      className={defaultModal}
    >
      {title && <Modal.Header>{title}</Modal.Header>}
      <Modal.Content dangerouslySetInnerHTML={{ __html: body }}></Modal.Content>
      <Modal.Actions className={actionButtons && actionButtons?.length > 1 ? 'has-custom-buttons' : ''}>
        {renderActionButtons()}
      </Modal.Actions>
    </Modal>
  );
};

export default ModalPopup;
