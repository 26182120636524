import { useLazyQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components/shared';
import { useRenewSession } from '../hooks';
import { queryProviders, queryRoles } from '../services';
import { Provider, Role, User } from '../types';
import { GENERAL_CONSTANTS, PAGE_LINKS } from '../utilities/constants';

type LoginData = {
  accessToken: string;
  user: User;
};

type GlobalContextType = {
  user: User | null;
  login: (loginData: LoginData) => void;
  logout: () => void;

  roles: Role[];
  providers: Provider[];

  maxSessionTime: number;
  isSessionAboutToExpire: boolean;
  setSessionAboutToExpire: (flag: boolean) => void;
  extendSession: () => void;

  setUser: (user: User | null) => void;
  setLoader: (flag: boolean) => void;
  setMaxSessionTime: (maxSessionTime: number) => void;

  showChangesDiscardWarningModal: boolean;
  setShowChangesDiscardWarningModal: (flag: boolean) => void;

  isPageInEditMode: boolean;
  setPageIsInEditMode: (flag: boolean) => void;

  shouldAllowSelectedNavigation: boolean;
  setAllowSelectedNavigation: (flag: boolean) => void;
};

const initialData = {
  user: null,
};

type GlobalContextProviderProps = {
  children: ReactNode;
};

export const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);

const authToken = sessionStorage.getItem(GENERAL_CONSTANTS.authToken);
if (authToken) {
  const decodedToken: any = jwtDecode(authToken || '');

  if (decodedToken.exp * 1000 < Date.now()) {
    sessionStorage.removeItem(GENERAL_CONSTANTS.authToken);
  } else {
    initialData.user = decodedToken.user;
  }
}

export const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const navigate = useNavigate();
  const [showChangesDiscardWarningModal, setShowChangesDiscardWarningModal] = useState(false);
  const [isPageInEditMode, setPageIsInEditMode] = useState(false);
  const [shouldAllowSelectedNavigation, setAllowSelectedNavigation] = useState(true);

  const [isSessionAboutToExpire, setSessionAboutToExpire] = useState<boolean>(false);
  const [isInitialDataLoading, setInitialDataLoading] = useState(false);

  const [initialExecutionCount, setInitialExecutionCount] = useState(0);

  const [maxSessionTime, setMaxSessionTime] = useState(0);
  const [showLoader, setLoader] = useState(false);
  const [roles, setRoles] = useState<Role[]>([] as Role[]);
  const [providers, setProviders] = useState<Array<Provider>>([] as Array<Provider>);
  const [user, setUser] = useState<User | null>(initialData.user);

  const { extendSession: onExtendSession } = useRenewSession();

  const [getRoles] = useLazyQuery(queryRoles);
  const [getProviders] = useLazyQuery(queryProviders, {
    fetchPolicy: 'no-cache',
  });

  const userToken = sessionStorage.getItem(GENERAL_CONSTANTS.authToken);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadInitialData = async () => {
    setLoader(true);
    const promiseResult = await Promise.all([getProviders(), getRoles()]);
    setProviders(promiseResult[0].data.providers || []);
    setRoles(promiseResult[1].data.roles || []);
    setInitialDataLoading(false);
    setLoader(false);
  };

  async function login(loginData: LoginData) {
    setPageIsInEditMode(false);
    const decodedToken: any = jwtDecode(loginData.accessToken || '');
    if (decodedToken?.isTwoFactorAuthenticationCompleted) {
      sessionStorage.setItem(GENERAL_CONSTANTS.authToken, loginData.accessToken);
      setUser(decodedToken?.user);
      setMaxSessionTime(+decodedToken.maxSessionTime);
      navigate(PAGE_LINKS.home);
    } else {
      window.open(PAGE_LINKS.loginInstruction);
    }
  }

  async function logout() {
    try {
      setPageIsInEditMode(false);
      
      setLoader(true);
      setSessionAboutToExpire(false);

      setUser(null);
      setProviders([]);

      return true;
    } catch (error) {
      console.error('Error occurred while logging out:', error);
    } finally {
      sessionStorage.removeItem(GENERAL_CONSTANTS.authToken);
      setLoader(false);
    }
  }

  const extendSession = async () => {
    const response = await onExtendSession();
    sessionStorage.setItem(GENERAL_CONSTANTS.authToken, response.data.renewSession.accessToken);
    setSessionAboutToExpire(false);
  };

  useEffect(() => {
    if (userToken && user && !isInitialDataLoading && (providers.length === 0 || roles.length === 0) && initialExecutionCount < 3) {
      loadInitialData();
      setInitialDataLoading(true);
      setInitialExecutionCount((prevCount) => prevCount + 1);
    }
  }, [loadInitialData, providers.length, roles.length, userToken, user, isInitialDataLoading, initialExecutionCount]);

  return (
    <>
      <GlobalContext.Provider
        value={{
          user,
          roles,
          providers,
          maxSessionTime,
          isSessionAboutToExpire,
          login,
          logout,
          setSessionAboutToExpire,
          extendSession,
          setLoader,
          setUser,
          setMaxSessionTime,
          isPageInEditMode,
          setPageIsInEditMode,
          showChangesDiscardWarningModal,
          setShowChangesDiscardWarningModal,
          shouldAllowSelectedNavigation,
          setAllowSelectedNavigation,
        }}
      >
        {children}
        {<Loading show={showLoader} />}
      </GlobalContext.Provider>
    </>
  );
};
