import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { ModalPopup, RequireAuth } from './components/shared';
import { Layout, PublicLayout } from './components/shared/Layouts';
import SessionExpiryModal from './components/shared/SessionExpiryModal/SessionExpiryModal';
import { GlobalContext } from './context/GlobalContext';
import { useAccountLogout } from './hooks';
import {
  AccountActivation,
  AccountManagement,
  AdminUserForm,
  Administrators,
  ComingSoon,
  Home,
  LinkValidation,
  Login,
  LoginInstruction,
  LoginLinkExpired,
  MissingInformation,
  PatientStatus,
  ProviderUserForm,
  Providers,
  TermOfUse,
  Unauthorized,
} from './pages';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Glossary from './pages/Glossary/Glossary';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import { PAGE_LINKS, ROLES } from './utilities';

function App() {
  const {
    isSessionAboutToExpire,
    setSessionAboutToExpire,
    logout,
    extendSession,
    showChangesDiscardWarningModal,
    setAllowSelectedNavigation,
    setShowChangesDiscardWarningModal,
    setPageIsInEditMode,
  } = useContext(GlobalContext);
  const { accountLogout } = useAccountLogout();

  const handleLogout = () => {
    accountLogout();
    logout();
  };

  const handleExtendSession = async () => {
    setSessionAboutToExpire(false);
    await extendSession();
  };

  const handlePageChangeModalClose = async (action: string) => {
    setShowChangesDiscardWarningModal(false);
    if (action === 'yes') {
      setAllowSelectedNavigation(true);
      setPageIsInEditMode(false);
    } else {
      setAllowSelectedNavigation(false);
    }
    console.log(action);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Public Routes */}
          <Route path="login" element={<Login />} />
          <Route path="account-activation/:activationToken" element={<AccountActivation />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:resetPasswordToken" element={<ResetPassword />} />
          <Route path="secondary-authentication/:twoFactorPasswordToken" element={<LinkValidation />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="login-instruction" element={<LoginInstruction />} />
          <Route path="login-link-expired" element={<LoginLinkExpired />} />

          <Route element={<PublicLayout />}>
            <Route path={PAGE_LINKS.termOfUse} element={<TermOfUse />} />

            <Route path="privacy-policy" element={<ComingSoon />} />
            <Route path="coming-soon" element={<ComingSoon />} />
          </Route>

          {/* Protected Routes */}
          <Route element={<RequireAuth allowedRoles={Object.values(ROLES)} />}>
            <Route path="/" element={<Home />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.superadmin, ROLES.missinginformation, ROLES.accountadmin]} />}>
            <Route path="missing-information" element={<MissingInformation />}>
              <Route index path="patient" element={<MissingInformation />} />
              <Route path="contacting" element={<MissingInformation />} />
              <Route path="final-review" element={<MissingInformation />} />
              <Route path="failed-notifications" element={<MissingInformation />} />
              <Route path="bt-upload-errors" element={<MissingInformation />} />
              <Route path="contact-outside-email-fax" element={<MissingInformation />} />
              <Route path="sugar-contact-required" element={<MissingInformation />} />
              <Route path="contact-details-required" element={<MissingInformation />} />
              <Route
                path="audits"
                element={
                  <>
                    <RequireAuth allowedRoles={[ROLES.superadmin, ROLES.accountadmin]} />
                    <MissingInformation />
                  </>
                }
              />
              <Route
                path="reporting"
                element={
                  <>
                    <RequireAuth allowedRoles={[ROLES.superadmin, ROLES.accountadmin]} />
                    <MissingInformation />
                  </>
                }
              />
            </Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.superadmin, ROLES.accountadmin, ROLES.provider]} />}>
            <Route path="patients" element={<PatientStatus />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.superadmin, ROLES.accountadmin]} />}>
            <Route path="account-management" element={<AccountManagement />}>
              <Route path="providers">
                <Route index element={<Providers />} />
                <Route path="new" element={<ProviderUserForm />} />
                <Route path="edit" element={<ProviderUserForm />} />
              </Route>
            </Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.superadmin, ROLES.registrationadmin]} />}>
            <Route path="account-management" element={<AccountManagement />}>
              <Route path="administrators">
                <Route index element={<Administrators />} />
                <Route path="new" element={<AdminUserForm />} />
                <Route path="edit" element={<AdminUserForm />} />
              </Route>
            </Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.superadmin]} />}>
            <Route path="glossary" element={<Glossary />} />
          </Route>

          {/* Catch all */}
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
      {isSessionAboutToExpire && <SessionExpiryModal onExtendSessionClick={handleExtendSession} onLogoutClick={handleLogout} />}
      {showChangesDiscardWarningModal && (
        <ModalPopup
          {...{
            body: 'Your changes will be discarded when you leave this page. Do you want to leave this page?',
            showPopup: true,
            actionButtons: [
              {
                name: 'no',
                text: 'No',
                addCssClasses: 'btn-secondary text-transform-none',
                onClick: () => handlePageChangeModalClose('no'),
              },
              {
                name: 'yes',
                text: 'Yes',
                addCssClasses: 'text-transform-none',
                onClick: () => handlePageChangeModalClose('yes'),
              },
            ],
          }}
          size="tiny"
          inline={false}
        />
      )}
    </>
  );
}

export default App;
