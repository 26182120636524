import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import { mutationSendActivationLink } from '../../../services';

const useSendActivationLink = () => {
  const toastId = React.useRef<string | number | null>(null);

  const [sendActivationLink] = useMutation(mutationSendActivationLink, {
    onError() {
      const message = `Error occured while sending activation link`;

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);
    },
  });

  const triggerActivationLinkEmail = async (userId: number) => {
    const result = await sendActivationLink({
      variables: {
        userId: userId,
      },
    });

    if (result.data) {
      const message = 'Activation email sent';

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);
      return result.data.resendActivationLink;
    }

    return null;
  };

  return { triggerActivationLinkEmail };
};

export default useSendActivationLink;
