import { useNavigate } from 'react-router-dom';
import { Container, Header, Item, Form } from 'semantic-ui-react';
import { InputButton } from '../../components/controls';
import { ProviderHeader, ProviderFooter } from '../../components/shared';

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate('/');

  return (
    <Item as="div" className="Login">
      <ProviderHeader />
      <Container fluid>
        <Item as="div" style={{ margin: '0 auto' }}>
          <Header as="h1" className="title" content="Unauthorized" />
          <br />
          <Header as="h2" className="title" content="You do not have access to the requested page" />
          <Form size="massive">
            <InputButton text="Go Back" fluid onClick={goBack} />
          </Form>
        </Item>
      </Container>
      <ProviderFooter />
    </Item>
  );
};

export default Unauthorized;
