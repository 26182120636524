import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Item, Popup } from 'semantic-ui-react';

import { InputButton } from '../../components/controls';
import { ActionProp, FieldProp, Filter, Loading, ModalPopup, ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useDownloadFile, useProviders } from '../../hooks';
import { queryDownloadCSVProviders } from '../../services';
import { Provider } from '../../types';
import { PAGE_LINKS } from '../../utilities';
import { ProvidersTable } from './ProvidersTable';
import { AccountManagementContext } from './context';

import '../PatientStatus/PatientStatus.scss';

const pageHeaderTitle = [{ key: 'Providers', content: 'Providers', active: true }];

const Providers = () => {
  const navigate = useNavigate();
  const accountManagementContext = useContext(AccountManagementContext);
  const { roles } = useContext(GlobalContext);
  const setSearchParams = useSearchParams()[1];

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);

  const [searchOptions, setSearchOptions] = useState<{
    [k: string]: any;
  } | null>(null);

  const [downloadProvidersCSV, { error }] = useLazyQuery(queryDownloadCSVProviders, {
    fetchPolicy: 'no-cache',
  });

  const providers = useProviders();
  const downloadFile = useDownloadFile();

  // const providerGroupArray = new Set();
  const providersGroup = useMemo(() => {
    const providerGroupNameArr: { value: string; text: string; key: string }[] = [];
    providers.forEach((provider: Provider) => {
      const { group } = provider;
      if (group && !providerGroupNameArr.find((item) => item.text === group.originalName)) {
        const groupName = group.originalName;
        providerGroupNameArr.push({
          value: groupName,
          text: groupName,
          key: groupName,
        });
      }
    });
    return providerGroupNameArr?.sort((a: any, b: any) => a.text.localeCompare(b.text));
  }, [providers]);

  const filterFields: FieldProp[] = useMemo(() => {
    return [
      {
        name: 'name',
        type: 'textbox',
        label: 'Name',
        placeholder: 'Enter Name',
      },
      {
        name: 'email',
        type: 'textbox',
        label: 'Work Email',
        placeholder: 'Enter Work Email',
      },
      {
        name: 'doctorGroup',
        type: 'select',
        label: 'Provider Group',
        placeholder: 'Select Provider Group',
        option: providersGroup,
      },
    ];
  }, [providersGroup]);

  const filterActions: ActionProp[] = useMemo(
    () => [
      {
        name: 'search',
        label: 'FILTER',
        addClasses: 'mb-0',
        onClick: (_, data) => {
          let searchQuery: { [k: string]: any } = {};
          Object.keys(data).forEach((key) => {
            if (data[key] !== '') {
              searchQuery[key] = data[key];
            }
          });
          setSearchParams(searchQuery);
        },
      },
      {
        name: 'clearfilter',
        label: 'Clear filters',
        addClasses: 'mb-0 btn-secondary',
        onClick: () => {
          setSearchParams({});
        },
      },
    ],
    [setSearchParams],
  );

  useEffect(() => {
    if (roles.length > 0) {
      setIsComponentMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  useEffect(() => {
    accountManagementContext?.setUser(null);
  }, [accountManagementContext]);

  const pageHeaderActions = () => (
    <>
      <InputButton
        text="Export"
        onClick={async () => {
          setLoadingOnTableAction(true);
          downloadProvidersCSV({
            variables: {
              searchOptions: { ...searchOptions },
            },
          }).then(({ data }) => {
            setLoadingOnTableAction(false);
            downloadFile(data.downloadProvidersCSV, 'Providers', 'csv');
          });
        }}
      />
      <Popup
        content="Add a new user"
        trigger={
          <div>
            <InputButton icon="plus" circular size="mini" onClick={() => navigate(PAGE_LINKS.providerUserCreate)} />
          </div>
        }
      />
    </>
  );

  if (error) {
    console.log(error);
    setShowErrorModal(true);
  }

  return (
    <>
      <Item as="div" className="body-content">
        <ProviderSubHeader pageTitle={pageHeaderTitle} ActionButton={pageHeaderActions()} />
        {!isComponentMounted ? (
          <Loading show={true} />
        ) : (
          <Container fluid className="with-sidebar">
            <Filter fields={filterFields} actions={filterActions} />
            <Item as="div" id="scrollTo" className="content">
              <ProvidersTable onSearchOptionsChange={(selectedSearchOptions) => setSearchOptions(selectedSearchOptions)} />
            </Item>
          </Container>
        )}
      </Item>
      {isLoadingOnTableAction && <Loading show={true} />}
      {showErrorModal && (
        <ModalPopup
          showPopup
          title="Error"
          body="Error occured while downloading the Providers, please try again.  If same error repeats, please reach support team."
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </>
  );
};

export default Providers;
