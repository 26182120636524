import { useQuery } from '@apollo/client';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';
import { InputButton, InputCheckbox, InputField, InputSelect, StringField } from '../../components/controls';
import { ModalPopup, ModalPopupProps, ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useProviders } from '../../hooks';
import { useForm } from '../../hooks/useForm';
import { queryRoles } from '../../services';
import { Provider, Role } from '../../types';
import { PAGE_LINKS, ROLES } from '../../utilities';
import { AccountManagementContext } from './context';
import useAccountCreate from './hooks/useAccountCreate';
import useAccountUpdate from './hooks/useAccountUpdate';
import { ProviderForm } from './types/userForm.type';

const doctorText =
  '“Dr.” will be used in the salutation of the activation email when this is yes. The user’s first name will be used when this is no.';

const initializeFormData: ProviderForm = {
  firstName: '',
  lastName: '',
  email: '',
  providerId: undefined,
  providerGroup: '',
  useSalutation: false,
};

const ProviderUserForm = () => {
  const navigate = useNavigate();

  const { pathname: locationPathname } = window.location;

  const { user: currentLoggedInUser } = useContext(GlobalContext);
  const accountManagementContext = useContext(AccountManagementContext);

  const { onChange, formState, setFormState, renderFieldError } = useForm<ProviderForm>(initializeFormData);

  const { data: rolesData } = useQuery(queryRoles);
  const providers = useProviders();

  const { errorMessage, setErrorMessage, createNewUser, loading: loadingCreateUser } = useAccountCreate();

  const {
    errorMessage: updateErrorMessgae,
    setErrorMessage: setUpdateErrorMessage,
    updateExistingUser,
    loading: loadingUpdateUser,
  } = useAccountUpdate();

  const { email, firstName, lastName, providerId, providerGroup, useSalutation } = formState;

  const [modalPopupProps, setModalPopupProps] = useState<ModalPopupProps>({
    title: 'Alert',
    body: '',
    showPopup: false,
    onClose: () => {
      setFormState({
        ...formState,
        ...initializeFormData,
      });

      setModalPopupProps({ ...modalPopupProps, showPopup: false });
      navigate(PAGE_LINKS.providers, { replace: true });
    },
  });

  const consolidatedErrorMessege = errorMessage || updateErrorMessgae;

  const renderEmailError = () => {
    let emailErrorMessage = renderFieldError('email');
    let existStr = 'email';
    let replaceWith = 'work email';
    if (emailErrorMessage) {
      if (emailErrorMessage.includes('maximum of')) {
        existStr = 'Email';
        replaceWith = 'Work email';
      }
      emailErrorMessage = emailErrorMessage.replace(existStr, replaceWith);
    }

    return emailErrorMessage !== ''
      ? emailErrorMessage.includes('A valid email address is required')
        ? `A valid email address is required for registration.`
        : emailErrorMessage
      : consolidatedErrorMessege && consolidatedErrorMessege.includes('The email you entered is already in use')
      ? consolidatedErrorMessege.replace(existStr, replaceWith)
      : '';
  };

  const renderError = (fieldName: keyof ProviderForm) => {
    const retVal = fieldName.toString() === 'email' ? renderEmailError() : renderFieldError(fieldName);

    return retVal;
  };

  const isFormValid = (): boolean => {
    let isValid = true;
    if (
      !email ||
      !firstName ||
      !lastName ||
      !providerId ||
      renderError('firstName') !== '' ||
      renderError('lastName') !== '' ||
      renderError('email') !== ''
    ) {
      isValid = false;
    }
    return isValid;
  };

  const [formSubmitted, setFormSubmitted] = useState(false);

  const providersForDropdown = useMemo(() => {
    return providers
      ?.map(({ id, name, originalName }: Provider) => {
        return {
          value: id?.toString(),
          text: name || originalName || '',
        };
      })
      ?.sort((a: any, b: any) => a.text.localeCompare(b.text));
  }, [providers]);

  const selectedProviderInfo = providers.find((provider: Provider) => {
    return provider.id === parseInt(formState.providerId + '');
  });

  const pageTitles = useMemo(
    () => [
      { key: 'providers', content: 'Providers', link: true },
      { key: 'userForm', content: 'Create New User', active: true },
    ],
    [],
  );

  useEffect(() => {
    if (locationPathname === PAGE_LINKS.providerUserEdit) {
      const user = accountManagementContext?.user;
      if (!user || (currentLoggedInUser?.role.code !== ROLES.superadmin && currentLoggedInUser?.role.code !== ROLES.accountadmin)) {
        navigate('/');
        return;
      }

      setFormState({
        firstName: user.firstName,
        lastName: user.lastName || '',
        email: user.email,
        roleId: user.role.id,
        providerId: user.provider?.id,
        providerGroup: user.provider?.group?.name || '',
        useSalutation: user.useSalutation,
      });

      pageTitles[1].content = 'Edit User';
    } else {
      pageTitles[1].content = 'Create New User';
      setFormState(initializeFormData);
    }
  }, [accountManagementContext?.user, currentLoggedInUser?.role.code, locationPathname, navigate, pageTitles, setFormState]);

  const doctorGroup = selectedProviderInfo?.group?.originalName || selectedProviderInfo?.name;
  const npi = selectedProviderInfo?.npi;

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormSubmitted(true);

    setErrorMessage('');
    setUpdateErrorMessage('');

    if (!isFormValid()) {
      return;
    }

    let result = null;

    const formData = {
      ...formState,
      roleId: rolesData?.roles.find((role: Role) => role.code === 'provider').id,
      providerId: parseInt(providerId + ''),
      useSalutation: Boolean(useSalutation),
    };

    if (accountManagementContext?.user) {
      result = await updateExistingUser({
        ...formData,
        id: accountManagementContext.user.id,
      });
    } else {
      result = await createNewUser({ ...formData });
    }

    if (result.data) {
      const popupProps = { ...modalPopupProps };

      let successMessage = '';

      if (accountManagementContext?.user) {
        successMessage = 'User details have been updated successfully.';
      } else {
        successMessage = 'Provider account has been created successfully. An email triggered to the user to activate the account.';
      }

      setModalPopupProps({
        ...popupProps,
        body: successMessage,
        showPopup: true,
      });
    }
  };

  return (
    <>
      <Item as="div" className="body-content Provider-Form-Page">
        <ProviderSubHeader pageTitle={pageTitles} />
        <Container fluid>
          <Item as="div" className="content">
            {/* ERROR  */}
            {errorMessage && !errorMessage.includes('The email you entered is already in use') && (
              <Header block className="error" image={errorIcon} color="red" content={errorMessage} />
            )}
            <Form>
              <InputCheckbox
                addClasses="switch-button large"
                dataTrue="Yes"
                dataFalse="No"
                label="Doctor"
                toggle
                text={doctorText}
                checked={useSalutation}
                onChange={(_, data) => {
                  onChange('useSalutation', data.checked);
                }}
              />
              <InputField
                name="firstName"
                label="First Name"
                placeholder="First Name"
                required
                maxLength={100}
                // error={errorText}
                value={firstName}
                onChange={({ target: { name, value } }) => {
                  onChange(name, value);
                }}
                error={formSubmitted && renderError('firstName')}
              />
              <InputField
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                maxLength={100}
                required
                value={lastName}
                onChange={({ target: { name, value } }) => {
                  onChange(name, value);
                }}
                error={formSubmitted && renderError('lastName')}
              />
              <InputField
                name="email"
                type="email"
                label="WORK EMAIL"
                placeholder="Work Email Address"
                required
                value={email}
                maxLength={150}
                onChange={({ target: { name, value } }) => {
                  // updateEmail(emailPattern.test(value));
                  onChange(name, value);
                }}
                error={formSubmitted && renderError('email')}
              />

              <InputSelect
                addClass="provider-dropdown"
                name="provider"
                options={providersForDropdown || []}
                fluid
                placeholder="Select a provider name"
                selection="selection"
                label="Provider"
                search
                clearable={true}
                required
                value={providerId}
                onChange={(_, { value }) => onChange('providerId', value?.toString() || '')}
                error={formSubmitted && !providerId && 'A provider must be selected'}
              />
              <StringField label="NPI" text={npi?.toString()} />
              <StringField label="Provider Group" text={doctorGroup || providerGroup} />
              <InputButton
                loading={loadingCreateUser || loadingUpdateUser}
                addCssClasses="mb-0 empty-label"
                text="Save"
                fluid
                requiredHintText
                onClick={handleFormSubmit}
                disabled={!firstName && !lastName && !email && !providerId}
              />
              <InputButton
                text="Cancel"
                fluid
                addCssClasses="btn-secondary empty-label mb-0"
                onClick={() => navigate(PAGE_LINKS.providers, { replace: true })}
              />
            </Form>
          </Item>
        </Container>
      </Item>
      {modalPopupProps.showPopup && <ModalPopup {...modalPopupProps} size="tiny" />}
    </>
  );
};

export default ProviderUserForm;
