import { Link } from 'react-router-dom';
import { PAGE_LINKS } from '../../utilities/constants';
import { Container, Item } from 'semantic-ui-react';
import { ProviderFooter, ProviderHeader, ProviderSubHeader } from '../../components/shared';

const PageTitle = [{ key: 'Two-Factor authentication', content: 'Two-Factor authentication ', active: true }];

const LoginLinkExpired = () => {
  return (
    <Item as="div" className="two-factor-auth">
      <ProviderHeader />
      <ProviderSubHeader pageTitle={PageTitle} />
      <Container fluid>
        <Item as="div" className="content">
          <p>
            The button links in the secondary verification email have expired. Login again and complete the secondary verification
            process within 30 minutes of the email being sent. <Link to={PAGE_LINKS.login}>Return to login page</Link>{' '}
          </p>
        </Item>
      </Container>
      <ProviderFooter />
    </Item>
  );
};
export default LoginLinkExpired;
