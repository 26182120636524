import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { mutationRenewSession } from '../services';
import { GENERAL_CONSTANTS } from '../utilities/constants';

const useRenewSession = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const [renewSession] = useMutation(mutationRenewSession, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const { extensions, message } = graphQLErrors[0];

        if (extensions.code === 'UNAUTHENTICATED') {
          setErrorMessage(message);
        } else if (extensions.code === 'FORBIDDEN') {
          setErrorMessage('Invalid email or password');
        } else {
          setErrorMessage(GENERAL_CONSTANTS.unexpectedErrorReachSupport);
        }
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        }
      }
    },
    fetchPolicy: 'no-cache',
  });

  const extendSession = useCallback(() => {
    return renewSession();
  }, [renewSession]);

  return { extendSession, errorMessage };
};

export default useRenewSession;
