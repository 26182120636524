import { Outlet } from 'react-router-dom';
import { Container, Item } from 'semantic-ui-react';
import ProviderFooter from '../../ProviderFooter/ProviderFooter';
import ProviderHeader from '../../ProviderHeader/ProviderHeader';

const PublicLayout = () => {
  return (
    <Item as="div" className="Term-of-Use">
      <ProviderHeader />
      <Container fluid>
        <Outlet />
      </Container>
      <ProviderFooter />
    </Item>
  );
};

export default PublicLayout;
