import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Icon, Image, Item, Popup } from 'semantic-ui-react';
import statusSVG from '../../assets/img/status-icon.svg';
import { PPTable, SortDirectionProp, TableColumnProps } from '../../components/controls/ProviderTable';
import { Loading } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useProviders } from '../../hooks';
import { queryPatientsByProvider } from '../../services';
import { Patient } from '../../types/patient.type';
import { APP_DEFAULT_DATE_FORMAT, DEFAULT_NUMBER_OF_RECORDS_PER_PAGE } from '../../utilities/constants';
import './PatientStatus.scss';
import useRefreshPatient from './hooks/useRefreshPatient';

const statusIcon = <Image src={statusSVG} className="img-icon" />;

interface ColumnDataRenderFunctions {
  'mrn brightreePatientId': (patient: Patient) => JSX.Element;
  status: (patient: Patient) => JSX.Element;
  provider: (patient: Patient) => JSX.Element;
  daysInStatus: (patient: Patient) => JSX.Element;
  dateOfBirth: (patient: Patient) => JSX.Element;
  patientRefresh: (patient: Patient) => JSX.Element | null;
}

type PatientStatusTableProps = {
  onSearchOptionsChange?: (options: { [k: string]: any }) => void;
};

const DEFAULT_SORT_COLUMN = 'fullName';

const SORT_FIELD_MAPPER = {
  fullName: 'NAME',
  dateOfBirth: 'DOB',
  provider: 'PHYSICIAN',
  status: 'STATUS',
  daysInStatus: 'STATUSDATE',
  'mrn brightreePatientId': 'MRN',
};

export const PatientStatusTable = ({ onSearchOptionsChange }: PatientStatusTableProps) => {
  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);
  const [isRefreshPatientTriggered, setRefreshPatientTriggered] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_NUMBER_OF_RECORDS_PER_PAGE);

  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('ascending');

  const { user: currentLoggedInUser, logout } = useContext(GlobalContext);
  const { isProvider = false } = currentLoggedInUser || {};
  const userRole = currentLoggedInUser?.role?.code;

  const { refreshedPatient, triggerRefreshPatient } = useRefreshPatient();
  const [searchParams] = useSearchParams();
  const providers = useProviders();

  const [getPatients, { data: patientInfo, error, loading }] = useLazyQuery(queryPatientsByProvider, {
    fetchPolicy: 'no-cache',
  });

  const [patients, setPatients] = useState<Patient[]>([]);
  const { totalRecords } = patientInfo?.patients || {};

  if (error) {
    const {
      graphQLErrors: [{ message }],
    } = error;

    if (message === 'Invalid token' || message === 'Unauthorized') {
      logout();
      // return <Navigate to={PAGE_LINKS.login} />;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUpdatedPatients = (currentPageNumber: number) => {
    const physicianNameFromParam = searchParams.get('physician') || null;
    const physicianName = providers.find((provider) => provider.name === physicianNameFromParam)?.originalName ?? null;
    const doctorGroupName = searchParams.get('doctorGroup')?.toLowerCase() || null;
    const sortDir = sortDirection === 'ascending' ? 'ASC' : sortField === 'daysInStatus' ? 'DESC' : 'DESC';
    const orderStatus = searchParams.get('orderStatus') ?? null;
    const lastStatusChange = searchParams.get('lastStatusChange') ?? null;

    const patientBrightreeId = searchParams.get('patientBrightreeId') || null;

    let patientStatus = '';
    let dateOfBirth = searchParams.get('dob');

    if (searchParams.get('statusUnknown') === 'Unknown') {
      patientStatus += 'Unknown';
    }
    if (searchParams.get('statusBTWIP') === 'BT_WIP') {
      patientStatus += `${patientStatus !== '' ? patientStatus + ',' : ''}BT_WIP`;
    }

    // Check for valid Date
    let da = moment(dateOfBirth).format(APP_DEFAULT_DATE_FORMAT);
    if (dateOfBirth && dateOfBirth.length < 10) {
      dateOfBirth = moment(new Date()).format(APP_DEFAULT_DATE_FORMAT);
    }

    if (dateOfBirth && dateOfBirth.length === 10 && da === 'Invalid date') {
      dateOfBirth = moment(new Date()).format(APP_DEFAULT_DATE_FORMAT);
    }

    const filter = {
      name: searchParams.get('name'),
      dateOfBirth,
      physicianName,
      doctorGroupName,
      status: patientStatus,
      lastStatusChange: lastStatusChange === 'all' ? null : lastStatusChange,
      orderStatus: orderStatus === 'all' ? null : orderStatus,
      patientBrightreeId: patientBrightreeId ? +patientBrightreeId : null,
    };

    const searchOptions = {
      skip: (currentPageNumber - 1) * pageSize,
      take: pageSize,
      sortBy: SORT_FIELD_MAPPER[(sortField ? sortField : DEFAULT_SORT_COLUMN) as keyof typeof SORT_FIELD_MAPPER],
      sortDirection: sortDir,
      filter,
    };

    getPatients({
      variables: {
        searchOptions: { ...searchOptions },
      },
    }).then(({ data }) => {
      setPatients(data?.patients?.patients);

      if (onSearchOptionsChange) {
        onSearchOptionsChange({
          ...searchOptions,
          take: data?.patients.totalRecords,
          skip: 0,
        });
      }

      setLoadingOnTableAction(false);
    });
  };

  const handleRefreshClick = async (patient: Patient) => {
    setRefreshPatientTriggered(true);

    await triggerRefreshPatient(patient.betterNightUserId);

    setRefreshPatientTriggered(false);
  };

  const columnRenderers: ColumnDataRenderFunctions = {
    dateOfBirth: (patient: Patient) => {
      return <Item className="nowrap">{patient.dateOfBirth}</Item>;
    },
    daysInStatus: (patient: Patient) => {
      return <Item>{patient.daysInStatus > 90 ? '> 90' : patient.daysInStatus}</Item>;
    },
    'mrn brightreePatientId': (patient: Patient) => (
      <Item className="nowrap">
        <div>
          MRN:{' '}
          <span
            style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
            onClick={() => window.open(patient.betternightLink, '_blank')}
          >
            {patient.mrn}
          </span>
        </div>
        <div>
          BT ID:{' '}
          <span
            style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
            onClick={() => window.open(patient.brightreeLink, '_blank')}
          >
            {patient.brightreePatientId}
          </span>
        </div>
      </Item>
    ),
    status: (patient: Patient) => {
      const patientStatus = patient.patientStatus || 'Unknown';

      const tooltip = (
        <div className="patientStatusTooltip">
          <div className="statusTitle">{patientStatus}</div>
          <div className="statusDefinition">{patient.patientStatusDefinition}</div>
        </div>
      );
      return (
        <Popup
          on="hover"
          style={{ margin: '0 0 2px 0' }}
          content={tooltip}
          trigger={
            <Item className="status-cell">
              {patientStatus.includes('Missing Requested') ? (
                <>
                  Missing Requested
                  <br />
                  {patientStatus.split('Missing Requested ')[1]}
                </>
              ) : (
                patientStatus
              )}
            </Item>
          }
        />
      );
    },
    provider: (patient: Patient) => {
      return (
        <Item>
          <div>Referring: {patient.referringProviderName}</div>
          <div>Ordering: {patient.orderingProviderName}</div>
        </Item>
      );
    },
    patientRefresh: (patient: Patient) => {
      return (
        <Item>
          <div className="refresh-icon-wrapper">
            <Icon name="redo alternate" className="cursorPointer" onClick={() => handleRefreshClick(patient)} />
          </div>
        </Item>
      );
    },
  };

  const onColumnDataRender = (column: TableColumnProps, rowData: Patient) => {
    return columnRenderers[column.name as keyof ColumnDataRenderFunctions]?.(rowData);
  };

  const tableColumns: TableColumnProps[] = useMemo(
    () => [
      {
        name: 'id',
        label: 'Id',
        isVisible: false,
      },
      {
        name: 'fullName',
        label: 'Patient Name',
        addClasses: 'width-20-percent word-break',
        isSortable: false,
      },
      {
        name: 'dateOfBirth',
        label: 'DOB',
        addClasses: 'width-10-percent',
        onColumnDataRender: onColumnDataRender,
        isSortable: false,
      },
      {
        name: 'provider',
        label: 'Provider',
        onColumnDataRender: onColumnDataRender,
        addClasses: 'width-25-percent word-break',
        isSortable: false,
      },
      {
        name: 'status',
        label: 'Status',
        children: (
          <Popup
            on="hover"
            style={{ margin: '0 0 0 -5px' }}
            content="To see a description of a patient's status, hover over or click on the status name."
            trigger={<Item className="image-container">{statusIcon}</Item>}
          />
        ),
        onColumnDataRender: onColumnDataRender,
        addClasses: 'status-th word-break',
        isSortable: false,
      },
      {
        name: 'daysInStatus',
        label: 'Days In Status',
        onColumnDataRender: onColumnDataRender,
        addClasses: 'width-10-percent',
      },
      {
        name: 'mrn brightreePatientId',
        label: 'MRN / BT ID',
        isVisible: isProvider === true ? false : true,
        onColumnDataRender: onColumnDataRender,
        addClasses: 'width-12-percent',
        isSortable: false,
      },
      {
        name: 'patientRefresh',
        label: '',
        isVisible: userRole === 'superadmin' || userRole === 'accountadmin' ? true : false,
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
        addClasses: 'padding-left-0px',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProvider],
  );

  useEffect(() => {
    getUpdatedPatients(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortDirection, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
    getUpdatedPatients(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const refreshPatients = () => {
      const updatedPatients = (patients as Patient[]).map((pt) =>
        pt.betterNightUserId === refreshedPatient?.betterNightUserId && refreshedPatient.updatedPatientData
          ? { ...refreshedPatient.updatedPatientData }
          : pt,
      );

      setPatients([...updatedPatients]);
      triggerRefreshPatient(0);
    };

    if (!isRefreshPatientTriggered && patients?.length > 0 && refreshedPatient && refreshedPatient?.updatedPatientData) {
      refreshPatients();
    }
  }, [isRefreshPatientTriggered, refreshedPatient, patients, setPatients, triggerRefreshPatient]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const displayPatients = [...patients];

  return (
    <>
      {isLoading || (loading && !isLoadingOnTableAction) ? (
        <Loading show={true} />
      ) : (
        <>
          <PPTable
            data={displayPatients}
            columns={tableColumns}
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={totalRecords}
            sortField={sortField}
            sortDirection={sortDirection as SortDirectionProp}
            onSort={(sortBy, sortDir) => {
              setLoadingOnTableAction(true);
              setCurrentPage(1);
              setSortField(sortBy);
              setSortDirection(sortDir);
            }}
            onPageChange={(updatedCurrentPage, updatedPageSize) => {
              setLoadingOnTableAction(true);
              setCurrentPage(updatedCurrentPage);
              setPageSize(updatedPageSize);
            }}
          />
          {(isLoadingOnTableAction || isRefreshPatientTriggered) && <Loading show={true} />}
        </>
      )}
    </>
  );
};
