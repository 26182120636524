import { Link } from 'react-router-dom';
import { Item } from 'semantic-ui-react';
import { PAGE_LINKS } from '../../../utilities';
import './ProviderFooter.scss';

const ProviderFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Item as="footer" className="provider-footer">
      <Item as="span">© BetterNight, {currentYear}</Item>
      <Item as="span">
        If you have any questions or feedback, contact us at{' '}
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = 'mailto:customersuccess-support@betternight.com';
            e.preventDefault();
          }}
        >
          customersuccess-support@betternight.com
        </Link>
      </Item>
      <Item as="div">
        <Link to={PAGE_LINKS.termOfUse} target="_blank" rel="noopener noreferrer">
          Terms of Use
        </Link>
        <Link to={`${PAGE_LINKS.termOfUse}#betterNight-policy`} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
      </Item>
    </Item>
  );
};

export default ProviderFooter;
