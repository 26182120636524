import { gql } from '@apollo/client/core';

export default gql`
  mutation RefreshPatient($betterNightUserId: Int!) {
    refreshPatient(betterNightUserId: $betterNightUserId) {
      id
      firstName
      lastName
      fullName
      dateOfBirth
      referringProviderId
      referringProviderName
      orderingProviderId
      orderingProviderName
      patientStatus
      patientStatusDefinition
      statusDate
      daysInStatus
      mrn
      brightreeId
      brightreePatientId
      brightreeLink
      betternightLink
      betterNightUserId
    }
  }
`;
