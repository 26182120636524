import { useNavigate } from 'react-router-dom';
import { Form, Header, Item } from 'semantic-ui-react';
import { InputButton } from '../../components/controls';

const InProgress = ({ showGoBackButton = true }) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Item as="div" className="body-content">
      <Item as="div" style={{ margin: '0 auto', marginTop: '100px' }}>
        <Header as="h1" className="title" content="Coming Soon..." />
        <br />
        {showGoBackButton && (
          <Form size="massive">
            <InputButton text="Go Back" fluid onClick={goBack} />
          </Form>
        )}
      </Item>
    </Item>
  );
};

export default InProgress;
