import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { mutationToggleProviderContactMissingInfoActiveStatus } from '../../../services';

type ActiveToggle = {
  providerId: number;
  isActive: boolean;
};

const useProviderContactMissingInfoActiveStatusToggle = () => {
  const [isActiveRecord, setActiveFlag] = useState<ActiveToggle | null>(null);

  const toastId = React.useRef<string | number | null>(null);

  const [toggleProviderContactMissingInfoActiveStatus] = useMutation(mutationToggleProviderContactMissingInfoActiveStatus, {
    onError() {
      const message = `Error occurred while updating the status`;

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);

      if (isActiveRecord) {
        const { providerId, isActive } = isActiveRecord;

        setActiveFlag({
          providerId,
          isActive: !isActive,
        });

        toggleProviderContactMissingInfoActiveStatus({
          variables: {
            worklistInput: {
              providerId,
              isActive: !isActive,
            },
          },
        });
      }
    },
  });

  const setActiveStatus = async (providerId: number, isActive: boolean) => {
    const result = await toggleProviderContactMissingInfoActiveStatus({
      variables: {
        worklistInput: {
          providerId,
          isActive: isActive,
        },
      },
    });

    if (result.data) {
      setActiveFlag({
        providerId,
        isActive,
      });

      const message = `Worklist status updated successfully.`;

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);
      return result.data.toggleUserActiveStatus;
    }

    return null;
  };

  return { isActiveRecord, setActiveStatus };
};

export default useProviderContactMissingInfoActiveStatusToggle;
