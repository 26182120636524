import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid, Item } from 'semantic-ui-react';

import { InputButton } from '../../../../components/controls';
import { PPTable, SortDirectionProp, TableColumnProps } from '../../../../components/controls/ProviderTable';
import RadioList from '../../../../components/controls/form/RadioList/RadioList';
import { Loading } from '../../../../components/shared';
import { GlobalContext } from '../../../../context/GlobalContext';
import { useDownloadFile } from '../../../../hooks';
import {
  mutationCloseBTUploadErrorRecord,
  mutationReOpenBTUploadErrorRecord,
  queryBTUploadErrors,
  queryDownloadCSVBtUploadError,
} from '../../../../services';
import { BTUploadErrorWorklist, SearchOptions } from '../../../../types';
import {
  DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION,
  SORT_ORDERS,
  TABLE_MISSING_INFO_PAGE_COUNTS,
} from '../../../../utilities/constants';
import { formatDateToCustomString, transformSearchedParamEntries } from '../../../../utilities/sharedFunctions';

import './BTUploadErrors.scss';

type BTUploadErrorsProps = {
  note: string;
  onWorklistUpdate: (totalRecords?: number) => void;
};

const SEARCH_FIELD_MAPPER = {
  closed: 'closed',
  open: 'open',
};

const FILTER_BY_RADIO_OPTIONS = [
  { text: 'Open', value: SEARCH_FIELD_MAPPER.open },
  { text: 'Closed', value: SEARCH_FIELD_MAPPER.closed },
];

const SORT_FIELDS = {
  createdAt: 'createdAt',
  closedDate: 'closedDate',
};

const BTUploadErrors = ({ note, onWorklistUpdate }: BTUploadErrorsProps) => {
  const { logout } = useContext(GlobalContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const downloadFile = useDownloadFile();

  const toastId = React.useRef<string | number | null>(null);

  const sortField = searchParams.get('__sort') || SORT_FIELDS.createdAt;

  const sortDirection = searchParams.get('__order') || SORT_ORDERS.descending;

  const currentPage = +(searchParams.get('__page') || 1);

  const recordToView = searchParams.get('isActive') === 'true' ? SEARCH_FIELD_MAPPER.open : SEARCH_FIELD_MAPPER.closed;

  const [selectedFilterByRadioOption, setFilterByRadioOption] = useState(recordToView);

  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);

  const [apiCallTriggered, setAPICallTriggered] = useState(false);

  const showToaster = (message: string, color = false) => {
    if (toastId.current !== null) {
      toast.dismiss(toastId.current);
    }

    let toasterOptions: any = { autoClose: 5000 };

    if (color) {
      toasterOptions = { ...toasterOptions, theme: 'colored' };
    }

    toastId.current = toast.error(message, toasterOptions);
  };

  const [getBTUploadErrors, { data: apiGetData, error: apiGetError, loading: apiLoadingGet }] = useLazyQuery(queryBTUploadErrors, {
    fetchPolicy: 'no-cache',
  });

  const [closeBTUploadErrorRecord, { loading: apiLoadingCloseRecord }] = useMutation(mutationCloseBTUploadErrorRecord, {
    onError() {
      const message = `Error occurred while Closing the record.`;
      showToaster(message, true);
    },
  });

  const [reOpenBTUploadErrorRecord, { loading: apiLoadingReOpenRecord }] = useMutation(mutationReOpenBTUploadErrorRecord, {
    onError() {
      const message = `Error occurred while Reopening the record.`;
      showToaster(message, true);
    },
  });
  const [getCSVForBTUploadError] = useLazyQuery(queryDownloadCSVBtUploadError, {
    fetchPolicy: 'no-cache',
  });
  const getSearchParams = (): SearchOptions => {
    let sortDir = sortDirection === 'ascending' ? 'DESC' : 'ASC';

    const skip = (currentPage - 1) * DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION;

    const filterOption: Record<string, any> = {
      isActive: true,
    };

    const searchedParams = transformSearchedParamEntries(searchParams);

    Object.keys(searchedParams).forEach((key) => {
      if (!key.includes('__')) {
        if (key === 'isActive') {
          filterOption[key] = searchedParams[key] === 'false' ? false : true;
        } else {
          filterOption[key] = searchedParams[key];
        }
      }
    });

    let sortFieldInfo =
      sortField === SORT_FIELDS.createdAt ? 'CREATED_AT' : sortField === SORT_FIELDS.closedDate ? 'CLOSED_DATE' : sortField;
    return {
      skip: skip,
      take: DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION,
      sortBy: sortFieldInfo,
      sortDirection: sortDir,
      filter: filterOption,
    };
  };

  const handleDownloadCSV = () => {
    const searchOptions = getSearchParams();
    searchOptions.take = 0;
    searchOptions.timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

    getCSVForBTUploadError({
      variables: {
        searchOptions: { ...searchOptions },
      },
    }).then((response) => {
      downloadFile(response?.data?.downloadBtUploadErrorCSV, 'BT_UPLOAD_ERROR', 'csv');
      return;
    });
  };
  const renderStatusFilters = (
    <Grid.Column mobile={16} computer={13} className="filter-controls">
      <InputButton
        key={'CSV'}
        inline={false}
        text={'CSV'}
        type="button"
        addCssClasses="download-csv"
        icon="download"
        onClick={(e) => {
          e.preventDefault();
          handleDownloadCSV();
        }}
      />
      <RadioList
        name="statusFilter"
        label=""
        options={FILTER_BY_RADIO_OPTIONS}
        value={selectedFilterByRadioOption}
        onChange={(selectedValue) => {
          const existingSearchedParams = transformSearchedParamEntries(searchParams);

          setSearchParams({
            ...existingSearchedParams,
            __page: '1',
            __sort: SORT_FIELDS.createdAt,
            __order: SORT_ORDERS.descending,
            isActive: selectedValue === SEARCH_FIELD_MAPPER.closed ? 'false' : 'true',
          });

          setFilterByRadioOption(selectedValue);
        }}
      />
    </Grid.Column>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUpdatedBTUploadErrors = () => {
    const searchOptions = getSearchParams();

    if (!apiCallTriggered) {
      setAPICallTriggered(true);
      getBTUploadErrors({
        variables: {
          searchOptions: { ...searchOptions },
        },
      }).then((response) => {
        setLoadingOnTableAction(false);
        setAPICallTriggered(false);
        onWorklistUpdate(response?.data?.btUploadErrors?.totalRecords);
      });
    }
  };

  const handleClose = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedRecordId: number) => {
    event.preventDefault();

    const result = await closeBTUploadErrorRecord({
      variables: {
        btUploadErrorRecordId: selectedRecordId,
      },
    });

    if (result?.data?.closeBTUploadErrorRecord === 'Record closed successfully.') {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast('Record closed.');

      onWorklistUpdate();
      getUpdatedBTUploadErrors();
    }
  };

  const handleReOpen = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedRecordId: number) => {
    event.preventDefault();

    const result = await reOpenBTUploadErrorRecord({
      variables: {
        btUploadErrorRecordId: selectedRecordId,
      },
    });

    if (result?.data?.reOpenBTUploadErrorRecord === 'Record reopened successfully.') {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast('Record Reopened.');

      onWorklistUpdate();
      getUpdatedBTUploadErrors();
    }
  };

  const onColumnDataRender = useCallback(
    (column: TableColumnProps, rowData: BTUploadErrorWorklist) => {
      const { id, createdAt, closedDate, patientMissingInfoDetails } = rowData;
      const { fullName, mrn, brightreePatientId, betternightLink, brightreeLink, salesOrder, salesOrderLink, missingInfo } =
        patientMissingInfoDetails;

      if (column.name === 'patientNameMRN') {
        return (
          <>
            <div key={id} className="patient-details">
              <div>{fullName}</div>
              <div>
                MRN:{' '}
                <span
                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                  onClick={() => window.open(betternightLink, '_blank')}
                >
                  {mrn}
                </span>
              </div>
              <div>
                BT:{' '}
                <span
                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                  onClick={() => window.open(brightreeLink, '_blank')}
                >
                  {brightreePatientId}
                </span>
              </div>
              <div>
                SO:{' '}
                <span
                  style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                  onClick={() => window.open(salesOrderLink, '_blank')}
                >
                  {salesOrder}
                </span>
              </div>
            </div>
          </>
        );
      } else if (column.name === 'createdAt') {
        if (!createdAt) return '';

        const convertedDate = formatDateToCustomString(createdAt, true);
        // const days = getDaysDifference(convertedDate);
        const [date, time, meridiem] = convertedDate.split(' ');

        return (
          <>
            <div>{date}</div>
            <div>{`${time} ${meridiem}`}</div>
            {/* {days > 0 && <div>{`${days} day${days === 1 ? '' : 's'} ago`} </div>} */}
          </>
        );
      } else if (column.name === 'closedDate') {
        if (!closedDate) return '';

        const convertedDate = formatDateToCustomString(closedDate, true);
        // const days = getDaysDifference(convertedDate);
        const [date, time, meridiem] = convertedDate.split(' ');

        return (
          <>
            <div>{date}</div>
            <div>{`${time} ${meridiem}`}</div>
            {/* {days > 0 && <div>{`${days} day${days === 1 ? '' : 's'} ago`} </div>} */}
          </>
        );
      } else if (column.name === 'actionButtons') {
        return selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open ? (
          <InputButton text="Fixed BT Record" type="button" addCssClasses="actionButtons" onClick={(e) => handleClose(e, id)} />
        ) : (
          <InputButton text="Reopen" type="button" addCssClasses="actionButtons" onClick={(e) => handleReOpen(e, id)} />
        );
      } else if (column.name === 'patientMissingInfo') {
        return missingInfo;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUpdatedBTUploadErrors, selectedFilterByRadioOption],
  );

  const tableColumns: TableColumnProps[] = useMemo(() => {
    const columns: TableColumnProps[] = [
      {
        name: 'btUploadErrorId',
        label: 'Id',
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
        isVisible: false,
      },
      {
        name: 'patientNameMRN',
        label: 'Patient Details',
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
        addClasses: 'width-17-percent',
      },
      {
        name: 'patientMissingInfo',
        label: 'Missing Information When Failure Occurred',
        addClasses: 'width-25-percent',
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
      },
      {
        name: 'errorType',
        label: 'Error Type',
        isSortable: false,
        isVisible: true,
      },
      {
        name: 'createdAt',
        label: 'Error Occurred At',
        isSortable: true,
        addClasses: 'width-08-percent',
        onColumnDataRender: onColumnDataRender,
      },
    ];

    if (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open) {
      columns.push({
        name: 'actionButtons',
        label: '',
        isSortable: false,
        addClasses: 'width-12-percent',
        onColumnDataRender: onColumnDataRender,
      });
    }

    if (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.closed) {
      columns.push(
        {
          name: 'closedDate',
          label: 'Closed At',
          isSortable: false,
          addClasses: 'width-05-percent',
          onColumnDataRender: onColumnDataRender,
        },
        {
          name: 'actionButtons',
          label: '',
          isSortable: false,
          addClasses: 'width-05-percent',
          onColumnDataRender: onColumnDataRender,
        },
      );
    }

    return columns;
  }, [onColumnDataRender, selectedFilterByRadioOption]);

  useEffect(() => {
    if (Array.from(searchParams.entries()).length === 0) {
      setFilterByRadioOption(SEARCH_FIELD_MAPPER.open);
    }

    getUpdatedBTUploadErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const { totalRecords, btUploadErrors } = apiGetData?.btUploadErrors || {};

  if (apiGetError) {
    const {
      graphQLErrors: [{ message }],
    } = apiGetError || { graphQLErrors: [] };

    if (message === 'Invalid token' || message === 'Unauthorized') {
      logout();
    }
  }

  return (
    <div className="patient-contacting-container">
      <Item as="p" className="desc">
        {note}
      </Item>
      <Grid className="filter-pane">
        <Grid.Row columns={2}>
          <Grid.Column mobile={16} computer={13}></Grid.Column>
          {renderStatusFilters}
        </Grid.Row>
      </Grid>
      {(apiLoadingCloseRecord || apiLoadingReOpenRecord || isLoadingOnTableAction || apiLoadingGet) && <Loading show={true} />}
      <Item>
        <PPTable
          className="pp-table-secondary align-text-bottom"
          data={btUploadErrors}
          columns={tableColumns}
          totalRecords={totalRecords}
          currentPage={currentPage}
          pageSize={DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION}
          sortField={sortField}
          sortDirection={sortDirection as SortDirectionProp}
          onSort={(sortBy, sortDir) => {
            setLoadingOnTableAction(true);
            const existingSearchedParams = transformSearchedParamEntries(searchParams);

            setSearchParams({
              ...existingSearchedParams,
              __sort: sortBy,
              __order: sortDir,
              __page: '1',
            });
          }}
          onPageChange={(updatedCurrentPage) => {
            setLoadingOnTableAction(true);

            const existingSearchedParams = transformSearchedParamEntries(searchParams);

            setSearchParams({
              ...existingSearchedParams,
              __page: updatedCurrentPage.toString(),
            });
          }}
          pageCounts={TABLE_MISSING_INFO_PAGE_COUNTS}
        />
      </Item>
    </div>
  );
};

export default BTUploadErrors;
