import { Form, Input, InputOnChangeData, Item } from 'semantic-ui-react';

interface InputFieldProps {
  name: string;
  placeholder?: string;
  type?: string;
  label?: any;
  AddClass?: string;
  inline?: boolean;
  required?: boolean;
  hint?: any;
  value?: string | number;
  error?: React.ReactNode;
  size?: any;
  disabled?: boolean;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
}

export const InputField = ({
  name,
  placeholder,
  label,
  AddClass,
  type,
  inline = true,
  required,
  hint,
  value,
  error,
  disabled,
  onChange,
  size,
  maxLength = 50,
}: InputFieldProps) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'numberString') {
      const regex = /^[0-9\b]+$/;

      const code = event.which;
      const char = String.fromCharCode(code);

      if (code !== 13 && !regex.test(char)) {
        event.preventDefault();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    if (type === 'numberString') {
      const regex = /^[0-9\b]+$/;      

      if (data.value.trim() !== '' && !regex.test(data.value)) {
        event.preventDefault();
        return;
      }
    }
    if (onChange) {
      onChange(event, data);
    }
  };

  return (
    <Form.Field className={AddClass} inline={inline} required={required}>
      {label ? <label>{label}</label> : ''}
      <Item as="div">
        <Input
          key={name}
          name={name}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          size={size}
          maxLength={maxLength}
        />
        {hint ? <span className="hint">{hint}</span> : ''}
        {error ? (
          <Item as="div" className="error">
            {error}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </Form.Field>
  );
};
