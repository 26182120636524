import { useEffect, useState } from 'react';
import { useCountdownTimer } from '../../../hooks';
import ModalPopup from '../ModalPopup/ModalPopup';
import './SessionExpiryModal.scss';
import { COUNTDOWN_SECONDS } from '../../../utilities/constants';

type SessionExpiryModalProps = {
  onExtendSessionClick: () => void;
  onLogoutClick: () => void;
};

const SessionExpiryModal = ({ onExtendSessionClick, onLogoutClick }: SessionExpiryModalProps) => {
  const [isComponentMounted, setComponentMounted] = useState(false);
  const remainingTime = useCountdownTimer();

  const convertToSeconds = (time: string) => {
    const [minutes, seconds] = time.split(':');
    const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
    return totalSeconds;
  };

  const remainingTimeInSeconds = convertToSeconds(remainingTime);

  useEffect(() => {
    if (remainingTimeInSeconds <= COUNTDOWN_SECONDS && remainingTimeInSeconds > 0) {
      if (!isComponentMounted) {
        setTimeout(() => {
          setComponentMounted(true);
        }, 1000);
      }
    } else {
      setComponentMounted(false);
    }
  }, [isComponentMounted, remainingTimeInSeconds]);

  if (remainingTimeInSeconds <= 0) return <></>;

  return (
    <>
      {isComponentMounted && (
        <ModalPopup
          {...{
            title: 'Are You Still There?',
            body: `You have been inactive and will be signed out in <b>${convertToSeconds(
              remainingTime,
            )} seconds</b> to ensure your account is kept secure.`,
            showPopup: true,
            actionButtons: [
              {
                name: 'renewSession',
                text: 'Wait, I’m still here!',
                onClick: () => onExtendSessionClick(),
              },
              {
                name: 'signOut',
                text: 'Sign out',
                onClick: () => onLogoutClick(),
              },
            ],
          }}
          size="tiny"
          defaultModal="session-expiry-modal"
          inline={false}
        />
      )}
    </>
  );
};

export default SessionExpiryModal;
