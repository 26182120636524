import { Form, Item, TextArea, TextAreaProps } from 'semantic-ui-react';

interface TextAreaFieldProps {
  name: string;
  placeholder?: string;
  type?: string;
  label?: any;
  addCssClasses?: string;
  inline?: boolean;
  required?: boolean;
  hint?: any;
  value?: string | number;
  error?: React.ReactNode;
  disabled?: boolean;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;
}

export const TextAreaField = ({
  name,
  placeholder,
  label,
  addCssClasses,
  inline = true,
  required,
  hint,
  value,
  error,
  disabled,
  maxLength = 50,
  onChange,
}: TextAreaFieldProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {    
    if (onChange) {
      onChange(event, data);
    }
  };

  return (
    <Form.Field className={addCssClasses} inline={inline} required={required}>
      {label ? <label>{label}</label> : ''}
      <Item as="div">
        <TextArea
          key={name}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={handleOnChange}
        />
        {hint ? <span className="hint">{hint}</span> : ''}
        {error ? (
          <Item as="div" className="error">
            {error}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </Form.Field>
  );
};
