import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, DropdownItemProps, Form, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';

import { InputButton, InputField, InputSelect, StringField } from '../../components/controls';
import { ModalPopup, ModalPopupProps, ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useForm } from '../../hooks/useForm';
import { Role } from '../../types';
import { PAGE_LINKS, ROLES } from '../../utilities/constants';
import { AccountManagementContext } from './context/AccountManagementContext';
import useAccountCreate from './hooks/useAccountCreate';
import useAccountUpdate from './hooks/useAccountUpdate';
import { AdminForm } from './types/userForm.type';

const initializeFormData: AdminForm = {
  firstName: '',
  lastName: '',
  email: '',
  brightreeLoginId: '',
};

const AdminUserForm = () => {
  const navigate = useNavigate();
  const { roles } = useContext(GlobalContext);

  const accountManagementContext = useContext(AccountManagementContext);
  const location = useLocation();

  const { errorMessage, setErrorMessage, createNewUser, loading: loadingCreateUser } = useAccountCreate();

  const {
    errorMessage: updateErrorMessage,
    setErrorMessage: setUpdateErrorMessage,
    updateExistingUser,
    loading: loadingUpdateUser,
  } = useAccountUpdate();

  const consolidatedErrorMessage = errorMessage || updateErrorMessage;
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { onChange, formState, setFormState, renderFieldError } = useForm<AdminForm>(initializeFormData);

  const [modalPopupProps, setModalPopupProps] = useState<ModalPopupProps>({
    title: 'ALERT',
    body: '',
    showPopup: false,
    onClose: () => {
      setFormState({
        ...formState,
        ...initializeFormData,
      });
      setModalPopupProps({ ...modalPopupProps, showPopup: false });
      navigate(PAGE_LINKS.administrators, { replace: true });
    },
  });

  const { firstName, lastName, email, roleId } = formState;

  const roleName = roleId && roles.find((role: Role) => role.id === roleId)?.name;

  const pageTitles = useMemo(
    () => [
      {
        key: 'administrators',
        content: 'Administrators',
        link: true,
      },
      { key: 'userForm', content: 'Create New User', active: false },
    ],
    [],
  );

  const rolesDropdownOptions: DropdownItemProps[] = useMemo(() => {
    if (roleName === 'Super Admin') {
      return roles
        .filter((role: Role) => role.code.toLowerCase() !== 'provider')
        .map(({ id, name }: Role) => {
          return {
            value: id.toString(),
            text: name,
          };
        });
    }
    return roles
      .filter((role: Role) => role.code.toLowerCase() !== 'provider' && role.code.toLowerCase() !== 'superadmin')
      .map(({ id, name }: Role) => {
        return {
          value: id.toString(),
          text: name,
        };
      });
  }, [roleName, roles]);

  const renderEmailError = () => {
    const emailErrorMessage = renderFieldError('email');

    return emailErrorMessage !== ''
      ? emailErrorMessage.includes('A valid email address is required')
        ? `A valid email address is required for registration.`
        : emailErrorMessage
      : consolidatedErrorMessage && consolidatedErrorMessage.includes('The email you entered is already in use')
      ? consolidatedErrorMessage
      : '';
  };

  const renderBrightreeUserKeyError = () => {
    let brightreeUserKeyErrorMessage = '';
    if (!formState.brightreeLoginId) {
      brightreeUserKeyErrorMessage = 'A valid Brightree User Key is required for registration.';
    }
    if (formState.brightreeLoginId && +formState.brightreeLoginId < 1) {
      brightreeUserKeyErrorMessage = 'Brightree User Key can not be negative value or 0.';
    }
    return brightreeUserKeyErrorMessage;
  };

  const renderError = (fieldName: keyof AdminForm) => {
    let retVal = '';

    switch (fieldName.toString()) {
      case 'email':
        retVal = renderEmailError();
        break;
      case 'brightreeLoginId':
        retVal = renderBrightreeUserKeyError();
        break;
      default:
        retVal = renderFieldError(fieldName);
        break;
    }

    return retVal;
  };

  const isFormValid = (): boolean => {
    let isValid = true;

    if (
      !email ||
      !firstName ||
      !lastName ||
      !roleId ||
      renderError('firstName') !== '' ||
      renderError('lastName') !== '' ||
      renderError('email') !== '' ||
      renderError('brightreeLoginId') !== ''
    ) {
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    if (location.pathname === PAGE_LINKS.adminUserEdit) {
      const user = accountManagementContext?.user;

      const rolesToVerify = [ROLES.superadmin.toString(), ROLES.accountadmin.toString(), ROLES.registrationadmin.toString(), ROLES.missinginformation.toString()];

      if (!user || !rolesToVerify.includes(user.role.code)) {
        navigate(PAGE_LINKS.administrators);
        return;
      }

      setFormState({
        firstName: user.firstName,
        lastName: user.lastName || '',
        email: user.email,
        roleId: user.role.id,
        brightreeLoginId: user.brightreeLoginId,
      });

      pageTitles[1].content = 'Edit User';
    } else {
      pageTitles[1].content = 'Create New User';
      setFormState(initializeFormData);
    }
  }, [accountManagementContext?.user, location.pathname, navigate, pageTitles, setFormState]);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormSubmitted(true);

    setErrorMessage('');
    setUpdateErrorMessage('');

    if (!isFormValid()) {
      return;
    }

    let result = null;

    if (accountManagementContext?.user) {
      result = await updateExistingUser({
        ...formState,
        id: accountManagementContext.user.id,
      });
    } else {
      result = await createNewUser({ ...formState });
    }

    if (result.data) {
      const popupProps = { ...modalPopupProps };

      let successMessage = '';

      if (accountManagementContext?.user) {
        successMessage = 'User details have been updated successfully.';
      } else {
        const selectedRole = roles.find((role: Role) => role.id === formState.roleId)?.name;

        successMessage = `${selectedRole} account has been created successfully. An email triggered to the user to activate the account.`;
      }

      setModalPopupProps({
        ...popupProps,
        body: successMessage,
        showPopup: true,
      });
    }
  };

  return (
    <>
      <Item as="div" className="body-content Provider-Form-Page">
        <ProviderSubHeader pageTitle={pageTitles} />
        <Container fluid>
          <Item as="div" className="content">
            {consolidatedErrorMessage && !consolidatedErrorMessage.includes('The email you entered is already in use') && (
              <Header block className="error" image={errorIcon} color="red" content={consolidatedErrorMessage} />
            )}

            <Form>
              <InputField
                name="firstName"
                label="First Name"
                placeholder="First Name"
                required
                maxLength={100}
                value={formState.firstName}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                error={formSubmitted && renderError('firstName')}
              />
              <InputField
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                maxLength={100}
                required
                value={formState.lastName}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                error={formSubmitted && renderError('lastName')}
              />
              <InputField
                name="email"
                type="email"
                label="Email"
                placeholder="Email Address"
                required
                value={email}
                maxLength={150}
                onChange={({ target: { name, value } }) => {
                  // If requirement to clear the error on text change, enable the following
                  // if (errorMessage) {
                  //   setErrorMessage('');
                  // }

                  onChange(name, value);
                }}
                error={formSubmitted && renderError('email')}
              />
              <InputField
                name="brightreeLoginId"
                type="numberString"
                label="Brightree User Key"
                placeholder="Brightree User Key"
                required
                value={formState.brightreeLoginId}
                maxLength={10}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                error={formSubmitted && renderError('brightreeLoginId')}
              />

              {roleName === 'Super Admin' ? (
                <StringField label="Role" text={roleName} />
              ) : (
                <InputSelect
                  loading={roles.length === 0}
                  name="role"
                  options={rolesDropdownOptions}
                  fluid
                  placeholder="Select Role"
                  selection="selection"
                  label="Role"
                  required
                  value={roleId}
                  onChange={(_, { value }) => {
                    onChange('roleId', value ? +value : undefined);
                  }}
                  error={formSubmitted && !roleId && 'A role must be selected'}
                />
              )}

              <InputButton
                loading={loadingCreateUser || loadingUpdateUser}
                addCssClasses="mb-0 empty-label"
                text="Save"
                fluid
                requiredHintText
                onClick={handleFormSubmit}
                disabled={!firstName && !lastName && !email && !roleId}
              />
              <InputButton
                text="Cancel"
                fluid
                addCssClasses="btn-secondary empty-label mb-0"
                onClick={() => navigate(PAGE_LINKS.administrators, { replace: true })}
              />
            </Form>
          </Item>
        </Container>
      </Item>
      {modalPopupProps.showPopup && <ModalPopup {...modalPopupProps} size="tiny" />}
    </>
  );
};

export default AdminUserForm;
