import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { mutationLoginUser } from '../services';
import { Login } from '../types';
import { GENERAL_CONSTANTS } from '../utilities/constants';

const useAccountLogin = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const [loginMutation, { loading }] = useMutation(mutationLoginUser, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const { extensions, message } = graphQLErrors[0];

        if (extensions.code === 'UNAUTHENTICATED') {
          setErrorMessage(message);
        } else if (extensions.code === 'FORBIDDEN') {
          setErrorMessage('Invalid email or password');
        } else {
          setErrorMessage(GENERAL_CONSTANTS.unexpectedErrorReachSupport);
        }
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        }
      }
    },
  });

  const accountLogin = async (formState: Login) => {
    return loginMutation({
      variables: { input: { ...formState } },
    });
  };

  return { errorMessage, setErrorMessage, accountLogin, loading };
};

export default useAccountLogin;
