import { gql } from '@apollo/client/core';

export default gql`
  query BtUploadErrors($searchOptions: SearchBTUploadErrorDto!) {
    btUploadErrors(searchOptions: $searchOptions) {
      totalRecords
      btUploadErrors {
        id
        createdBy
        createdAt
        updatedBy
        updatedAt
        isActive
        patientMissingInfoDetails
        errorType
        closedDate
      }
    }
  }
`;
