import { Form, Item, Radio } from 'semantic-ui-react';

type RadioListOptionProps = {
  text: string;
  value: string;
};

type RadioListProps = {
  name: string;
  label: string;
  options: RadioListOptionProps[];
  value: string;
  onChange: (selectedValue: string) => void;
  addClasses?: string;
  isRequired?: boolean;
};
const RadioList = ({ name, label, options, value, onChange, addClasses, isRequired = false }: RadioListProps) => {
  return (
    <Form.Field className={addClasses} required={isRequired}>
      {label ? <label>{label}</label> : ''}
      <Item as="div">
        {options.map(({ text, value: optionValue }) => {
          return (
            <Radio
              key={optionValue}
              label={text}
              name={name}
              className={`${addClasses ? addClasses +  '-item' : ''}`}
              checked={optionValue === value}
              onChange={() => onChange(optionValue)}
            />
          );
        })}
      </Item>
    </Form.Field>
  );
};

export default RadioList;
