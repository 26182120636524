import { gql } from '@apollo/client/core';

export default gql`
  query FetchProviders {
    providers {
      id
      name
      originalName
      npi
      group {
        id
        name
        originalName
        isCatchGroup
      }
      isActive
      user {
        id
      }
    }
  }
`;
