import { gql } from '@apollo/client';

export default gql`
  mutation ResetPassword($resetPasswordInput: ResetPasswordDto!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      id
      firstName
      lastName
      email
      isActive
      accessToken
    }
  }
`;
