import { gql } from '@apollo/client';

export default gql`
  mutation ChangePassword($changePasswordInput: ChangePasswordDto!) {
    changePassword(changePasswordInput: $changePasswordInput) {
      id
      firstName
      lastName
      email
      isActive
    }
  }
`;
