import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { mutationUpdateUser } from '../../../services';
import { AdminForm, ProviderForm } from '../types/userForm.type';

const useAccountUpdate = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [createUser, { loading }] = useMutation(mutationUpdateUser, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        setErrorMessage(
          graphQLErrors
            .map((error) => {
              if (error.message.includes('User already exists with this email!')) {
                return 'The email you entered is already in use. Enter a different email address.';
              } else if (error.message === 'Bad Request Exception' && error.extensions) {
                return (error.extensions as any).response.message;
              }
              return error.message;
            })
            .join(', '),
        );
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        }
      }
    },
  });

  const updateExistingUser = async (userForm: AdminForm | ProviderForm) => {
    return createUser({
      variables: {
        ...userForm,
      },
    });
  };

  return { errorMessage, setErrorMessage, updateExistingUser, loading };
};

export default useAccountUpdate;
