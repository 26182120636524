import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';
import { InputButton, InputField } from '../../components/controls';
import { ModalPopup, ModalPopupProps, ProviderFooter, ProviderHeader, ProviderSubHeader } from '../../components/shared';
import { useForm } from '../../hooks';
import { mutationResetPasswordLink } from '../../services';
import { PAGE_LINKS } from '../../utilities';
import { GENERAL_CONSTANTS } from '../../utilities/constants';

const pageHeaderTitle = [{ key: 'forgotPassword', content: 'FORGOT PASSWORD', active: true }];

type ForgotPasswordForm = {
  email: string;
};

const initializeFormData: ForgotPasswordForm = {
  email: '',
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [modalPopupProps, setModalPopupProps] = useState<ModalPopupProps>({
    title: 'ALERT',
    body: '',
    showPopup: false,
    actionButtons: [
      {
        name: 'ok',
        text: 'OK',
        onClick: () => {
          setModalPopupProps({ ...modalPopupProps, showPopup: false });
          navigate(PAGE_LINKS.login, { replace: true });
        },
      },
    ],
  });

  const [sendResetPasswordLink, { loading }] = useMutation(mutationResetPasswordLink, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message } = graphQLErrors[0];

        if (message === 'Invalid email.') {
          setErrorMessage(message);
        } else {
          setErrorMessage(GENERAL_CONSTANTS.unexpectedErrorReachSupport);
        }
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        } else {
          setErrorMessage(GENERAL_CONSTANTS.unexpectedErrorReachSupport);
        }
      }
    },
  });

  const { onChange, formState, renderFieldError } = useForm<ForgotPasswordForm>(initializeFormData);

  const { email } = formState;

  const renderEmailError = () => {
    let emailErrorMessage = renderFieldError('email');

    return emailErrorMessage !== ''
      ? emailErrorMessage
      : errorMessage && errorMessage.includes('Invalid email')
      ? 'We were unable to find an account with that email address.'
      : '';
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!email || renderEmailError() !== '') {
      return false;
    }

    const result = await sendResetPasswordLink({
      variables: {
        email,
      },
    });

    if (result.data) {
      setModalPopupProps({
        ...modalPopupProps,
        body: `We've sent a password recovery email to ${email}. Please click the link in the email to reset your password. If you do not see the email, please check your spam folder.`,
        showPopup: true,
      });
    }
  };

  return (
    <>
      <Item as="div" className="Provider-Form-Page forgot-password">
        <ProviderHeader showAppName />
        <ProviderSubHeader pageTitle={pageHeaderTitle} />
        <Container fluid>
          <Item as="div" className="content">
            <Header as="h5" textAlign="center">
              Enter the email you use to login and click reset. Check the inbox for the email you entered for a link to reset your
              password.
            </Header>
            {errorMessage && !errorMessage.includes('Invalid email.') && (
              <Header block className="error" image={errorIcon} color="red" content={errorMessage} />
            )}
            <Form>
              <InputField
                name="email"
                type="email"
                label="Email"
                placeholder="Email Address"
                required
                value={email}
                maxLength={150}
                onChange={({ target: { name, value } }) => {
                  setErrorMessage('');
                  onChange(name, value);
                }}
                error={formSubmitted && renderEmailError()}
              />
              <InputButton
                loading={loading}
                addCssClasses="mb-0 empty-label"
                text="Reset"
                fluid
                requiredHintText
                onClick={handleFormSubmit}
                disabled={!email}
              />
            </Form>
          </Item>
        </Container>
        <ProviderFooter />
      </Item>
      {modalPopupProps.showPopup && <ModalPopup {...modalPopupProps} size="tiny" />}
    </>
  );
};

export default ForgotPassword;
