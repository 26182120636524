import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { mutationLogoutUser } from '../services';
import { GENERAL_CONSTANTS } from '../utilities/constants';
import { GlobalContext } from '../context/GlobalContext';

const useAccountLogout = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { logout } = useContext(GlobalContext);
  const [isLogoutTriggered, setLogoutTriggered] = useState(false);
  const [logoutMutation, { loading }] = useMutation(mutationLogoutUser, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const { extensions, message } = graphQLErrors[0];

        if (extensions.code === 'UNAUTHENTICATED') {
          setErrorMessage(message);
        } else if (extensions.code === 'FORBIDDEN') {
          setErrorMessage('Invalid email or password');
        } else {
          setErrorMessage(GENERAL_CONSTANTS.unexpectedErrorReachSupport);
        }
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        }
      }
    },
  });

  const accountLogout = async () => {
    if (!isLogoutTriggered) {
      setLogoutTriggered(true);
      await logoutMutation();
      logout();
      return;
    }
  };

  return { errorMessage, setErrorMessage, accountLogout, loading };
};

export default useAccountLogout;
