import { gql } from '@apollo/client';

export default gql`
  mutation UpdateGlossaryPatientStatus($id: Int!, $patientStatus: String!, $patientStatusDefinition: String!) {
    updateGlossaryPatientStatus(
      patientStatus: { id: $id, patientStatus: $patientStatus, patientStatusDefinition: $patientStatusDefinition }
    ) {
      id
      createdBy
      createdAt
      updatedBy
      updatedAt
      stateId
      stateCode
      stateDescription
      pathway
      patientStatus
      patientStatusDefinition
    }
  }
`;
