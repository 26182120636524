import { useLazyQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Item, Popup } from 'semantic-ui-react';
import { InputButton } from '../../components/controls';
import { PPTable, SortDirectionProp, TableColumnProps } from '../../components/controls/ProviderTable';
import { Loading } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { queryUsersByRole } from '../../services';
import { Role } from '../../types';
import { User } from '../../types/user.type';
import { DEFAULT_NUMBER_OF_RECORDS_PER_PAGE, PAGE_LINKS } from '../../utilities/constants';
import { convertToPST } from '../../utilities/sharedFunctions';
import { AccountManagementContext } from './context';
import useActiveStatusToggle from './hooks/useActiveStatusToggle';
import useSendActivationLink from './hooks/useSendActivationLink';

type AdministratorsTableProps = {
  onSearchOptionsChange?: (options: { [k: string]: any }) => void;
};

const DEFAULT_SORT_COLUMN = 'fullName';

const SORT_FIELD_MAPPER = {
  fullName: 'NAME',
  email: 'EMAIL',
  lastLoggedInAt: 'LASTSIGNIN',
  brightreeLoginId: 'BRIGHTREELOGINKEY',
  role: 'ROLE',
};

export const AdministratorsTable = ({ onSearchOptionsChange }: AdministratorsTableProps) => {
  const navigate = useNavigate();
  const accountManagementContext = useContext(AccountManagementContext);
  const { roles, user: currentLoggedInUser, logout } = useContext(GlobalContext);

  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_NUMBER_OF_RECORDS_PER_PAGE);

  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('ascending');

  const [searchParams] = useSearchParams();

  const { activeStatusToggledUser, setUserStatusChange } = useActiveStatusToggle();
  const { triggerActivationLinkEmail } = useSendActivationLink();

  const [getUsers, { data: usersData, error: errorFetchingUsers, loading }] = useLazyQuery(queryUsersByRole, {
    fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
  });

  const handleSendIconClick = useCallback(
    async (userId: number) => {
      setLoadingOnTableAction(true);
      await triggerActivationLinkEmail(userId);
      setLoadingOnTableAction(false);
    },
    [triggerActivationLinkEmail],
  );

  const handleActiveToggleClick = useCallback(
    async (userId: number, isActive: boolean) => {
      setLoadingOnTableAction(true);
      await setUserStatusChange(userId, isActive);
      setLoadingOnTableAction(false);
    },
    [setUserStatusChange],
  );

  const onColumnDataRender = useCallback(
    (column: TableColumnProps, rowData: User) => {
      if (column.name === 'role') {
        return rowData.role.name;
      } else if (column.name === 'lastLoggedInAt') {
        return rowData.lastLoggedInAt ? convertToPST(rowData.lastLoggedInAt).split(',')[0] : '';
      } else if (column.name !== 'actions') {
        return null;
      } else if (rowData.email === currentLoggedInUser?.email) {
        return null;
      }

      return (
        <Item className="actions-items">
          <Popup
            content="Edit"
            trigger={
              <span
                //  disabled={isCurrentUserRecord}
                className="edit-icon"
                onClick={() => {
                  accountManagementContext?.setUser(rowData);
                  navigate(PAGE_LINKS.adminUserEdit);
                }}
              />
            }
          />
          <Popup
            content="Send activation link"
            trigger={<span className="send-icon" onClick={() => handleSendIconClick(rowData.id)} />}
          />
          <InputButton
            addCssClasses={`btn-sm ${rowData.isActive ? 'active' : 'inactive'} `}
            inline={false}
            text={rowData.isActive ? 'Deactivate' : 'Activate'}
            onClick={() => {
              handleActiveToggleClick(rowData.id, !rowData.isActive);
            }}
          />
        </Item>
      );
    },
    [accountManagementContext, currentLoggedInUser?.email, handleActiveToggleClick, handleSendIconClick, navigate],
  );

  const tableColumns: TableColumnProps[] = useMemo(
    () => [
      {
        name: 'id',
        label: 'Id',
        isVisible: false,
      },
      {
        name: 'fullName',
        label: 'Name',
        addClasses: 'width-20-percent word-break',
        isSortable: false,
      },
      {
        name: 'email',
        label: 'Email',
        addClasses: 'width-30-percent word-break',
        isSortable: false,
      },
      {
        name: 'role',
        label: 'Role',
        addClasses: 'width-10-percent',
        onColumnDataRender: onColumnDataRender,
        isSortable: false,
      },
      {
        name: 'brightreeLoginId',
        label: 'Brightree User Key',
        addClasses: 'width-10-percent',
        isSortable: false,
      },
      {
        name: 'lastLoggedInAt',
        label: 'Last Sign In',
        addClasses: 'width-05-percent',
        onColumnDataRender: onColumnDataRender,
      },
      {
        name: 'actions',
        label: '',
        isSortable: false,
        addClasses: 'width-05-percent',
        onColumnDataRender: onColumnDataRender,
      },
    ],
    [onColumnDataRender],
  );

  if (errorFetchingUsers) {
    const {
      graphQLErrors: [{ message }],
    } = errorFetchingUsers;

    if (message === 'Invalid token' || message === 'Unauthorized') {
      logout();
      // return <Navigate to={PAGE_LINKS.login} />;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUpdatedUsers = (currentPageNumber: number) => {
    const accountStatus = searchParams.get('accountStatus')?.trim().toLowerCase() || '';
    const doctorGroupName = searchParams.get('doctorGroup')?.trim().toLowerCase() || '';
    const name = searchParams.get('name')?.trim().toLowerCase() || '';
    const email = searchParams.get('email')?.trim().toLowerCase() || '';

    const sortDir = sortDirection === 'ascending' ? 'ASC' : sortField === 'lastLoggedInAt' ? 'DESC' : 'DESC';

    const filter = {
      roleIds: roles
        .filter((role: Role) => role.code !== 'provider')
        ?.map((roleInfo) => roleInfo.id)
        .join(','),
      name,
      email,
      providerGroup: doctorGroupName,
      isActive: accountStatus === 'all' ? null : accountStatus === 'active' ? true : false,
    };

    const searchOptions = {
      skip: (currentPageNumber - 1) * pageSize,
      take: pageSize,
      sortBy: SORT_FIELD_MAPPER[(sortField ? sortField : DEFAULT_SORT_COLUMN) as keyof typeof SORT_FIELD_MAPPER],
      sortDirection: sortDir,
      filter,
    };

    getUsers({
      variables: {
        searchOptions: { ...searchOptions },
      },
    }).then(({ data }) => {
      setLoadingOnTableAction(false);
      if (onSearchOptionsChange) {
        onSearchOptionsChange({
          ...searchOptions,
          take: data?.users.totalRecords,
          skip: 0,
        });
      }
    });
  };

  useEffect(() => {
    getUpdatedUsers(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortDirection, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
    getUpdatedUsers(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getUpdatedUsers(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatusToggledUser, currentPage]);

  const { totalRecords, users } = usersData?.users || {};

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {isLoading || (loading && !isLoadingOnTableAction) ? (
        <Loading show={true} />
      ) : (
        <>
          <PPTable
            data={users}
            columns={tableColumns}
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={totalRecords}
            sortField={sortField}
            sortDirection={sortDirection as SortDirectionProp}
            onSort={(sortBy, sortDir) => {
              setLoadingOnTableAction(true);
              setCurrentPage(1);
              setSortField(sortBy);
              setSortDirection(sortDir);
            }}
            onPageChange={(updatedCurrentPage, updatedPageSize) => {
              setLoadingOnTableAction(true);
              setCurrentPage(updatedCurrentPage);
              setPageSize(updatedPageSize);
            }}
          />
          {isLoadingOnTableAction && <Loading show={true} />}
        </>
      )}
    </>
  );
};
