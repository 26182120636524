import { gql } from '@apollo/client/core';

export default gql`
  query FetchPatientsByProvider($searchOptions: SearchPatientsDto!) {
    patients(searchOptions: $searchOptions) {
      totalRecords
      patients {
        id
        firstName
        lastName
        fullName
        dateOfBirth
        referringProviderId
        referringProviderName
        orderingProviderId
        orderingProviderName
        patientStatus
        patientStatusDefinition
        statusDate
        daysInStatus
        mrn
        brightreeId
        brightreePatientId
        brightreeLink
        betternightLink
        betterNightUserId
      }
    }
  }
`;
