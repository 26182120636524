import { Form, Item } from 'semantic-ui-react';

interface StringFieldProps {
  text?: string;
  label?: string;
  addClasses?: string;
  inline?: boolean;
}

export const StringField = ({ label, addClasses, text, inline = true }: StringFieldProps) => {
  return (
    <Form.Field className={addClasses} inline={inline}>
      {label ? <label className="padding-top-04px">{label}</label> : ''}
      <Item as="div">{text}</Item>
    </Form.Field>
  );
};
