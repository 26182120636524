import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Sidebar } from 'semantic-ui-react';
import { GlobalContext } from '../../../context/GlobalContext';
import { PAGE_LINKS } from '../../../utilities';
import './ProviderSidebar.scss';
import { useAccountLogout } from '../../../hooks';

type ProviderSidebarProps = {
  visible: boolean;
  onHide: any;
};

const ProviderSidebar = ({ visible, onHide }: ProviderSidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedPage, setSelectedPage] = useState(location.pathname);
  const {
    user: currentLoggedInUser,
    isPageInEditMode,
    shouldAllowSelectedNavigation,
    setAllowSelectedNavigation,

    setShowChangesDiscardWarningModal,
  } = useContext(GlobalContext);

  const { accountLogout } = useAccountLogout();
  const userRole = currentLoggedInUser?.role?.code;

  const handleLinkClick = useCallback(
    (redirectionUrl: string) => {
      setSelectedPage(redirectionUrl);

      if (isPageInEditMode && redirectionUrl !== selectedPage) {
        setShowChangesDiscardWarningModal(true);
        setAllowSelectedNavigation(false);
      } else {
        onHide();
        navigate(redirectionUrl);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, onHide],
  );

  const shouldDisableLink = useCallback(
    (currentPageUrl: string) => {
      return location.pathname === currentPageUrl;
    },
    [location.pathname],
  );

  useEffect(() => {
    if (shouldAllowSelectedNavigation) {
      onHide();
      navigate(selectedPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAllowSelectedNavigation]);

  const renderPatients = useMemo(() => {
    return (
      <Menu.Item as="a" onClick={() => handleLinkClick(PAGE_LINKS.patients)} disabled={shouldDisableLink(PAGE_LINKS.patients)}>
        Patient Status
      </Menu.Item>
    );
  }, [handleLinkClick, shouldDisableLink]);

  const renderMissingInformation = useMemo(() => {
    return (
      <Menu.Item
        as="a"
        onClick={() => handleLinkClick(PAGE_LINKS.missingInformation)}
        disabled={shouldDisableLink(PAGE_LINKS.missingInformation)}
      >
        Missing Information
      </Menu.Item>
    );
  }, [handleLinkClick, shouldDisableLink]);

  const renderProviders = useMemo(() => {
    return (
      <Menu.Item as="a" onClick={() => handleLinkClick(PAGE_LINKS.providers)} disabled={shouldDisableLink(PAGE_LINKS.providers)}>
        Providers
      </Menu.Item>
    );
  }, [handleLinkClick, shouldDisableLink]);

  const renderAdministrators = useMemo(() => {
    return (
      <Menu.Item
        as="a"
        onClick={() => handleLinkClick(PAGE_LINKS.administrators)}
        disabled={shouldDisableLink(PAGE_LINKS.administrators)}
      >
        Administrators
      </Menu.Item>
    );
  }, [handleLinkClick, shouldDisableLink]);

  const renderGlossary = useMemo(() => {
    return (
      <Menu.Item as="a" onClick={() => handleLinkClick(PAGE_LINKS.glossary)} disabled={shouldDisableLink(PAGE_LINKS.glossary)}>
        Glossary
      </Menu.Item>
    );
  }, [handleLinkClick, shouldDisableLink]);

  const renderChangePassword = useMemo(() => {
    return (
      <Menu.Item
        as="a"
        onClick={() => handleLinkClick(PAGE_LINKS.changePassword)}
        disabled={shouldDisableLink(PAGE_LINKS.changePassword)}
      >
        Change Password
      </Menu.Item>
    );
  }, [handleLinkClick, shouldDisableLink]);

  const renderSignOut = useMemo(() => {
    return (
      <Menu.Item
        as="a"
        onClick={async () => {
          accountLogout();
        }}
      >
        Sign out
      </Menu.Item>
    );
  }, [accountLogout]);

  const renderMenu = () => {
    switch (userRole) {
      case 'superadmin':
        return (
          <>
            {renderPatients}
            {renderMissingInformation}
            {renderProviders}
            {renderAdministrators}
            {renderGlossary}
            {renderChangePassword}
            {renderSignOut}
          </>
        );
      case 'accountadmin':
        return (
          <>
            {renderPatients}
            {renderMissingInformation}
            {renderProviders}
            {renderChangePassword}
            {renderSignOut}
          </>
        );
      case 'provider':
        return (
          <>
            {renderPatients}
            {renderChangePassword}
            {renderSignOut}
          </>
        );
      case 'missinginformation':
        return (
          <>
            {renderMissingInformation}
            {renderChangePassword}
            {renderSignOut}
          </>
        );
      default:
        return (
          <>
            {renderAdministrators}
            {renderChangePassword}
            {renderSignOut}
          </>
        );
    }
  };

  return (
    <>
      <Sidebar className="Provider-Sidebar" as={Menu} animation="overlay" onHide={onHide} vertical visible={visible}>
        {renderMenu()}
      </Sidebar>
    </>
  );
};

export default ProviderSidebar;
