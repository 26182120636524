import { gql } from '@apollo/client/core';

export default gql`
  query FetchLastSynced {
    lastSynced {
      id
      lastSyncedAt,
      status
    }
  }
`;
