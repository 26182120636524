import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Item, Label, MenuItem, TabPane, TabProps } from 'semantic-ui-react';
import { TabMenu } from '../../components/controls';
import { ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { queryWorklistsCountByType } from '../../services';
import { WorklistsCountByType } from '../../types';
import { PAGE_LINKS, ROLES, WORKLIST_TYPES } from '../../utilities/constants';
import { transformSearchedParamEntries } from '../../utilities/sharedFunctions';
import { PatientMissingInfo, PatientProviderContacting, ProviderContactMissingInfo } from './';
import { Audits } from './Audits/Audits';
import './MissingInformation.scss';
import BTUploadErrors from './PatientProviderContacting/BTUploadErrors/BTUploadErrors';
import Reporting from './Reporting/Reporting';

const PAGE_TITLE = [{ key: 'Missing Information', content: 'Missing Information', active: true }];

const TAB_MENU_PATHS = {
  '0': PAGE_LINKS.missingInformationPatient,
  '1': PAGE_LINKS.missingInformationContacting,
  '2': PAGE_LINKS.missingInformationFinalReview,
  '3': PAGE_LINKS.missingInformationFailedNotifications,
  '4': PAGE_LINKS.missingInformationBTUploadErrors,
  '5': PAGE_LINKS.missingInformationSugarContactRequired,
  '6': PAGE_LINKS.missingInformationContactDetailsRequired,
  '7': PAGE_LINKS.missingInformationAudits,
  '8': PAGE_LINKS.missingInformationReporting,
};

type TabMenuKeys = keyof typeof TAB_MENU_PATHS;

const PAGE_NOTES = {
  SUGAR_CONTACT_REQUIRED:
    'The system was not able to link a Sugar Contact with the ordering provider. There are either multiple Sugar Contacts with a matching Doc Key to this ordering provider, or there are no Sugar Contacts with a matching Doc Key. If there are multiple, the Doc Key should be deleted out of the wrong contact and kept in the right one. If there are no Sugar contacts with a matching Doc Key, then the Doc Key should be added to the right contact or a new contact should be created with the Doc Key. We have listed below any Sugar Contacts found that had a matching Doc Key or NPI for your reference. Click "Close" when these updates have been made.',
  CONTACT_DETAILS_REQUIRED: `The contact details for these Brightree providers are missing in Sugar. The task here is to update the missing information contact details in Sugar so the system knows how to contact the provider about missing information. Click "Close" after contact details have been updated in Sugar if the provider is still in this list.`,
  PATIENT_MISSING_INFO: `Enter a Brightree ID below to search for a patient to generate a Missing Information PDF, add them to the automated Missing Information system, or edit a patient's existing record. A few reminders:
    <ul>
      <li>A patient can only have one Missing Information record open at a time.</li>      
      <li>If you just want to download a Missing Information PDF for a patient, use the "Open Form to Generate PDF" button.</li>
      <li>If you want to add a patient to the automated system, use the "Add to Missing Information System" button. In order to do this, their Brightree SO must be in the Missing Info 0 WIP state and their provider must have the required contact information in Sugar.</li>
      <li>When you add them to the automated Missing Information system, their SO WIP state will automatically be updated to the Missing Info Automation 1 WIP state. If you are unable to add a patient to the system, manually update the WIP state of the patient's SO accordingly.</li>
      <li>Click the "Received All" button when we receive all the patient's missing information and then manually update the WIP state of the patient's SO accordingly.</li>
      <li>Use the "Close Record" button if you need to remove a patient from the Missing Information system before we receive everything and then manually update the WIP state of the patient's SO accordingly.</li>
    </ul>`,
  CONTACTING: `The patients in this list are missing information that is preventing them from moving forward in the BetterNight process. Use the "Download PDF" button to review a patient's PDF before sending it. Use the "Send Next Attempt" button to trigger a fax or email to the ordering provider requesting the missing patient information. Click the "Received All" button when we receive all the patient's missing information to remove them from the list and then update the WIP state of the patient's Brightree Sales Order accordingly.`,
  FINAL_REVIEW: `The third contact attempt to the ordering provider has been completed for these patients. Use the "Received All" button if we now have everything. Once the patient has been in this list for 7 days, they will automatically leave the list and the WIP state of their Sales Order will update to RPA Void. Use the "Close Record" button if you need to manually remove the patient from this list and close them out of the Missing Information system before their SO is voided.`,
  FAILED_NOTIFICATIONS: `The notification attempt to the provider failed. Please check to make sure the Missing Information contact details in the provider's Sugar Contact are valid. Use the "Return to Contacting List" button after you have made any necessary updates to the contact and you can try the next contact again from that list or generate the PDF to send outside of this system and then update the Next Contact Attempt number and date in the Missing Information record as needed. Use the "Received All" button if we are no longer missing information for this patient. Use the "Close Record" button if this patient's Missing Information needs to be handled manually now outside of this system and update the WIP of their SO accordingly.`,
  BT_UPLOAD_ERRORS: `These are patients whose update to Brightree listed below was unsuccessful. The Brightree patient account needs to be manually updated. Note that if an update was made in Brightree after the error appeared in this list, the error won’t automatically disappear from this list, so it’s possible that it has already been fixed. Use the "Fixed BT Record" button to remove a patient from this list once you have posted a note to their Brightree account, updated their sales order to the correct WIP state, or confirmed the account has already been fixed.`,
};

const MissingInformation = () => {
  const { logout } = useContext(GlobalContext);

  const {
    user: currentLoggedInUser,
    setShowChangesDiscardWarningModal,
    setAllowSelectedNavigation,
    shouldAllowSelectedNavigation,
    isPageInEditMode,
  } = useContext(GlobalContext);
  const userRole = currentLoggedInUser?.role?.code;

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const [totalRecordsInCurrentPage, setTotalRecordsInCurrentPage] = useState<number | undefined>(undefined);

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();

  const [getWorklistsCountByType, { data, error: errorFetchingData }] = useLazyQuery(queryWorklistsCountByType, {
    fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
  });

  const renderPanes = (records: WorklistsCountByType[]) => {
    let contactingCount = 0;
    let finalReviewCount = 0;
    let failedNotificationsCount = 0;
    let btUploadErrorsCount = 0;

    let sugarContactRequiredCount = 0;
    let contactDetailsRequiredCount = 0;

    const showActiveRecords = searchParams.get('isActive') && searchParams.get('isActive') === 'false' ? false : true;

    if (records && records.length > 0) {
      contactingCount =
        location.pathname === PAGE_LINKS.missingInformationContacting && totalRecordsInCurrentPage !== undefined
          ? totalRecordsInCurrentPage
          : records.find(
              (rec: any) =>
                rec.worklistTypeCode ===
                `PATIENT_MISSING_INFO${
                  location.pathname === PAGE_LINKS.missingInformationContacting && !showActiveRecords ? '_RECEIVED' : ''
                }`,
            )?.worklistItemsCount || 0;

      finalReviewCount =
        location.pathname === PAGE_LINKS.missingInformationFinalReview && totalRecordsInCurrentPage !== undefined
          ? totalRecordsInCurrentPage
          : records.find(
              (rec: any) =>
                rec.worklistTypeCode ===
                `PATIENT_MISSING_INFO_FINAL_REVIEW${
                  location.pathname === PAGE_LINKS.missingInformationFinalReview && !showActiveRecords ? '_CLOSED' : ''
                }`,
            )?.worklistItemsCount || 0;

      failedNotificationsCount =
        location.pathname === PAGE_LINKS.missingInformationFailedNotifications && totalRecordsInCurrentPage !== undefined
          ? totalRecordsInCurrentPage
          : records.find(
              (rec: any) =>
                rec.worklistTypeCode ===
                `PATIENT_REFERRAL_CONTACT_FAILED_NOTIFICATION${
                  location.pathname === PAGE_LINKS.missingInformationFailedNotifications && !showActiveRecords ? '_CLOSED' : '_OPEN'
                }`,
            )?.worklistItemsCount || 0;

      btUploadErrorsCount =
        location.pathname === PAGE_LINKS.missingInformationBTUploadErrors && totalRecordsInCurrentPage !== undefined
          ? totalRecordsInCurrentPage
          : records.find(
              (rec: any) =>
                rec.worklistTypeCode ===
                `BT_UPLOAD_ERROR_LOG${
                  location.pathname === PAGE_LINKS.missingInformationBTUploadErrors && !showActiveRecords ? '_CLOSED' : '_OPEN'
                }`,
            )?.worklistItemsCount || 0;

      sugarContactRequiredCount =
        location.pathname === PAGE_LINKS.missingInformationSugarContactRequired && totalRecordsInCurrentPage !== undefined
          ? totalRecordsInCurrentPage
          : records.find(
              (rec: any) =>
                rec.worklistTypeCode ===
                `SUGAR_CONTACT_REQUIRED${
                  location.pathname === PAGE_LINKS.missingInformationSugarContactRequired && !showActiveRecords ? '_CLOSED' : '_OPEN'
                }`,
            )?.worklistItemsCount || 0;

      contactDetailsRequiredCount =
        location.pathname === PAGE_LINKS.missingInformationContactDetailsRequired && totalRecordsInCurrentPage !== undefined
          ? totalRecordsInCurrentPage
          : records.find(
              (rec: any) =>
                rec.worklistTypeCode ===
                `CONTACT_DETAILS_REQUIRED${
                  location.pathname === PAGE_LINKS.missingInformationContactDetailsRequired && !showActiveRecords ? '_CLOSED' : '_OPEN'
                }`,
            )?.worklistItemsCount || 0;
    }

    const tabs = [
      {
        menuItem: {
          key: 'patient',
          content: 'Manage Missing Patient Information',
        },
        render: () => (
          <TabPane>
            <PatientMissingInfo
              note={PAGE_NOTES.PATIENT_MISSING_INFO}
              onSave={() => {
                getWorklistsCountByType();
              }}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem key="contacting">
            <span>Contacting</span>
            <Label circular color="orange" className="notification-badge">
              {contactingCount}
            </Label>
          </MenuItem>
        ),
        render: () => (
          <TabPane>
            <PatientProviderContacting
              note={PAGE_NOTES.CONTACTING}
              onWorklistUpdate={(totalRecords?: number) => {
                setTotalRecordsInCurrentPage(totalRecords);
              }}
              refreshTabCounts={() => {
                getWorklistsCountByType();
              }}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem key="finalReview">
            <span>Final Review</span>
            <Label circular color="orange" className="notification-badge">
              {finalReviewCount}
            </Label>
          </MenuItem>
        ),
        render: () => (
          <TabPane>
            <PatientProviderContacting
              note={PAGE_NOTES.FINAL_REVIEW}
              onWorklistUpdate={(totalRecords?: number) => {
                setTotalRecordsInCurrentPage(totalRecords);
              }}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem key="failedNotifications">
            <span>Failed Notifications</span>
            <Label circular color="orange" className="notification-badge">
              {failedNotificationsCount}
            </Label>
          </MenuItem>
        ),
        render: () => (
          <TabPane>
            <PatientProviderContacting
              note={PAGE_NOTES.FAILED_NOTIFICATIONS}
              onWorklistUpdate={(totalRecords?: number) => {
                setTotalRecordsInCurrentPage(totalRecords);
              }}
              refreshTabCounts={() => {
                getWorklistsCountByType();
              }}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem key="btUploadErrors">
            <span>BT Upload Errors</span>
            <Label circular color="orange" className="notification-badge">
              {btUploadErrorsCount}
            </Label>
          </MenuItem>
        ),
        render: () => (
          <TabPane>
            <BTUploadErrors
              note={PAGE_NOTES.BT_UPLOAD_ERRORS}
              onWorklistUpdate={(totalRecords?: number) => {
                setTotalRecordsInCurrentPage(totalRecords);
              }}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem key="sugarContactRequired">
            <span>Sugar Contact Required</span>
            <Label circular color="orange" className="notification-badge">
              {sugarContactRequiredCount}
            </Label>
          </MenuItem>
        ),
        render: () => {
          return (
            <TabPane>
              <ProviderContactMissingInfo
                worklistType={WORKLIST_TYPES.SUGAR_CONTACT_REQUIRED.toString()}
                note={PAGE_NOTES.SUGAR_CONTACT_REQUIRED}
                onWorklistUpdate={(totalRecords?: number) => {
                  setTotalRecordsInCurrentPage(totalRecords);
                }}
              />
            </TabPane>
          );
        },
      },
      {
        menuItem: (
          <MenuItem key="contactDetailsRequired">
            <span>Contact Details Required</span>
            <Label circular color="orange" className="notification-badge">
              {contactDetailsRequiredCount}
            </Label>
          </MenuItem>
        ),
        render: () => {
          return (
            <TabPane>
              <ProviderContactMissingInfo
                worklistType={WORKLIST_TYPES.CONTACT_DETAILS_REQUIRED.toString()}
                note={PAGE_NOTES.CONTACT_DETAILS_REQUIRED}
                onWorklistUpdate={(totalRecords?: number) => {
                  setTotalRecordsInCurrentPage(totalRecords);
                }}
              />
            </TabPane>
          );
        },
      },
    ];

    if (userRole && [ROLES.superadmin.toString(), ROLES.accountadmin.toString()].includes(userRole)) {
      tabs.push(
        {
          menuItem: (
            <MenuItem key="audits">
              <span>Audits</span>
            </MenuItem>
          ),
          render: () => {
            return (
              <TabPane>
                <Audits />
              </TabPane>
            );
          },
        },
        {
          menuItem: (
            <MenuItem key="reporting">
              <span>Reporting</span>
            </MenuItem>
          ),
          render: () => {
            return (
              <TabPane>
                <Reporting />
              </TabPane>
            );
          },
        },
      );
    }

    return tabs;
  };

  const navigateToPage = (pageIndex: number) => {
    const selectedTabIndex = pageIndex!.toString() as TabMenuKeys;
    const pageURL = TAB_MENU_PATHS[selectedTabIndex];
    navigate(pageURL);
  };

  useEffect(() => {
    if (shouldAllowSelectedNavigation) {
      if (activeTab !== selectedTab) {
        navigateToPage(selectedTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAllowSelectedNavigation]);

  useEffect(() => {
    getWorklistsCountByType();
  }, [getWorklistsCountByType]);

  useEffect(() => {
    setIsComponentMounted(true);

    let isNavigatedPathFound = false;
    const navigatedPage = location.pathname;
    Object.keys(TAB_MENU_PATHS).forEach((key) => {
      const selectedTabIndex = key.toString() as TabMenuKeys;
      if (TAB_MENU_PATHS[selectedTabIndex] === navigatedPage) {
        setSelectedTab(+key);
        setActiveTab(+key);
        isNavigatedPathFound = true;
        return;
      }
    });

    if (!isNavigatedPathFound) {
      navigate(PAGE_LINKS.missingInformationPatient, { replace: true });
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    setSearchParams(transformSearchedParamEntries(searchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, selectedTabData: TabProps) => {
    event.preventDefault();

    const activeIndex = +selectedTabData.activeIndex!;

    setSelectedTab(activeIndex!);
    setTotalRecordsInCurrentPage(undefined);

    if (isPageInEditMode && activeTab !== activeIndex) {
      setShowChangesDiscardWarningModal(true);
      setAllowSelectedNavigation(false);
    } else {
      navigateToPage(activeIndex);

      const tabsToIgnoreCountReload = [7, 8];
      if (!tabsToIgnoreCountReload.includes(activeIndex)) {
        getWorklistsCountByType();
      }
    }
  };

  if (errorFetchingData) {
    const {
      graphQLErrors: [{ message }],
    } = errorFetchingData;

    if (message === 'Invalid token' || message === 'Unauthorized') {
      logout();
      // return <Navigate to={PAGE_LINKS.login} />;
    }
  }

  const worklistsCountByType = data?.worklistsCountByType || {};

  return (
    <>
      {isComponentMounted && (
        <Item as="div" className="missing-information">
          <ProviderSubHeader pageTitle={PAGE_TITLE} />
          <TabMenu
            panes={renderPanes(worklistsCountByType)}
            activeTabIndex={activeTab}
            className="pp-tab-menu"
            onTabChange={handleTabChange}
          />
        </Item>
      )}
    </>
  );
};

export default MissingInformation;
