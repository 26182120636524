import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { mutationToggleUserActiveStatus } from '../../../services';

type ActiveToggle = {
  userId: number;
  isActive: boolean;
};

const useActiveStatusToggle = () => {
  const [activeStatusToggledUser, setActiveStatusToggledUser] = useState<ActiveToggle | null>(null);

  const toastId = React.useRef<string | number | null>(null);

  const [toggleUserActiveStatus] = useMutation(mutationToggleUserActiveStatus, {
    onError() {
      const message = `Error occurred while updating user active status`;

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);

      if (activeStatusToggledUser) {
        const { userId, isActive } = activeStatusToggledUser;

        setActiveStatusToggledUser({
          userId: userId,
          isActive: !isActive,
        });

        toggleUserActiveStatus({
          variables: {
            userInput: {
              id: userId,
              isActive: !isActive,
            },
          },
        });
      }
    },
  });

  const setUserStatusChange = async (userId: number, isActive: boolean) => {
    const result = await toggleUserActiveStatus({
      variables: {
        userInput: {
          id: userId,
          isActive: isActive,
        },
      },
    });

    if (result.data) {
      setActiveStatusToggledUser({
        userId,
        isActive,
      });

      const message = `User ${isActive ? ' activated and activation email sent' : 'deactivated'}`;

      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      toastId.current = toast(message);
      return result.data.toggleUserActiveStatus;
    }

    return null;
  };

  return { activeStatusToggledUser, setUserStatusChange };
};

export default useActiveStatusToggle;
