import { debounce } from 'lodash';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Form, Item } from 'semantic-ui-react';

import 'react-datepicker/dist/react-datepicker.css';

interface DateRangePickerProps {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  onDateChange: (range: { startDate: Date | null; endDate: Date | null }) => void;
  onDatePickerClose?: () => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ name, startDate, endDate, onDateChange, onDatePickerClose }) => {
  // const [startDate, setStartDate] = useState<Date | null | undefined>(startDate ? new Date(startDate) : null);
  // const [endDate, setEndDate] = useState<Date | null | undefined>(endDate ? new Date(endDate) : null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    let [start, end] = dates;

    if (!start && !end) {
      start = startDate;
      end = endDate;
    }

    // setStartDate(start ? new Date(start.toISOString()) : null);
    // setEndDate(end ? new Date(end.toISOString()) : null);

    if (onDateChange) {
      onDateChange({ startDate: start, endDate: end });
    }
  };

  const onCloseEvent = () => {
    if (onDatePickerClose) {
      onDatePickerClose();
    }
  };

  // useEffect(() => {
  //   if (!startDate && !endDate) {
  //     setStartDate(defaultStartDate);
  //     setEndDate(defaultEndDate);

  //     if (onDateChange) {
  //       onDateChange({ startDate: defaultStartDate, endDate: defaultEndDate });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [defaultStartDate, defaultEndDate]);

  const handleCalendarClose = debounce(onCloseEvent, 100);

  return (
    <Form.Field>
      <Item as="div">
        <DatePicker
          name={name}
          showIcon
          toggleCalendarOnIconClick
          yearDropdownItemNumber={100}
          placeholderText={'MM/dd/yyyy - MM/dd/yyyy'}
          className=""
          onChange={handleDateChange}
          onCalendarClose={handleCalendarClose}
          onClickOutside={handleCalendarClose}
          onBlur={handleCalendarClose}
          onKeyDown={handleKeyDown}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          preventOpenOnFocus={true} // Disable opening on focus
          onFocus={(e) => e.target.blur()} // Prevent keyboard navigation
        />
      </Item>
    </Form.Field>
  );
};
