import { useQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';
import { ProviderFooter, ProviderHeader, ProviderSubHeader } from '../../components/shared';
import { queryValidateTwoFactorToken } from '../../services';
import { GENERAL_CONSTANTS, PAGE_LINKS } from '../../utilities/constants';

import { GlobalContext } from '../../context/GlobalContext';
import { Provider, Role } from '../../types';

type ActivationToken = {
  exp: number;
  iat: number;
  sub: number;
  isTwoFactorAuthenticationCompleted: boolean;
  maxSessionTime: number;
  rememberMe: boolean;
  user: UserDetails;
};

type UserDetails = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isActivationToken: boolean;
  isProvider: boolean;
  provider: Provider;
  providerGroup: string;
  useSalutation: boolean;
  fullName: string;
  updatedAt: Date;
  role: Role;
};

const PageTitle = [{ key: 'Two-Factor authentication', content: 'Two-Factor authentication ', active: true }];
const inValidTokenMessage = ['Invalid Token', 'Invalid Activation token.'];

const LinkValidation = () => {
  const navigate = useNavigate();
  const { twoFactorPasswordToken } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const { setUser, setMaxSessionTime } = useContext(GlobalContext);

  const {
    data: validateTokenResponse,
    loading: validateTokenLoading,
    error: validateTokenError,
  } = useQuery(queryValidateTwoFactorToken, {
    variables: {
      twoFactorToken: twoFactorPasswordToken,
    },
    fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
  });

  useEffect(() => {
    if (validateTokenResponse?.twoFactorValidateToken) {
      try {
        const decodedToken: ActivationToken = jwtDecode(validateTokenResponse?.twoFactorValidateToken || '');
        if (decodedToken.exp * 1000 < Date.now()) {
          navigate(PAGE_LINKS.loginLinkExpiration);
          return;
        }

        const userDetailsFromToken = decodedToken.user as UserDetails;
        userDetailsFromToken.id = decodedToken.sub;
        sessionStorage.setItem(GENERAL_CONSTANTS.authToken, validateTokenResponse?.twoFactorValidateToken);
        setUser(decodedToken?.user);
        setMaxSessionTime(+decodedToken.maxSessionTime);
        navigate(PAGE_LINKS.home);
      } catch (error) {
        navigate(PAGE_LINKS.loginLinkExpiration);
      }
    }
  }, [
    navigate,
    twoFactorPasswordToken,
    validateTokenLoading,
    validateTokenResponse?.twoFactorValidateToken,
    setUser,
    setMaxSessionTime,
  ]);

  if (validateTokenError) {
    const { graphQLErrors } = validateTokenError;
    if (graphQLErrors && graphQLErrors.length > 0) {
      const errorDetails = graphQLErrors[0];
      if (inValidTokenMessage.includes(errorDetails.message)) {
        navigate(PAGE_LINKS.loginLinkExpiration);
      }
    } else {
      if (validateTokenError && Array.isArray(validateTokenError)) {
        setErrorMessage(validateTokenError.map((error) => error.message).join(', '));
      }
    }
  }

  return (
    <>
      <Item as="div" className="two-factor-auth">
        <ProviderHeader />
        <ProviderSubHeader pageTitle={PageTitle} />
        <Container fluid>
          <Item as="div" className="content">
            {errorMessage && <Header block className="error" image={errorIcon} color="red" content={errorMessage} />}
          </Item>
        </Container>
        <ProviderFooter />
      </Item>
    </>
  );
};

export default LinkValidation;
