import { Tab, TabProps } from 'semantic-ui-react';
import './TabMenu.scss';

export interface TabMenuProps extends React.HTMLAttributes<HTMLButtonElement> {  
  activeTabIndex: number;  
  className?: string;
  onTabChange?: ((event: React.MouseEvent<HTMLDivElement>, data: TabProps) => void) | undefined
  panes: any;
}

export const TabMenu = ({ panes, className, activeTabIndex, onTabChange }: TabMenuProps) => {
  return <Tab className={className} panes={panes} activeIndex={activeTabIndex} onTabChange={onTabChange} />;
};
