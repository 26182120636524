import { memo, useCallback } from 'react';
import { Icon, Item, Pagination, Table } from 'semantic-ui-react';
import { SortDirectionProp, TableProps } from '.';
import { DEFAULT_NUMBER_OF_RECORDS_PER_PAGE, TABLE_PAGE_COUNTS } from '../../../utilities/constants';
import { getColumnValue, pageScrollTop } from '../../../utilities/sharedFunctions';
import { InputSelect } from '../form/InputSelect/InputSelect';
import './ProviderTable.scss';
import { StringField } from '../form/StringField/StringField';

export const PPTable = memo(
  ({
    columns,
    data,
    totalRecords,
    currentPage = 1,
    pageSize = DEFAULT_NUMBER_OF_RECORDS_PER_PAGE,
    isPaginationRequired = true,
    sortField,
    sortDirection,
    onSort,
    onPageChange,
    className,
    pageCounts = TABLE_PAGE_COUNTS,
  }: TableProps) => {
    const handlePageChange = useCallback(
      (currentPageNumber: number = 1, currentPageSize: number = DEFAULT_NUMBER_OF_RECORDS_PER_PAGE) => {
        pageScrollTop('scrollTop');
        if (onPageChange) {
          onPageChange(currentPageNumber, currentPageSize);
        }
      },
      [onPageChange],
    );

    const handleSort = (sortBy: string, sortDir: SortDirectionProp) => {
      if (onSort) {
        onSort(sortBy, sortDir);
        pageScrollTop('scrollTop');
      }
    };

    const renderSortIcon = (showUpDownArrows: boolean) => {
      if (showUpDownArrows) {
        return (
          <div className="upDownArrows">
            <Icon name="sort up" color="black" />
            <Icon name="sort down" color="black" />
          </div>
        );
      }
    };

    const renderLabel = (label: string) => {
      if (label?.includes('<br />')) {
        const labels = label.split('<br />');

        return (
          <>
            {labels.map((text, index) => (
              <div key={index + 1}>{text}</div>
            ))}
          </>
        );
      }

      return label;
    };

    const totalPages = Math.ceil((totalRecords || 0) / pageSize);

    const renderTableHeader = () => {
      return (
        <Table.Row key="tableHeaderRow">
          {columns.map((column) => {
            const { name: columnName, label, children, isVisible = true, isSortable = true, addClasses } = column;

            return (
              <Table.HeaderCell
                key={columnName}
                sorted={sortField === columnName ? sortDirection : undefined}
                className={`${!isVisible ? 'hide ' : ''}${!isSortable ? ' noSort' : ''} ${addClasses ? addClasses : ''}`}
              >
                <Item
                  className="cell"
                  onClick={() => isSortable && handleSort(columnName, sortDirection === 'ascending' ? 'descending' : 'ascending')}
                >
                  <div>{renderLabel(label)}</div>
                  {addClasses?.includes('showUpAndDownArrow') && renderSortIcon(true)}
                </Item>
                {children}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      );
    };

    const renderTableBody = () => {
      if (totalRecords === undefined || totalRecords === 0 || data.length === 0) {
        return null;
      }

      return (
        data &&
        data.map((item, index) => {
          return (
            <Table.Row key={item.id || index}>
              {columns.map((column) => {
                const { name, isVisible = true, onColumnDataRender, addClasses } = column;

                return (
                  <Table.Cell key={name} className={`${!isVisible ? 'hide' : ''} ${addClasses ? addClasses : ''}`}>
                    {onColumnDataRender ? (
                      onColumnDataRender(column, item)
                    ) : (
                      <Item className="cell">{getColumnValue(name, item)}</Item>
                    )}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })
      );
    };

    const renderTablePagination = () => {
      if (totalRecords === undefined) {
        return (
          <Item as="div" className="pagination-container">
            <Item className="no-result">Loading...</Item>
          </Item>
        );
      }
      if (totalRecords === 0 || data?.length === 0) {
        return (
          <Item as="div" className="pagination-container">
            <Item className="no-result">No results found</Item>
          </Item>
        );
      }
      if (isPaginationRequired && totalRecords && totalRecords > pageSize) {
        return (
          <Item as="div" className="pagination-container">
            {totalRecords > 0 && (
              <>
                <div>{totalRecords} Results</div>
                <Item as="div" className="col">
                  <Pagination
                    className={`${+currentPage === 1 ? 'firstItem' : ''}
                  ${+currentPage === totalPages ? 'lastItem' : ''}`}
                    prevItem={{ content: 'Prev' }}
                    nextItem={{ content: 'Next' }}
                    activePage={currentPage || 1}
                    onPageChange={(_, { activePage }) => handlePageChange(+(activePage || 1), pageSize)}
                    totalPages={totalPages}
                  />
                </Item>

                {pageCounts ? (
                  pageCounts.length === 1 ? (
                    <StringField addClasses='label-has-one-record' label="Results Per Page:" text={pageCounts[0].text} />
                  ) : (
                    <div className="dropdown-col">
                      <InputSelect
                        label="Results Per Page:"
                        inline
                        icon={'angle right'}
                        compact
                        name="pagesCounts"
                        value={pageSize.toString()}
                        onChange={(_, { value }) => {
                          handlePageChange(1, value ? +value : 1);
                        }}
                        options={pageCounts}
                      />
                    </div>
                  )
                ) : (
                  ''
                )}
              </>
            )}
          </Item>
        );
      }

      return null;
    };

    return (
      <Item as="div" className="pp-table">
        <Table striped sortable className={className}>
          <Table.Header>{renderTableHeader()}</Table.Header>
          <Table.Body>{renderTableBody()}</Table.Body>
        </Table>
        {renderTablePagination()}
      </Item>
    );
  },
);
