import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache, ServerParseError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import { GlobalContextProvider } from './context/GlobalContext';
import './index.scss';
import { AccountManagementContextProvider } from './pages';
import reportWebVitals from './reportWebVitals';
import { GENERAL_CONSTANTS, PAGE_LINKS } from './utilities/constants';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const httpLink = createHttpLink({
  uri: '/service',
  fetchOptions: {
    credentials: 'same-origin',
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const loader = document.getElementById('loader');

  if (loader) {
    loader.classList.remove('loader-spin');
    loader.classList.add('hide');
  }

  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (message === 'Unauthorized' || message === 'Invalid token') {
        sessionStorage.removeItem(GENERAL_CONSTANTS.authToken);
        if (window.location.pathname !== PAGE_LINKS.login) {
          window.location.href = PAGE_LINKS.login;
        }
      }
    });

  if ((networkError as ServerParseError)?.statusCode === 401) {
    sessionStorage.removeItem(GENERAL_CONSTANTS.authToken);
    if (window.location.pathname !== PAGE_LINKS.login) {
      window.location.href = PAGE_LINKS.login;
    }
  } else if (networkError) console.log(`[Network error]: ${networkError}`);
});

const lock = { refreshing: false, apiCallsCounter: 0 };

const authLink = setContext(async (req, { headers }) => {
  lock.apiCallsCounter += 1;

  while (lock.refreshing) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  if (req.operationName !== 'Logout') {
    lock.refreshing = true;
  }

  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem(GENERAL_CONSTANTS.authToken);

  if (lock.apiCallsCounter <= 0) {
    lock.apiCallsCounter = 1;
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    lock.apiCallsCounter -= 1;

    const context = operation.getContext();
    const authHeader = context.response.headers.get('authorization');

    if (authHeader) {
      sessionStorage.setItem(GENERAL_CONSTANTS.authToken, authHeader);
    }

    lock.refreshing = false;

    return response;
  });
});

const client = new ApolloClient({
  link: from([errorLink, authLink, afterwareLink, httpLink]),
  cache: new InMemoryCache(),
});

root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <GlobalContextProvider>
        <AccountManagementContextProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
          <ToastContainer autoClose={1000} />
        </AccountManagementContextProvider>
      </GlobalContextProvider>
    </ApolloProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
