import { Breadcrumb, Item } from 'semantic-ui-react';
import './ProviderSubHeader.scss';

interface ProviderSubHeaderProps {
  ActionButton?: any;
  pageTitle: any;
  pageTitleHint?: any;
  addClasses?: string;
}

const ProviderSubHeader = ({ ActionButton, pageTitle, pageTitleHint, addClasses }: ProviderSubHeaderProps) => {
  return (
    <Item as="div" className={addClasses ? `provider-subheader ${addClasses}` : 'provider-subheader'}>
      <Item as="div" className="titles">
        <Breadcrumb icon="right angle" size="big" sections={pageTitle} />
        {pageTitleHint && <div className="hint" dangerouslySetInnerHTML={{ __html: pageTitleHint }} />}
      </Item>

      {ActionButton && (
        <Item as="div" className="actions">
          {ActionButton}
        </Item>
      )}
    </Item>
  );
};

export default ProviderSubHeader;
