import React, { useState } from 'react';
import { Form, Icon, Input, InputOnChangeData, Item } from 'semantic-ui-react';
import './InputPassword.scss';

interface InputPasswordProps {
  name: string;
  placeholder?: string;
  label?: any;
  AddClass?: string;
  inline?: boolean;
  required?: boolean;
  hint?: any;
  value?: string;
  error?: React.ReactNode;
  size?: any;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
}

export const InputPassword = ({
  name,
  placeholder,
  label,
  AddClass,
  inline = true,
  required,
  hint,
  value,
  error,
  onChange,
  size,
  maxLength = 50,
}: InputPasswordProps) => {
  const [passwordShown, setPasswordShown] = useState(true);
  const tooglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Form.Field className={AddClass} inline={inline} required={required}>
      {label ? <label>{label}</label> : ''}
      <Item className="password-field">
        <Input
          name={name}
          type={passwordShown ? 'password' : 'text'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          size={size}
          maxLength={maxLength}
        />
        <Icon name={passwordShown ? 'eye' : 'eye slash'} onClick={tooglePassword} />
        {hint ? <span className="hint">{hint}</span> : ''}
        {error ? (
          <Item as="div" className="error">
            {error}
          </Item>
        ) : (
          ''
        )}
      </Item>
    </Form.Field>
  );
};
