import moment from 'moment';
import { useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Checkbox, Form, Header, Item, Label } from 'semantic-ui-react';
import { InputButton, InputDateField, InputField, InputSelect } from '..';
import RadioList from '../form/RadioList/RadioList';

import { pageScrollTop } from '../../../utilities';
import { APP_DEFAULT_DATE_FORMAT } from '../../../utilities/constants';

import './Filter.scss';


export type FieldProp = {
  type: 'textbox' | 'checkbox' | 'date' | 'select' | 'radioList' | 'numberString';
  name: string;
  label: string;
  placeholder?: string;
  hint?: React.ReactNode;
  addClasses?: string;
  onChange?: (name: string, value: string) => void;
  upward?: boolean;
  option?: { [k: string]: any }[];
  skipTagging?: boolean;
  splitTag?: boolean;
  openCalender?: boolean;
};

export type ActionProp = {
  name: string;
  label: string;
  addClasses?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: { [k: string]: string }) => void;
};

export type FilterProp = {
  fields: FieldProp[];
  actions: ActionProp[];
};

type FilterForm = {
  [k: string]: string;
};

type Action = {
  type: string;
  payload: string;
};
const filterReducer = (state: FilterForm, action: Action) => {
  if (action.type === 'reset') {
    return {} as FilterForm;
  }

  return { ...state, [action.type]: action.payload };
};

const Filter = (filterProps: FilterProp) => {
  const [searchParams] = useSearchParams();

  const [formState, dispatchFieldChange] = useReducer(filterReducer, {} as FilterForm);

  const isFilterApply = (type: string): boolean => {
    for (const key in formState) {
      if (formState[key]) {
        return false;
      }
    }

    // This if is used to set clear button disabled = false if filter is applied
    if (type.includes('clear') && Array.from(searchParams.entries()).length) {
      return false;
    }

    return true;
  };

  const getDate = (value: string): Date | null => {
    if (!value) {
      return null;
    }
    if (moment(value).format(APP_DEFAULT_DATE_FORMAT).includes('Invalid date')) {
      return null;
    }
    return new Date(value);
  };

  const renderTags = () => {
    const params = Array.from(searchParams.entries());

    if (params.length === 0) {
      return <></>;
    }

    return params.map(([key, value]) => {
      const fieldDetails = filterProps.fields.find((field) => field.name === key);

      if (!fieldDetails) {
        return null;
      }

      if (fieldDetails && fieldDetails.skipTagging) {
        return null;
      }

      if (fieldDetails && fieldDetails.splitTag) {
        return value.split(' ').map(
          (data, index) =>
            data && (
              <Label key={index} size="mini">
                {data}
              </Label>
            ),
        );
      }

      return (
        value && (
          <Label key={value} size="mini">
            {value}
          </Label>
        )
      );
    });
  };

  const getRadioValue = (name: string) => {
    if (!formState[name] && searchParams.get(name)) {
      dispatchFieldChange({
        type: name,
        payload: `${searchParams.get(name)}`,
      });
    }

    return formState[name] || searchParams.get(name) + '';
  };

  const getCheckboxValue = (name: string, value: string) => {
    if (!formState[name] && searchParams.get(name)) {
      dispatchFieldChange({
        type: name,
        payload: `${searchParams.get(name)}`,
      });
    }

    return ((formState && formState[name]) || searchParams.get(name)) === value ? true : false;
  };

  const renderFormFields = () => {
    return filterProps.fields.map(
      ({ name, type, label, placeholder, hint, addClasses, onChange, option: options, upward = false, openCalender = true }) => {
        if (type === 'radioList' && options) {
          return (
            <RadioList
              key={name}
              label={label}
              name={name}
              addClasses={addClasses}
              options={options.map((option) => {
                return {
                  text: option.text,
                  value: option.value,
                };
              })}
              value={getRadioValue(name)}
              onChange={(selectedValue) => {
                dispatchFieldChange({
                  type: name,
                  payload: `${selectedValue}`,
                });
              }}
            />
          );
        } else if (type === 'select' && options) {
          return (
            <InputSelect
              key={name}
              upward={upward}
              name={name || ''}
              options={options}
              search={true}
              inline={false}
              placeholder={placeholder || ''}
              selection={'selection'}
              label={label || ''}
              value={formState[name]}
              onChange={(_, { value }) => {
                dispatchFieldChange({
                  type: name,
                  payload: `${value}`,
                });
              }}
            />
          );
        } else if (type === 'date') {
          return (
            <InputDateField
              key={name}
              name={name}
              label={label}
              placeholder={placeholder}
              AddClass={addClasses}
              value={getDate(formState[name])}
              hint={hint || ''}
              openCalender={openCalender}
              onChange={(value) => {
                const changedValue = value?.toString() || '';
                dispatchFieldChange({
                  type: name,
                  payload: changedValue,
                });
              }}
            />
          );
        } else if (type === 'checkbox') {
          return (
            <Checkbox
              key={name}
              label={label}
              checked={getCheckboxValue(name, options && options[0].value)}
              onChange={(_, event) => {
                const { checked } = event;
                const updatedValue = checked && options && options.length ? options[0].value : 'false';
                dispatchFieldChange({
                  type: name,
                  payload: updatedValue,
                });
                if (onChange) {
                  onChange(name, updatedValue);
                }
              }}
            />
          );
        }
        return (
          <InputField
            key={name}
            inline={false}
            name={name}
            type={type}
            label={label}
            placeholder={placeholder}
            hint={hint}
            AddClass={addClasses}
            value={formState[name] || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const {
                target: { name: nameOnChange, value: changedValue },
              } = event;
              dispatchFieldChange({
                type: nameOnChange,
                payload: changedValue,
              });
              if (onChange) {
                onChange(name, changedValue);
              }
            }}
          />
        );
      },
    );
  };

  const renderActionButtons = () => {
    return filterProps.actions.map(({ name, label, addClasses, onClick }) => {
      return (
        <InputButton
          key={name}
          inline={false}
          text={label}
          addCssClasses={addClasses}
          disabled={isFilterApply(name)}
          onClick={(e) => {
            e.preventDefault();
            onClick(e, formState);
            dispatchFieldChange({ type: 'reset', payload: '' });
            pageScrollTop('sidebar-scrollbar');
          }}
        />
      );
    });
  };

  return (
    <>
      <Item as="div" className="sidebar" id="sidebar-scrollbar">
        <Form size="mini">
          <Header as="h5">FILTER</Header>
          <Item style={{ marginBottom: 10 }}>{renderTags()}</Item>
          {renderFormFields()}
          {renderActionButtons()}
        </Form>
      </Item>
    </>
  );
};

export default Filter;
