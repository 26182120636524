import { gql } from '@apollo/client';

export default gql`
  mutation ToggleUserActiveStatus($userInput: UpdateUserActiveDto!) {
    toggleUserActiveStatus(userInput: $userInput) {
      id
      firstName
      lastName
      fullName
      email
      isActive
      lastLoggedInAt
      isProvider
      updatedAt
      termsAcceptedAt
      useSalutation
      provider {
        id
        name
        group {
          id
          name
        }
      }
      role {
        id
        code
        name
      }
    }
  }
`;
