import { gql } from '@apollo/client';

export default gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $roleId: Int!
    $providerId: Int
    $useSalutation: Boolean
    $brightreeLoginId: String
  ) {
    createUser(
      user: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        roleId: $roleId
        providerId: $providerId
        useSalutation: $useSalutation
        brightreeLoginId: $brightreeLoginId
      }
    ) {
      id
      firstName
      lastName
      email
      isActive
      isProvider
    }
  }
`;
