import { useContext, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalContext';
import { PAGE_LINKS } from '../../../utilities';
import { PrivateLayout } from '../Layouts';

type ProtectedRouteProps = {
  allowedRoles: string[];
};

const RequireAuth = ({ allowedRoles }: ProtectedRouteProps) => {
  const { user: currentLoggedInUser } = useContext(GlobalContext);
  const location = useLocation();
  const userRole = currentLoggedInUser?.role?.code;
  const hasPermissionToAccessPage = useMemo(() => {
    if (!currentLoggedInUser || !userRole) return false;

    const hasPermission = allowedRoles.includes(userRole) || false;

    return hasPermission;
  }, [allowedRoles, currentLoggedInUser, userRole]);

  return hasPermissionToAccessPage ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : currentLoggedInUser ? (
    <Navigate to={PAGE_LINKS.unauthorized} state={{ path: location.pathname }} replace />
  ) : (
    <Navigate to={PAGE_LINKS.login} state={{ path: location.pathname }} replace />
  );
};

export default RequireAuth;
