import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Form, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';
import { InputButton, InputField, InputPassword } from '../../components/controls';
import { ProviderFooter, ProviderHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useAccountLogin, useForm } from '../../hooks';
import { Login as LoginForm } from '../../types';
import { REGEX_PATTERNS } from '../../utilities/constants';
import './Login.scss';

const initializeFormData: LoginForm = {
  email: '',
  password: '',
};

const Login = () => {
  const navigate = useNavigate();
  const [isEmailValid, updateEmail] = useState(false);
  const [isPasswordValid, updatePasswordValid] = useState(false);
  const [submitted, updateSubmitted] = useState(false);

  const { user: currentLoggedInUser, login } = useContext(GlobalContext);
  const { onChange, formState } = useForm<LoginForm>(initializeFormData);

  const { errorMessage, accountLogin, loading } = useAccountLogin();

  // const redirectTo = '/';

  const isFormValid = (): boolean => {
    let isValid = true;
    const { email, password } = formState;
    if (!email || !password || !isEmailValid || !isPasswordValid) {
      isValid = false;
    }
    return isValid;
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    updateSubmitted(true);
    if (!isFormValid()) {
      return;
    }
    try {
      const {
        data: { login: loginData },
      } = await accountLogin(formState);
      login(loginData);
    } catch (error) {}
  };

  return (
    <>
      {!currentLoggedInUser && (
        <Item as="div" className="Login">
          <ProviderHeader />
          <Container fluid>
            <Header as="h1" className="title mb-0 uppercase" content="betternight Provider Portal" />
            <Item as="div" className="sub-title">
              <i>Your Source for Transparent and Accountable Patient Insight</i>
            </Item>
            <Item as="div" style={{ maxWidth: 300, margin: '0 auto' }}>
              <Form size="massive">
                {/* ERROR  */}
                {errorMessage && <Header block className="error" image={errorIcon} color="red" content={errorMessage} />}
                <Header as="h1" className="title" content="Sign In" />
                <InputField
                  name="email"
                  type="email"
                  inline={false}
                  placeholder="WORK EMAIL"
                  value={formState.email}
                  maxLength={100}
                  onChange={({ target: { name, value } }) => {
                    updateEmail(REGEX_PATTERNS.email.test(value));
                    onChange(name, value);
                  }}
                  error={
                    submitted && (
                      <>
                        {formState.email && !isEmailValid && <span className="error-cotent">Enter a valid Email to continue.</span>}
                        {!formState.email && <span className="error-cotent">Enter a valid Email to continue.</span>}
                      </>
                    )
                  }
                />
                <InputPassword
                  name="password"
                  placeholder="PASSWORD"
                  inline={false}
                  value={formState.password}
                  onChange={({ target: { name, value } }) => {
                    updatePasswordValid(!false);
                    onChange(name, value);
                  }}
                  error={
                    submitted && <>{!formState.password && <span className="error-cotent">Enter a Password to continue.</span>}</>
                  }
                />
                <InputButton loading={loading} text="CONTINUE" inline={false} fluid size="massive" onClick={handleFormSubmit} />
                <Link className="link" to="/forgot-password">
                  FORGOT PASSWORD
                </Link>
              </Form>
            </Item>
          </Container>
          <ProviderFooter />
        </Item>
      )}
      {currentLoggedInUser && navigate('/', { replace: true })}
    </>
  );
};

export default Login;
