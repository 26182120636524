import { gql } from '@apollo/client/core';

export default gql`
  query FetchGlossaryPatientStatuses {
    patientStatuses {
      id
      createdBy
      createdAt
      updatedBy
      updatedAt
      stateId
      stateCode
      stateDescription
      pathway
      patientStatus
      patientStatusDefinition
    }
  }
`;
