import { gql } from '@apollo/client/core';

export default gql`
  query FetchProviderContactMissingInfos($searchOptions: SearchProviderContactMissingInfoDto!) {
    providerContactMissingInfos(searchOptions: $searchOptions) {
      totalRecords
      providerContactMissingInfos {
        provider {    
          id     
          brightreeId         
          npi
          name
          originalName
          groupId
          isActive  
          brightreeNavigationLink     
        }
        providerGroup {
          id         
          brightreeId
          originalName
          name
          description
          isActive
        }
        worklistType {
          id        
          category
          code
        }
        sugarContacts {
          id
          createdBy
          createdAt
          updatedBy
          updatedAt          
          preferredCommunicationMethod
          communicationMethodDetails
          sugarRecordId
          sugarAccountName
          sugarName
          sugarDocKey
          sugarNPI
          isActive
          closedAt          
          sugarContactNavigationLink
        }
      }
    }
  }
`;
