import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import { Form, Item } from 'semantic-ui-react';
import { useSearchParams } from 'react-router-dom';
import { APP_DEFAULT_DATE_FORMAT } from '../../../../utilities/constants';

interface InputFieldProps {
  name: string;
  placeholder?: string;
  label?: any;
  AddClass?: string;
  inline?: boolean;
  required?: boolean;
  hint?: any;
  value?: Date | null;
  error?: React.ReactNode;
  disabled?: boolean;
  dateFormat?: string;
  maxLength?: number;
  dateType?: 'basic' | 'range' | undefined;
  openCalender?: boolean;
  onChange: (data?: string) => void;
  maxDate?: Date;
  minDate?: Date;
  selectsRange?: boolean;
}

export const InputDateField = ({
  placeholder,
  label,
  AddClass,
  inline,
  required,
  value,
  hint,
  error,
  minDate = new Date('01/01/1900'),
  maxDate = new Date(),
  dateFormat = 'MM/dd/yyyy',
  openCalender = true,
  onChange,
}: InputFieldProps) => {
  const inputRef = useRef(null);

  const [searchParams] = useSearchParams();

  const clearInputField = useCallback(() => {
    onChange('');
    if (inputRef && inputRef.current) (inputRef.current as HTMLInputElement).value = '';
  }, [onChange]);

  useEffect(() => {
    if (!value) {
      clearInputField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onChangeEvent = (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => {
    const dateValue = (event?.target as HTMLInputElement)?.value;
    if (dateValue === undefined && date) {
      const da = moment(date.toISOString()).format(APP_DEFAULT_DATE_FORMAT);
      onChange(da);
      return;
    }
    if (!dateValue || dateValue?.length < 10) {
      onChange('');
      return;
    }
    onChange(dateValue);
  };

  const getDate = (event: any) => {
    const dateValue = event?.target?.value;
    // // First Check base condition
    // if (!dateValue || dateValue.length < 10) {
    //   return clearInputField();
    // }
    // // Check for valid Date
    // let da = moment(dateValue).format('MM/DD/YYYY');
    // if (da === 'Invalid date') {
    //   return clearInputField();
    // }

    // // now compare two dates
    // const currentDate = new Date().getTime();
    // const minDate = new Date('01/01/1900').getTime();
    // const fieldDate = new Date(da).getTime();
    // // Check for min and max Date
    // if (fieldDate > currentDate || fieldDate < minDate) {
    //   return clearInputField();
    // }
    onChange(dateValue);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      getDate(event);
    }
  };

  const handledChange = debounce(onChangeEvent, 100);
  // const handleBlur = debounce(getDate, 100);
  return (
    <Form.Field className={AddClass} inline={inline} required={required}>
      {label && <label>{label}</label>}
      <Item as="div">
        {!openCalender ? (
          <InputMask
            ref={inputRef}
            id="inputmask"
            maskChar=""
            placeholder={placeholder}
            autoComplete={'false'}
            alwaysShowMask={false}
            onKeyDown={handleKeyDown}
            mask="99/99/9999"
            onBlur={getDate}
          ></InputMask>
        ) : (
          <DatePicker
            autoComplete={'false'}
            showIcon
            toggleCalendarOnIconClick
            showYearDropdown
            shouldCloseOnSelect
            yearDropdownItemNumber={100}
            minDate={minDate}
            customInput={
              <InputMask id="inputmask" maskChar="" autoComplete="false" alwaysShowMask={false} mask="99/99/9999"></InputMask>
            }
            placeholderText={placeholder}
            scrollableYearDropdown
            maxDate={maxDate}
            dateFormat={dateFormat}
            selected={value}
            onChange={handledChange}
          />
        )}

        {hint ? <span className="hint">{hint}</span> : ''}
        {error && (
          <Item as="div" className="error">
            {error}
          </Item>
        )}
      </Item>
    </Form.Field>
  );
};
