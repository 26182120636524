import { useEffect } from 'react';
import { Header, Item, Segment } from 'semantic-ui-react';
import { ProviderSubHeader } from '../../components/shared';
import './TermOfUse.scss';

const pageHeaderTitle = [{ key: 'termsOfUse', content: 'TERMS OF USE', active: true }];

export const TermOfUse = () => {
  useEffect(() => {
    const pageURL = window.location.href;
    if (/betterNight-policy/.test(pageURL)) {
      const element = document.getElementById('betterNight-policy');
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return (
    <>
      <ProviderSubHeader pageTitle={pageHeaderTitle} addClasses="termsPageHeader" />
      <main className="termsPolicy">
        <Segment basic className="terms">
          <Header as="h2" id="termCondition">
            BETTERNIGHT TERMS OF USE
          </Header>
          <Item as="p">Last revised July 26, 2022</Item>
          <Item as="p">
            These terms and conditions of use (“Terms of Use”) govern your use of our online interfaces and properties (e.g., websites
            and mobile applications) owned and controlled by Sleep Data Services, LLC dba BetterNight Services, including the
            www.betternight.com website (the “BetterNight site”), as well as the services (“Services”) and products (“Products”)
            available to users through the BetterNight site. BetterNight Services (“BetterNight”, “we,” “us,” and “our”) contracts with
            BetterNight Medical Group, P.A., BetterNight Medical Group, Better Night LLC, BetterNight Medical Group of KS P.A.,
            BetterNight Medical Group of NJ P.A., Sleep Data, and Sleep Data Diagnostics (collectively referred to as “Better Night
            Provider Group”) regarding online telehealth medical consultations and secure messaging between Better Night Provider Group
            physicians and other healthcare professionals (individually the “Provider” and collectively the “Providers”) and their
            patients. (See Supplemental Terms Applicable to Providers.) The professional medical services (which are provided by Better
            Night Provider Group) and the non-clinical BetterNight site services (which are provided by BetterNight) are collectively
            referred to in this Terms of Use as the “Services”. The terms “you”, “your”, and "user" means you, your dependent(s) if
            any, and any other person accessing your BetterNight Account.
          </Item>
          <Item as="p">
            <b>
              Your acceptance of, and compliance with, these Terms of Use is a condition to your use of the BetterNight site and
              Services and purchase of Products. By clicking “accept”, you acknowledge that you have read, understand, and accept all
              terms and conditions contained within the Terms of Use, Notice of Privacy Practices and Privacy Policy. If you do not
              agree to be bound by these terms, you are not authorized to access or use this BetterNight site or Services; promptly
              exit this BetterNight site.
            </b>
          </Item>
          <Item as="p">
            <b>Binding Arbitration.</b> These Terms of Use provide that all disputes between you and BetterNight that in any way relate
            to these Terms of Use or your use of the BetterNight site will be resolved by BINDING ARBITRATION. ACCORDINGLY, YOU AGREE
            TO GIVE UP YOUR RIGHT TO GO TO COURT (INCLUDING IN A CLASS ACTION PROCEEDING) to assert or defend your rights under these
            Terms of Use. Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury and your claims cannot be
            brought as a class action. Please review the Section below entitled{' '}
            <a className="link" href="#dispute-arbitration">
              {' '}
              Dispute Resolution; Arbitration Agreement
            </a>{' '}
            for the details regarding your agreement to arbitrate any disputes with BetterNight.
          </Item>
          <Item as="p">
            <b>1. Privacy Practices</b>
          </Item>
          <Item as="p">
            You agree that information provided by you in connection with the Services and BetterNight site shall be governed by the{' '}
            <a className="link" href="#betterNight-policy">
              BetterNight Privacy Policy
            </a>
            , which is hereby incorporated and made a part of this Agreement. You agree that information provided by you in connection
            with the Services shall also be governed by the Better Night Provider Group{' '}
            <a className="link" href="#noticePrivacy">
              Notice of Privacy Practices
            </a>{' '}
            and is hereby incorporated and made a part of this Agreement.
          </Item>
          <Item as="p">
            <b>
              <i>
                Please Note the Following Information Concerning BetterNight's Research Use of User Information.
                <br /> This information is also included in the BetterNight Privacy Policy. Additional uses of User Information are
                also set forth in the BetterNight Privacy Policy.{' '}
              </i>
            </b>
          </Item>
          <Item as="p">
            <b>Research Use:</b> BetterNight collects User Information (as hereinafter defined) in order to provide and improve
            Services and Products that you purchase using the BetterNight site or we provide to others. BetterNight may use aspects of
            User Information in research so as to improve its Service, Products and Information. Prior to such research use,
            BetterNight will de-identify User Information. “De-identification” is a process of detecting identifiers (e.g., personal
            names and social security numbers) that directly or indirectly point to a person (or entity) and deleting those identifiers
            from the data. Health information containing personal identifiers that are linked to You (and part of Userfyo Information)
            is defined as protected health information (“Protected Health Information” or “PHI”) and is protected under the federal and
            state law. Only User Information that is de-identified (“Your De-Identified Information”) shall be used in BetterNight’s
            research as described in this paragraph. You acknowledge and agree that BetterNight shall have a royalty-free, irrevocable,
            worldwide, non-exclusive right and license to use Your De-identified Information to copy, modify, adapt, translate, create
            derivative works, improve the quality of our service and products, develop or create new services or products and/or
            incorporate Your De-Identified Information into any form, medium or technology now known or later developed throughout the
            world without compensation.
          </Item>
          <Item as="p">
            <b>2. Services Provided - No Medical Care or Advice by BetterNight</b>
          </Item>
          <Item as="p">
            We offer an online communication platform for Providers and their patients to connect via the BetterNight site through the
            use of synchronous and asynchronous telecommunications technologies. The BetterNight site facilitates communication between
            patients and Providers.
          </Item>
          <Item as="p">
            The BetterNight site does not provide medical advice or care. BetterNight contracts with Better Night Provider Group, an
            independent, physician-owned medical group with a network of United States based Providers who provide clinical telehealth
            services. Better Night Provider Group Providers deliver clinical services via the BetterNight platform to their patients.
            Providers are independently contracted or employed by Better Night Provider Group. Providers are not contracted or employed
            by BetterNight. The Providers, and not BetterNight, are responsible for the quality and appropriateness of the care they
            render to you.
          </Item>
          <Item as="p">
            The Providers are independent of BetterNight and are merely using the BetterNight site as a way to communicate with you.
            Any information or advice received from a Provider comes from them alone, and not from BetterNight. Your interactions with
            the Providers via the BetterNight site are not intended to take the place of your relationship with your regular health
            care practitioners or primary care physician. Neither BetterNight, nor any of its subsidiaries or affiliates or any third
            party who may promote the BetterNight site or Service or provide a link to the Service, shall be liable for any
            professional advice obtained from a Provider via the BetterNight site or Service, nor any information obtained on the
            BetterNight site. BetterNight does not recommend or endorse any specific Providers, tests, physicians, medications,
            products, or procedures. You acknowledge that your reliance on any Providers or information delivered by the Providers via
            the BetterNight site or Service is solely at your own risk and you assume full responsibility for all risks associated
            herewith.
          </Item>
          <Item as="p">
            BetterNight does not make any representations or warranties about the training or skill of any Providers who deliver
            services via the BetterNight site or Service. You will be provided with available Providers based solely on the information
            you submit to the BetterNight site. You are ultimately responsible for choosing your particular Provider.
          </Item>
          <Item as="p">
            The content of the BetterNight site and the Services, including without limitation, text, copy, audio, video, photographs,
            illustrations, graphics and other visuals, is for informational purposes only and does not constitute professional medical
            advice, diagnosis, treatment, or recommendations of any kind by BetterNight. You should always seek the advice of your
            qualified health care professionals with any questions or concerns you may have regarding your individual needs and any
            medical conditions. All information provided by BetterNight, or in connection with any communications supported by
            BetterNight is intended to be for general information purposes only, and is in no way intended to create a provider-patient
            relationship as defined by state or federal law. While BetterNight facilitates your selection of, and communications with,
            Providers, the BetterNight site does not provide medical services, and the doctor-patient relationship is between you and
            the Better Night Provider Group Provider you select.
          </Item>
          <Item as="p">
            <b>3. IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL “911” IMMEDIATELY.</b>
          </Item>
          <Item as="p">
            BetterNight’s Site and Services are not for medical emergencies or urgent situations. You should not disregard or delay
            seeking medical advice based on anything that appears or does not appear on the BetterNight site. If you believe you have
            an emergency, call 9-1-1 immediately.
          </Item>
          <Item as="p">
            You should seek emergency help or follow up care when recommended by a Provider or when otherwise needed. You should
            continue to consult with your primary provider and other healthcare professionals as recommended. Always seek the advice of
            a physician or other qualified healthcare provider concerning questions you have regarding a medical condition and before
            stopping, starting, or modifying any treatment or modification.
          </Item>
          <Item as="p">
            <b>4. Risks of Telehealth Services</b>
          </Item>
          <Item as="p">
            By using the Services, you acknowledge the potential risks associated with telehealth services. These include but are not
            limited to the following: information transmitted may not be sufficient (e.g. poor resolution of images) to allow for
            appropriate medical or health care decision making by the Provider; delays in evaluation or treatment could occur due to
            failures of electronic equipment; a lack of access to your medical records may result in adverse drug interactions or
            allergic reactions or other judgment errors; although the electronic systems we use incorporate network and software
            security protocols to protect the privacy and security of health information, those protocols could fail causing a breach
            of privacy of your health information.
          </Item>
          <Item as="p">
            <b>5. Physician Telemedicine Cancellation Policy</b>
          </Item>
          <Item as="p">
            You should receive a reminder about your telemedicine appointment. You must cancel or reschedule your appointment more than
            twenty-four (24) hours prior to the appointment start time. If you fail to show up for a scheduled consult or to cancel in
            a timely manner, you will be charged $25.00.
          </Item>
          <Item as="p">
            <b>6. Prescription Policy</b>
          </Item>
          <Item as="p">
            Neither BetterNight nor Better Night Provider Group endorse any specific medication, pharmacy, pharmacologic product,
            medical device, or device supplier. If a Provider prescribes a medication, he/she will limit supply based upon state
            regulations and will only prescribe a medication as determined in his/her own discretion and professional judgment. There
            is no guarantee a prescription will be written. Providers do not prescribe DEA controlled substances or scheduled
            medications, or certain other drugs which may be harmful because of their potential for abuse. Providers reserve the right
            to deny care for actual or potential misuse of the Services.
          </Item>
          <Item as="p">
            You agree that any prescriptions that you acquire from a Provider will be solely for your personal use. You agree to fully
            and carefully read all provided product information and labels and to contact a physician or pharmacist if you have any
            questions regarding the prescription. BetterNight and Better Night Provider Group fully honor patient freedom of choice
            and, if you receive a prescription for a medication, you always have the option to instruct Better Night Provider Group to
            transmit that prescription to the pharmacy of your choice.
          </Item>
          <Item as="p">
            <b>7. Not an Insurance Product</b>
          </Item>
          <Item as="p">
            Neither BetterNight nor Better Night Provider Group are insurers. The Services are not insurance products, and the amounts
            you pay to BetterNight or Better Night Provider Group are not insurance premiums. If you desire any type of health or other
            insurance, you will need to purchase such insurance separately.
          </Item>
          <Item as="p">
            <b>8. Acceptance of Services</b>
          </Item>
          <Item as="p">
            I understand that by signing the agreement, I authorize provision of products and/or services to me by BetterNight Provider
            Group. I also understand that the products and services provided are prescribed by my physician and that it is necessary
            that I remain under the supervision of my attending physician during the course of my care.
          </Item>
          <Item as="p">
            <b>9. Release of Information</b>
          </Item>
          <Item as="p">
            I hereby authorize release to BetterNight Provider Group any and all of my medical records pertaining to my medical
            history, services rendered, or treatments received from my physician(s) or hospital. In order to process insurance claims,
            I also hereby authorize BetterNight Provider Group to furnish to my insurance carrier(s) any medical history, services
            rendered, or treatment needed.
          </Item>
          <Item as="p">
            <b>10. Assignment of Benefits</b>
          </Item>
          <Item as="p">
            I authorize direct payment of insurance benefits by my insurance company to BetterNight Provider Group for all medical
            supplies and services provided by BetterNight Provider Group. In the event that my insurance carrier does not accept
            “assignment of benefits”, I understand that payments may be sent directly to me, and that I am obligated to endorse and
            directly send such payments to BetterNight Provider Group for payment of my bill. I authorize any holder of medical
            information about me to be released to BetterNight Provider Group, my physician, or my insurance company in order to
            determine or secure eligibility information and/or reimbursement for covered services.
          </Item>
          <Item as="p">
            <b>11. Financial Responsibility</b>
          </Item>
          <Item as="p">
            I understand that I am responsible to BetterNight Provider Group for all charges not covered by my insurance. I recognize
            that in the event that my insurance company, employer, or any other third party payer refuses to pay the rental and/or
            purchase price(s) of the above items, or delays payment beyond 90 days of my receipt of items, or in the event that I have
            no insurance coverage or third party payer, that I will be responsible for said payments and will make prompt reimbursement
            within 30 days of notification by BetterNight Provider Group for all charges. I understand that my equipment will be
            converted to purchase per my insurance provider’s timeline. When the equipment is converted to purchase, the ownership of
            the equipment will be transferred to me, the beneficiary. Upon ownership, it is then my, the beneficiary’s responsibility
            to arrange for any required equipment service or repair.
          </Item>
          <Item as="p">
            When paying through insurance, the price quotes are estimates only. It is based on the information your insurance provider
            has given us. Estimated cost does not account for future accumulations on your insurance, such as deductible or out of
            pocket maximum. Any changes to insurance must be reported to BetterNight for coverage update.
          </Item>
          <Item as="p">
            <b>12. Medicare Capped Rental Notification</b>
          </Item>
          <Item as="p">
            This applies to Medicare patients who receive a PAP device. Medicare defines PAP devices as being either a capped rental or
            an inexpensive or routinely purchased item.
          </Item>
          <Item as="p">
            <u>&#10003;</u> FOR CAPPED RENTAL ITEMS:
          </Item>
          <Item as="ul">
            <li>
              Medicare will pay a monthly rental fee for a period not to exceed 13 months, after which ownership of the equipment is
              transferred to the Medicare beneficiary.
            </li>
            <li>
              After ownership of the equipment is transferred to the Medicare beneficiary, it is the beneficiary’s responsibility to
              arrange for any required equipment service or repair.
            </li>
            <li>
              Examples of this type of equipment include: Hospital beds, wheelchairs, alternating pressure pads, air-fluidized beds,
              nebulizers, suction pumps, continuous airway pressure (CPAP) devices, patient lifts, and trapeze bars.
            </li>
          </Item>
          <Item as="p">
            <u>&nbsp;&nbsp;&nbsp;</u> FOR INEXPENSIVE OR ROUTINELY PURCHASED ITEMS:
          </Item>
          <Item as="ul">
            <li>
              Equipment in this category can be purchased or rented; however, the total amount paid for monthly rentals cannot exceed
              the fee schedule purchase amount.
            </li>
            <li>
              Examples of this type of equipment include: Canes, walkers, crutches, commode chairs, low pressure and positioning
              equalization pads, home blood glucose monitors, seat lift mechanisms, pneumatic compressors (lymphedema pumps), bed side
              rails, and traction equipment.
            </li>
          </Item>
          <Item as="p">
            <b>13. Agreement For Self-payment of Insomnia Services</b>
          </Item>
          <Item as="p">
            We do not participate in any insurance plans, including Medicare or Medicaid, and we do not accept any health insurance
            whatsoever for our <b>insomnia</b> product. Our services are 100% self-pay by our patients.
          </Item>
          <Item as="p">
            By agreeing to this, you are electing to purchase services that may or may not be covered by your insurance if you obtained
            those services from a different provider. You have selected services for purchase from us on a self-pay basis. In other
            words, you have directed us to treat your purchase of these services as if you are an uninsured patient and you agree to be
            100% responsible for full payment of the listed price of the services. There is no guarantee your insurance company will
            make any payment on the cost of the services you have purchased.
          </Item>
          <Item as="p">
            BetterNight has provided you with the charges, in advance, for the services you have requested. By agreeing to this, you
            agree to pay these charges in full as a self-pay patient, electing not to use an insurance policy benefit. You have been
            given a choice of different services, along with their costs. You have selected the services and are willing to accept full
            financial responsibility for payment.
          </Item>
          <Item as="p">
            <b>14. Availability of Services</b>
          </Item>
          <Item as="p">
            BetterNight and Better Night Provider Group operate subject to state and federal regulations, and the Services may not be
            available in your state. You represent that you are not a person barred from enrolling for and/or receiving the Services
            under the laws of the United States or other applicable jurisdictions in which you may be located. Access to and use of the
            BetterNight site and/or the Services is limited exclusively to users located in States within the United States where the
            Services are available. Services are not available to users located outside the United States. Accessing the BetterNight
            site or Services from jurisdictions where content is illegal, or where we do not offer Services, is prohibited.
          </Item>
          <Item as="p">
            <b>15. Ordering and Purchasing or Products</b>
          </Item>
          <Item as="p">
            <b>Order Acceptance and Shipment</b>
          </Item>
          <Item as="p">
            Your placement of an order does not necessarily ensure that we will accept your order. We may require additional
            information regarding your order if you have not provided all the information required by us to completed. Once a properly
            completed order and authorization of your form of payment is received, we will process your order and Product for shipment.
            If, for some reason, we determined that we cannot ship your Product(s) within thirty (30) days following our receipt of a
            properly completed order, we will cancel your order and advise you of such action. We do not accept orders from dealers,
            wholesalers or other customers who intend to resell items offered on our BetterNight site.
          </Item>
          <Item as="p">
            <b>Typographical Errors and Incorrect Pricing</b>
          </Item>
          <Item as="p">
            In the event a Product or Service is listed at an incorrect price due to typographical error or error in pricing
            information received from a third party, we shall have the right to refuse or cancel any orders placed for the Product(s)
            and/or Service(s) listed at the incorrect price. We shall have the right to refuse or cancel any such orders whether or not
            the order has been confirmed and your credit or debit card charged. If your credit or debit card has already been charged
            for the purchase and your order is canceled, we will promptly issue a credit to your credit or debit card account in the
            amount of the charge.
          </Item>
          <Item as="p">
            <b>Risk of Loss</b>
          </Item>
          <Item as="p">
            All Products purchased from our BetterNight site are delivered to shipment carriers. The risk of loss and title for such
            Products pass to you when they are delivered to the carrier.
          </Item>
          <Item as="p">
            <b>Diagnostic Equipment Return Policy</b>
          </Item>
          <Item as="p">
            In order to provide the very best patient care and customer service, we need to ensure that diagnostic equipment is
            returned on time and in good condition. Late fees will be assessed if the equipment is not returned within the timeframes
            specified below. Patients may be held responsible for excessive damage to the recorder or sensors. Late fees will be
            charged at the following rates:
          </Item>
          <Item as="p">
            1-6 days after due date: $25.00 / day
            <br />
            7-13 days after due date: $75.00 / day
            <br />
            14+ days after due date: $3,500.00
          </Item>
          <Item as="p">
            <b>Return Policy</b>
          </Item>
          <Item as="p">
            You may return durable medical equipment products in accordance with our Returns Policy. Most unopened items sold by
            BetterNight Provider Group in new condition and returned within 90 days will receive a refund or exchange. Some items sold
            by BetterNight Provider Group have a modified return policy noted on the receipt or packing slip. Items that are opened or
            damaged or do not have a receipt may be denied a refund or exchange.
          </Item>
          <Item as="p">
            <b>Warranty Policy</b>
          </Item>
          <Item as="p">
            Every PAP device sold carries a 2-year manufacturer’s warranty. BetterNight will honor all warranties under applicable law.
            In addition, an owner’s manual with warranty information will be provided for all durable medical equipment where this
            manual is available.
          </Item>
          <Item as="p">
            <b>16. Eligibility; BetterNight site Access, Security and Restrictions; Passwords</b>
          </Item>
          <Item as="p">
            In order to access the BetterNight site and the Services, you represent and warrant that you are older than 18 years old.
            You agree to fully, accurately, and truthfully create your BetterNight Account (“Account”), including but not limited to
            your name, mailing address, phone number, email address, and password, which become your BetterNight ID and credentials.
            The BetterNight ID and/or credentials are personal to you, and you are solely responsible for maintaining the
            confidentiality of your BetterNight ID and/or credentials, and for all activities that occur under such BetterNight ID
            and/or credentials. You agree to prohibit anyone else from using your BetterNight ID and/or credentials and agree to
            immediately notify BetterNight of any actual or suspected unauthorized use of your BetterNight ID and/or credentials or
            other security concerns of which you become aware. Your access to the BetterNight site may be revoked by BetterNight at any
            time with or without cause.
          </Item>
          <Item as="p">
            You are prohibited from violating or attempting to violate the security of the BetterNight site, including, without
            limitation, (a) accessing data not intended for such user or logging onto a server or an account which the user is not
            authorized to access; or (b) attempting to probe, scan or test the vulnerability of a system or network or to breach
            security or authentication measures without proper authorization; or (c) accessing or using the BetterNight site or any
            portion thereof without authorization, in violation of these Terms of Use or in violation of applicable law.
          </Item>
          <Item as="p">
            You may not use any scraper, crawler, spider, robot or other automated means of any kind to access or copy data on the
            BetterNight site, deep-link to any feature or content on the BetterNight site, bypass our robot exclusion headers or other
            measures we may use to prevent or restrict access to the BetterNight site. Violations of system or network security may
            result in civil or criminal liability. BetterNight will investigate occurrences that may involve such violations and may
            involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You
            agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this
            BetterNight site or any activity being conducted on this BetterNight site.
          </Item>
          <Item as="p">
            <b>17. Electronic Communications</b>
          </Item>
          <Item as="p">
            When you use the BetterNight site or Services, or send e-mails, messages, and other communications from your desktop or
            mobile device to us, you are communicating with us electronically. You consent to receive communications from us
            electronically. You agree that (a) all agreements and consents can be signed electronically and (b) all notices,
            disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such notices
            and other communications be in writing. BetterNight and Better Night Provider Group may contact you by telephone, mail, or
            email to verify your account information. BetterNight and Better Night Provider Group may request further information from
            you and you agree to provide such further information to ensure that you have not fraudulently created your Account. If you
            do not provide this information in the manner requested within 14 days of the request, we reserve the right to suspend,
            discontinue, or deny your access to and use of the BetterNight site and the Services until you provide the information to
            us as requested.
          </Item>
          <Item as="p">
            <b>18. Consent to Receive Calls and Text Messages</b>
          </Item>
          <Item as="p">
            By providing your mobile number, you are agreeing to be contacted by or on behalf of BetterNight and Better Night Provider
            Group at the mobile number you have provided, including calls and text messages, to receive informational, Product or
            Service related (e.g., progress tracking, refill reminders, checkup reminders, etc.) and marketing communications relating
            to the BetterNight site and Services. Message and data rates may apply.{' '}
            <b>To stop receiving text messages text the word STOP.</b> We may confirm your opt out by text message. If you subscribe to
            multiple types of text messages from us, we may unsubscribe you from the service that most recently sent you a message
            and/or respond to your STOP message by texting you a request to identify services you wish to stop. Keep in mind that if
            you stop receiving text messages from us you may not receive important and helpful information and reminders about your
            progress and treatment.
          </Item>
          <Item as="p">
            <b>19. Ownership Of The BetterNight site And Related Materials; Additional Restrictions</b>
          </Item>
          <Item as="p">
            All pages within this BetterNight site and any material made available for download are the property of BetterNight, or its
            licensors or suppliers, as applicable. The BetterNight site is protected by United States and international copyright and
            trademark laws.
          </Item>
          <Item as="p">
            All rights not expressly granted to you in these Terms of Use are reserved and retained by BetterNight or its licensors,
            suppliers, publishers, rights holders, or other content providers. Neither the BetterNight site and Services, nor any part
            of the BetterNight site and Services, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited
            for any commercial purpose without express written consent of BetterNight. You may not frame or utilize framing techniques
            to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of
            BetterNight without express written consent. You may not use any meta tags or any other “hidden text” utilizing
            BetterNight’s name or trademarks without the express written consent of BetterNight. You may not misuse the BetterNight
            site or Services. You may use the BetterNight site and Services only as permitted by law. The content of the BetterNight
            site, including without limitation the files, documents, text, photographs, images, audio, and video, and any materials
            accessed through or made available for use or download through this BetterNight site may not be copied, distributed,
            modified, reproduced, published or used, in whole or in part, except for purposes authorized or approved in writing by
            BetterNight. You may not frame or utilize framing techniques to enclose, or deep linking to, any name, trademarks, service
            marks, logo, content or other proprietary information (including; images, text, page layout, or form) of BetterNight
            without our express written consent.
          </Item>
          <Item as="p">
            <b>20. No Users Under 18 Years Old</b>
          </Item>
          <Item as="p">
            The BetterNight site and Services are only for users of the age of 18. If you are under the age of 18, do not attempt to
            register with us at this BetterNight site or provide any personal information about yourself to us. If we learn that we
            have collected personal information from someone under the age of 18, we will promptly delete that information. If you
            believe we have collected personal information from someone under the age of 18, please call us at 1-866-801-9440.
          </Item>
          <Item as="p">
            <b>21. Accuracy of Information; Functionality</b>
          </Item>
          <Item as="p">
            Although BetterNight attempts to ensure the integrity and accurateness of the BetterNight site and Product descriptions, it
            makes no representations, warranties or guarantees whatsoever as to the correctness or accuracy of the BetterNight site,
            Product descriptions and other content on the BetterNight site. It is possible that the BetterNight site could include
            typographical errors, inaccuracies or other errors, and that unauthorized additions, deletions and alterations could be
            made to the BetterNight site by third parties. In the event that an inaccuracy arises, please inform BetterNight so that it
            can be corrected. If a Product described on our BetterNight site is not as described when you receive it, or the packaging
            on the BetterNight site does not match the product to receive, your sole remedy is to return it to us in unused and
            undamaged condition. Information contained on the BetterNight site may be changed or updated without notice. Additionally,
            BetterNight shall have no responsibility or liability for information or content posted to the BetterNight site from any
            non-BetterNight affiliated third party.
          </Item>
          <Item as="p">
            BetterNight and Better Night Provider Group each reserves complete and sole discretion with respect to the operation of the
            BetterNight site and the Services. We may withdraw, suspend, or discontinue any functionality or feature of the BetterNight
            site or the Services among other things. We are not responsible for transmission errors, corruption, or compromise of
            information carried over local or interchange telecommunications carrier. We are not responsible for maintaining
            information arising from use of the BetterNight site or with respect to the Services. We reserve the right to maintain,
            delete, or destroy all communications or information posted or uploaded to the BetterNight site or the Services in
            accordance with our internal record retention and/or destruction policies.
          </Item>
          <Item as="p">
            <b>22. Links to Other Sites</b>
          </Item>
          <Item as="p">
            BetterNight makes no representations whatsoever about any other website that you may access through this BetterNight site.
            When you access a non-BetterNight site, please understand that it is independent from BetterNight, and that BetterNight has
            no control over the content on that website. In addition, a link to a non-BetterNight website does not mean that
            BetterNight endorses or accepts any responsibility for the content, or the use, of the linked site. It is up to you to take
            precautions to ensure that whatever you select for your use or download is free of such items as viruses, worms, Trojan
            horses, and other items of a destructive nature. If you decide to access any of the third party sites linked to this
            BetterNight site, you do this entirely at your own risk.
          </Item>
          <Item as="p">
            <b>23. User Information</b>
          </Item>
          <Item as="p">
            If you submit, upload, post or transmit any health information, medical history, conditions, problems, symptoms, personal
            information, consent forms, agreements, requests, comments, ideas, suggestions, information, files, videos, images or other
            materials to us or our BetterNight site (“User Information”), you agree not to provide any User Information that (1) is
            false, inaccurate, defamatory, abusive, libelous, unlawful, obscene, threatening, harassing, fraudulent, pornographic, or
            harmful, or that could encourage criminal or unethical behavior, (2) violates or infringes the privacy, copyright,
            trademark, trade dress, trade secrets or intellectual property rights of any person or entity, or (3) contains or transmits
            a virus or any other harmful component. You agree not to contact other BetterNight site users through unsolicited e-mail,
            telephone calls, mailings or any other method of communication. You represent and warrant to BetterNight and Better Night
            Provider Group that you have the legal right and authorization to provide all User Information to BetterNight and Better
            Night Provider Group for use as set forth herein and required by BetterNight and the Better Night Provider Group Provider.
          </Item>
          <Item as="p">
            You agree not to (i) access the BetterNight site or use the Services in any unlawful way or for any unlawful purpose; (ii)
            post or transmit (a) a message under a false name, or (b) any data, materials, content, or information (including, without
            limitation, advice, and recommendations) (collectively “Information”) which is (1) libelous, defamatory, obscene,
            fraudulent, false, or contrary to the ownership or intellectual property rights of any other person, or (2) contains or
            promotes any virus, worm, Trojan horse, time bomb, malware, or other computer programing or code that is designed or
            intended to damage, destroy, intercept, download, interfere, manipulate, or otherwise interrupt or expropriate the
            BetterNight site or the Services, personal information, software, equipment, servers, or Information or facilitate or
            promote hacking or similar conduct; (iii) impersonate or misrepresent your identity or falsely state or misrepresent your
            affiliation with a person or entity; (iv) tamper, hack, spoof, copy, modify, or otherwise corrupt the administration,
            security, or proper function of the BetterNight site or the Services; (v) use robots or scripts with the BetterNight site;
            (vi) attempt to reverse engine, reverse assemble, reverse compile, decompile, disassemble, translate, or otherwise alter,
            defraud, or create false results from any executable code, information on, or received by this BetterNight site; (vii) to
            have any antivirus or antispyware software running that is set to override the internet browser’s cookies setting; (viii)
            incorrectly identify the sender of any message transmitted to BetterNight. You may not alter the attribution or origin of
            electronic mail, messages, or posting; (ix) harvest or collect Protected Health Information about any other individual who
            uses the BetterNight site or the Services; (x) infringe or facilitate infringement on any copyright, patent, trademark,
            trade secret, or other proprietary, publicity, or privacy rights of any party, including such rights of third parties.
          </Item>
          <Item as="p">
            You agree to defend, indemnify and hold harmless BetterNight, Better Night Provider Group, and the Providers from and
            against all third party claims, damages and expenses (including reasonable attorneys’ fees) against or incurred by us
            arising out of any User Information you upload to or transmit through the BetterNight site.
          </Item>
          <Item as="p">
            <b>24. Claims of Copyright Infringement</b>
          </Item>
          <Item as="p">
            We disclaim any responsibility or liability for copyrighted materials posted on our BetterNight site. If you believe that
            your work has been copied in a manner that constitutes copyright infringement, please follow the procedures set forth
            below.
          </Item>
          <Item as="p">
            BetterNight respects the intellectual property rights of others and expects its users to do the same. In accordance with
            the Digital Millennium Copyright Act (“DMCA”), we will respond promptly to notices of alleged infringement that are
            reported to BetterNight’s Designated Copyright Agent, identified below.
          </Item>
          <Item as="p">
            <b>Notices of Alleged Infringement for Content Made Available on the BetterNight site</b>
          </Item>
          <Item as="p">
            If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under
            copyright, please report alleged copyright infringements taking place on or through our BetterNight site by sending us a
            notice (“Notice”) complying with the following requirements.
          </Item>
          <Item as="ol">
            <li>
              <Item as="p">Identify the copyrighted works that you claim have been infringed.</Item>
            </li>
            <li>
              <Item as="p">
                Identify the material or link you claim is infringing (or the subject of infringing activity) and that access to which
                is to be disabled, including at a minimum, if applicable, the URL of the link shown on the BetterNight site where such
                material may be found.
              </Item>
            </li>
            <li>
              <Item as="p">Provide your mailing address, telephone number, and, if available, email address.</Item>
            </li>
            <li>
              <Item as="p">Include both of the following statements in the body of the Notice:</Item>
              <Item as="p">
                “I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by
                the copyright owner, its agent, or the law (e.g., as a fair use).”
              </Item>
              <Item as="p">
                “I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or
                authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is
                allegedly infringed.”
              </Item>
            </li>
            <li>
              <Item as="p">Provide your full legal name and your electronic or physical signature.</Item>
              <Item as="p">Deliver this Notice, with all items completed, to our Copyright Agent:</Item>
              <Item as="p">
                {' '}
                Better Night Services
                <br />
                Attn: VP of Marketing
                <br />
                5471 Kearny Villa Road, Suite 200
                <br />
                San Diego, CA 92123
                <br />
                info@betternight.com
              </Item>
            </li>
          </Item>
          <Item as="p">
            <b>25. Intellectual Property</b>
          </Item>
          <Item as="p">
            With the exception of your electronic medical record, BetterNight and Better Night Provider Group, as applicable, retain
            all right, title, and interest in and to the BetterNight site, the Services and any information, products, documentation,
            software, or other materials on the BetterNight site, and any patent, copyright, trade secret, idea or discovery, whether
            patentable or not, trademark, service mark, or other intellectual property, or proprietary right in any of the foregoing,
            except for information on the BetterNight site licensed by BetterNight or Better Night Provider Group (in that case, the
            license provider retains all right, title, and interest therein). The foregoing shall be referred to in this Agreement
            collectively as the “IP.” For clarity, the IP set forth above is not an exclusive account of IP owned by BetterNight or its
            license providers. You expressly agree not to commercialize any such IP, whether obtained directly or indirectly through
            the use of the BetterNight site, receipt of Services, or in any other manner (by You or a third party), without the prior
            written agreement of BetterNight or its license providers, each with respect to their IP. You agree not to store, copy,
            modify, reproduce, retransmit, distribute, disseminate, rent, lease, loan, sell, publish, broadcast, display, or circulate
            such IP to anyone. Use, reproduction, copying, or redistribution of BetterNight or Better Night Provider Group trademarks,
            service marks, and logos are strictly prohibited without the prior written permission of BetterNight or Better Night
            Provider Group, as applicable. The immediately foregoing sentence also applies to any third party trademarks, service
            marks, and logos posted on the BetterNight site. Nothing contained on the BetterNight site should be construed as granting,
            by implication, estoppel, waiver or otherwise, any license or right to use any trademarks, service marks or logos displayed
            on the BetterNight site without the written grant thereof by BetterNight, Better Night Provider Group or the third party
            owner of such trademarks, service marks, and/or logos. The BetterNight site may contain other proprietary notices and
            copyright information, the terms of which you agree to follow.
          </Item>
          <Item as="p">
            BetterNight may delete any information provided by you that it deems in its sole discretion fraudulent, abusive,
            defamatory, obscene, or in violation of copyright, trademark, or other intellectual property or ownership right of any
            other person or entity.
          </Item>
          <Item as="p">
            <b>26. Disclaimer of Warranties</b>
          </Item>
          <Item as="p">
            BetterNight does not warrant that access to or use of the BetterNight site will be uninterrupted or error-free or that
            defects in the BetterNight site will be corrected. This BetterNight site, including any content or information contained
            within it or any site-related service, is provided “as is,” with all faults, with no representations or warranties of any
            kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability, fitness for a
            particular purpose, quality of information, quiet enjoyment, and title/non-infringement. BetterNight does not warrant the
            accuracy, completeness or timeliness of the information obtained through the BetterNight site.
          </Item>
          <Item as="p">
            You assume total responsibility and risk for your use of this BetterNight site, site-related services, and linked websites.
            BetterNight does not warrant that files available for download will be free of viruses, worms, trojan horses or other
            destructive programming. You are responsible for implementing procedures sufficient to satisfy your needs for data back up
            and security.
          </Item>
          <Item as="p">
            Warranties relating to products or services offered, sold and distributed by BetterNight are subject to separate warranty
            terms and conditions, if any, provided by BetterNight or third parties with or in connection with the applicable products
            or services.
          </Item>
          <Item as="p">
            <b>27. Limitation of Liability Regarding Use of BetterNight site</b>
          </Item>
          <Item as="p">Except as provided by law, and without limitation:</Item>
          <Item as="p">
            BetterNight shall not be liable for the acts or omissions of the BetterNight Provider Group or the Providers. BetterNight
            and any third parties mentioned on this BetterNight site are neither responsible nor liable for any direct, indirect,
            incidental, consequential, special, exemplary, punitive, or other damages whatsoever (including, without limitation, those
            resulting from lost profits, lost data, or business interruption) arising out of or relating in any way to the BetterNight
            site, site-related services, content or information contained within the BetterNight site, and/or any linked website,
            whether based on warranty, contract, tort, or any other legal theory and whether or not advised of the possibility of such
            damages. Your sole remedy for dissatisfaction with the BetterNight site, site-related services, and/or linked websites is
            to stop using the BetterNight site and/or those services. To the extent any aspects of the foregoing limitations of
            liability are not enforceable, the maximum liability of BetterNight to you with respect to your use of this BetterNight
            site is $500 (five hundred dollars). You hereby agree to waive, to the fullest extent permitted by law, all laws that limit
            the efficacy of such indemnifications or releases.
          </Item>
          <Item as="p">
            <b>28. No Third Party Rights</b>
          </Item>
          <Item as="p">
            Unless expressly stated in the Terms of Use to the contrary, nothing herein is intended to confer any rights or remedies on
            any persons other than you, BetterNight, Better Night Provider Group, and its affiliates. Nothing in the Terms of Use is
            intended to relieve or discharge the obligation or liability of any third persons to you, BetterNight, Better Night
            Provider Group, and its affiliates, nor shall any provision give any third parties any right of subrogation or action over
            against you, BetterNight, Better Night Provider Group, and its affiliates.
          </Item>
          <Item as="p">
            <b>29. Assignment</b>
          </Item>
          <Item as="p">
            You may not assign, transfer, or delegate the Terms of Use or any part thereof without BetterNight’s prior written consent.
            BetterNight may freely transfer, assign, or delegate all or any part of the Terms of Use, and any rights or duties
            hereunder or thereunder. The Terms of Use will be binding upon and inure to the benefit of the heirs, successors, and
            permitted assignees of the parties.
          </Item>
          <Item as="p">
            <b>30. Supplemental Terms Applicable to Providers</b>
          </Item>
          <Item as="p">
            These supplemental terms apply to Providers in addition the other provisions of these Terms of Use. In the event of a
            conflict between the supplemental terms and any other terms herein, the supplemental terms shall prevail.
          </Item>
          <Item as="p">
            To be a healthcare provider using the BetterNight site (“Provider” or “you”) you must be a licensed physician, nurse
            practitioner, or healthcare professional contracted or employed by Better Night Provider Group, and must agree to comply
            with all laws, medical board rules and other rules and regulations applicable to you as a Provider or otherwise. Your
            relationship with the BetterNight users (including your Better Night Provider Group patients) is directly between you and
            the patient. The patient will never have a physician-patient relationship with BetterNight. BetterNight does not practice
            medicine and offers no medical services. As set forth more fully below, Provider is solely responsible for all agreements,
            consents, notices and other interactions with patients and other consumers. Without limiting the generality of the
            foregoing, Provider and Better Night Provider Group is solely responsible for all billings and collections from patients
            and other consumers, and BetterNight shall have no liability whatsoever to Provider with respect to any amounts owed by any
            patient or other consumer to Provider.
          </Item>
          <Item as="p">
            The BetterNight site does not provide any medical advice, legal advice, or representations in any way regarding any legal
            or medical issues associated with Provider, goods or services offered by Provider, including but not limited any compliance
            obligations or steps necessary to comply with any state or federal laws and regulations. Provider should seek legal counsel
            regarding any legal and compliance issues, and should not rely on any materials or content associated with the Services in
            determining Provider’s compliance obligations under law. Provider and BetterNight agree that BetterNight is not providing,
            to patient or anyone else, medical advice or legal advice.
          </Item>
          <Item as="p">
            Provider will use the BetterNight site and Services only in accordance with applicable standards of good medical practice.
            While software products such as the BetterNight site and Services can facilitate and improve the quality of service that
            Provider can offer patients, many factors, including the provider/patient relationship can affect a patient outcome, and
            with intricate and interdependent technologies and complex decision-making it is often difficult or impossible to
            accurately determine what the factors were and in what proportion they affected an outcome. Provider shall be solely
            responsible for its use of the BetterNight site and Services, and the provision of medical services to Provider’s patients.
            In this regard, Provider releases BetterNight and waives any and all potential claims against BetterNight as a result of
            Provider’s use of the BetterNight site and Services, and the provision of services to Provider’s patients.
          </Item>
          <Item as="p">
            As a result of the complexities and uncertainties inherent in the patient care process, Provider agrees to defend,
            indemnify and hold BetterNight harmless from any claim by or on behalf of any patient of Provider, or by or on behalf of
            any other third party or person claiming damage by virtue of a familial or financial relationship with such a patient,
            which is brought against BetterNight, regardless of the cause if such claim arises for any reason whatsoever, out of
            Provider’s use or operation of the BetterNight site and Services. To the extent applicable, Provider will obtain
            BetterNight’s prior written consent to any settlement or judgment in which Provider agrees to any finding of fault of
            BetterNight or defect in the BetterNight site or Services. BetterNight will promptly notify Provider in writing of any
            claim subject to this indemnification, promptly provide Provider with the information reasonably required for the defense
            of the same, and grant to Provider exclusive control over its defense and settlement.
          </Item>
          <Item as="p">
            If you submit, upload, transmit, or post any consents, notices, advice, recommendations, comments, files, videos, images or
            other materials to us or our BetterNight site (“Provider Content”) or provide any Provider Content to patients or other
            consumers, you agree not to provide any Provider Content that (1) is defamatory, abusive, libelous, unlawful, obscene,
            threatening, harassing, fraudulent, pornographic, or harmful, or that could encourage criminal or unethical behavior, (2)
            violates or infringes the privacy, copyright, trademark, trade dress, trade secrets or intellectual property rights of any
            person or entity, or (3) contains or transmits a virus or any other harmful component. Provider is solely responsible for
            obtaining all necessary agreements and consents from, and providing all required notices to, patients and other consumers.
            You agree not to contact other users through unsolicited e-mail, telephone calls, mailings or any other method of
            communication. You represent and warrant to BetterNight that you have the legal right and authorization to upload all
            Provider Content at the BetterNight site. BetterNight shall have a royalty-free, irrevocable, transferable right and
            license to use the Provider Content however BetterNight desires, including without limitation, to copy, modify, delete in
            its entirety, adapt, publish, translate, create derivative works from and/or sell and/or distribute such Provider Content
            and/or incorporate such Provider Content into any form, medium or technology throughout the world. BetterNight is and shall
            be under no obligation (1) to maintain any Provider Content in confidence; (2) to pay to you any compensation for any
            Provider Content; or (3) to respond to any Provider Content.
          </Item>
          <Item as="p">
            BetterNight does not regularly review Provider Content, but does reserve the right (but not the obligation) to monitor and
            edit or remove any Provider Content submitted to the BetterNight site. You grant BetterNight the right to use the name that
            you submit in connection with any Provider Content. You agree not to use a false email address, impersonate any person or
            entity, or otherwise mislead as to the origin of any Provider Content. You are and shall remain solely responsible for the
            content of any Provider Content you post to the BetterNight site or provide to patients or other consumers. BetterNight and
            its affiliates take no responsibility and assume no liability for any Provider Content submitted by you or any third party.
          </Item>
          <Item as="p" id="dispute-arbitration">
            <b>31. Dispute Resolution; Arbitration Agreement</b>
          </Item>
          <Item as="p">
            We will try to work in good faith to resolve any issue you have with the BetterNight site, including Products and Services
            ordered or purchased through the BetterNight site, if you bring that issue to the attention of our customer service
            department. However, we realize that there may be rare cases where we may not be able to resolve an issue to a customer’s
            satisfaction.
          </Item>
          <Item as="p">
            You and BetterNight agree that any dispute, claim or controversy arising out of or relating in any way to these Terms of
            Use or your use of the BetterNight site, including Products and Services ordered or purchased through the BetterNight site,
            shall be determined by binding arbitration instead of in courts of general jurisdiction. Arbitration is more informal than
            bringing a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury,and is subject to very
            limited review by courts. Arbitration allows for more limited discovery than in court, however, we agree to cooperate with
            each other to agree to reasonable discovery in light of the issues involved and amount of the claim. Arbitrators can award
            the same damages and relief that a court can award, but in so doing, the arbitrator shall apply substantive law regarding
            damages as if the matter had been brought in court, including without limitation, the law on punitive damages as applied by
            the United States Supreme Court. You agree that, by agreeing to these Terms of Use, the U.S. Federal Arbitration Act
            governs the interpretation and enforcement of this provision, and that you and BetterNight are each waiving the right to a
            trial by jury or to participate in a class action. This arbitration provision shall survive termination of these Terms of
            Use and any other contractual relationship between you and BetterNight.
          </Item>
          <Item as="p">
            If you desire to assert a claim against BetterNight, and you therefore elect to seek arbitration, you must first send to
            BetterNight, by certified mail, a written notice of your claim (“Notice”). The Notice to BetterNight should be addressed
            to: Better Night 5471 Kearny Villa Rd., Ste 200, San Diego, CA 92123(“Notice Address”). If BetterNight desires to assert a
            claim against you and therefore elects to seek arbitration, it will send, by certified mail, a written Notice to the most
            recent address we have on file or otherwise in our records for you. A Notice, whether sent by you or by BetterNight, must
            (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). If
            BetterNight and you do not reach an agreement to resolve the claim within 30 days after the Notice is received, you or
            BetterNight may commence an arbitration proceeding or file a claim in small claims court. During the arbitration, the
            amount of any settlement offer made by BetterNight or you shall not be disclosed to the arbitrator. You may download or
            copy a form Notice and a form to initiate arbitration from the American Arbitration Association at www.adr.org. If you are
            required to pay a filing fee, after BetterNight receives notice at the Notice Address that you have commenced arbitration,
            it will promptly reimburse you for your payment of the filing fee, unless your claim is for more than US $10,000. The
            arbitration will be governed by the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related
            Disputes (collectively, “AAA Rules”) of the American Arbitration Association (“AAA”), as modified by these Terms of Use,
            and will be administered by the AAA. The AAA Rules and Forms are available online at www.adr.org, by calling the AAA at
            1-800-778-7879, or by requesting them from us by writing to us at the Notice Address. The arbitrator is bound by the terms
            of these Terms of Use. All issues are for the arbitrator to decide, including issues relating to the scope and
            enforceability of these Terms of Use, including this arbitration agreement. Unless BetterNight and you agree otherwise, any
            arbitration hearings will take place in the county (or parish) of your billing address. (If you reside outside of the
            United States, any arbitration hearings will take place in your country of residence at a location reasonably convenient to
            you, but will remain subject to the AAA Rules including the AAA rules regarding the selection of an arbitrator). If your
            claim is for US $10,000 or less, we agree that you may choose whether the arbitration will be conducted solely on the basis
            of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the
            AAA Rules. If your claim exceeds US $10,000, the right to a hearing will be determined by the AAA Rules. Regardless of the
            manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain
            the essential findings and conclusions on which the award is based. If the arbitrator issues you an award that is greater
            than the value of BetterNight’s last written settlement offer made before an arbitrator was selected (or if BetterNight did
            not make a settlement offer before an arbitrator was selected), then BetterNight will pay you the amount of the award or US
            $1,000, whichever is greater. Except as expressly set forth herein, the payment of all filing, administration and
            arbitrator fees will be governed by the AAA Rules. Each party shall pay for its own costs and attorneys’ fees, if any.
            However, if any party prevails on a statutory claim that affords the prevailing party attorneys’ fees, or if there is a
            written agreement providing for payment or recovery attorneys’ fees, the arbitrator may award reasonable fees to the
            prevailing party, under the standards for fee shifting provided by law.
          </Item>
          <Item as="p">
            YOU AND BETTERNIGHT AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS
            A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and BetterNight
            agree otherwise, the arbitrator may not consolidate more than one person’s claims with your claims, and may not otherwise
            preside over any form of a representative or class proceeding. The arbitrator may award declaratory or injunctive relief
            only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that
            party’s individual claim.
          </Item>
          <Item as="p">
            If this Agreement to Arbitrate provision is found to be unenforceable, then (a) the entirety of this arbitration provision
            shall be null and void, but the remaining provisions of these Terms of Use shall remain in full force and effect; and (b)
            exclusive jurisdiction and venue for any claims will be in state or federal courts located in and for San Diego, CA.
          </Item>
          <Item as="p">
            <b>32. Force Majeure</b>
          </Item>
          <Item as="p">
            We will not be deemed to be in breach of these terms or liable for any breach of these terms or our privacy policy due to
            any event or occurrence beyond our reasonable control, including without limitation, acts of God, terrorism, war, invasion,
            failures of any public networks, electrical shortages, earthquakes or floods, civil disorder, strikes, fire or other
            disaster.
          </Item>
          <Item as="p">
            <b>33. Indemnification</b>
          </Item>
          <Item as="p">
            You agree to defend, indemnify, and hold harmless BetterNight, Better Night Provider Group, and any affiliates from and
            against any and all rights, demands, losses, liabilities, damages, claims, causes of action, actions, and suits (no matter
            whether at law or equity), fees, costs, and attorney’s fees of any kind whatsoever arising directly or indirectly out of or
            in connection with: (i) your use or misuse of the BetterNight site, Products or Services or any information posted on the
            BetterNight site; (ii) your breach of the Terms of Use or Privacy Policy; (iii) the content or subject matter of any
            information you provide to BetterNight, Better Night Provider Group, or any Provider or customer service agent; and/or (iv)
            any negligent or wrongful act or omission by you in your use or misuse of the BetterNight site, Products or Services or any
            information on the BetterNight site, including without limitation, infringement of third party intellectual property
            rights, privacy rights, or negligent or wrongful conduct.
          </Item>
          <Item as="p">
            <b>34. Revisions; General</b>
          </Item>
          <Item as="p">
            BetterNight reserves the right, in its sole discretion, to terminate your access to all or part of this BetterNight site,
            with or without cause, and with or without notice. BetterNight reserves the right to modify these Terms of Use at any time,
            effective upon posting. Any use of this website after such changes will be deemed an acceptance of those changes. You agree
            to review the Terms of Use each time you access this website so that you may be aware of any changes to these Terms. In the
            event that any of the Terms of Use are held by a court or other tribunal of competent jurisdiction to be unenforceable,
            such provisions shall be limited or eliminated to the minimum extent necessary so that these Terms of Use shall otherwise
            remain in full force and effect. These Terms of Use constitute the entire agreement between BetterNight and you pertaining
            to the subject matter hereof. In its sole discretion, BetterNight may from time-to-time revise these Terms of Use by
            updating this posting. You should, therefore, periodically visit this page to review the current Terms of Use, so you are
            aware of any such revisions to which you are bound. Certain provisions of these Terms of Use may be superseded by expressly
            designated legal notices or terms located on particular pages within this BetterNight site.
          </Item>
          <Item as="p">
            Copyright ©2021 Better Night Services All rights reserved. All trademarks, logos and service marks (“Marks”) displayed on
            the BetterNight site are our property or the property of other third parties. You are not permitted to use these Marks
            without our prior written consent or the consent of such third party which may own the Marks.
          </Item>
          <Item as="p">
            {' '}
            How to Contact Us: <br />
            BetterNight <br />
            5471 Kearny Villa Road, Suite 200 <br />
            San Diego, CA 92123 <br />
            <br />
            Telephone: 866-801-9440
            <br />
            Email: info@betternight.com
          </Item>
        </Segment>
        <Segment basic id="policy">
          <Header as="h2" id="betterNight-policy">
            BETTERNIGHT PRIVACY POLICY
          </Header>
          <Item as="p">
            <b>1. INTRODUCTION</b>
          </Item>
          <Item as="p">
            <b>Purpose.</b> The purpose of this Privacy Policy is to describe how d/b/a BetterNight and Better Night Provider Group
            collects, uses and shares information about you through our online interfaces (e.g., websites and mobile applications)
            owned and controlled by us, including{' '}
            <a href="https://betternight.com/" className="link" target="_blank" rel="noreferrer">
              www.betternight.com
            </a>{' '}
            (collectively referred to herein as the “BetterNight site”). Please read this notice carefully to understand what we do. If
            you do not understand any aspects of our Privacy Policy, please feel free to{' '}
            <a href="#how-to-contact" className="link">
              Contact Us
            </a>{' '}
            or as described at the end of this Policy.
          </Item>
          <Item as="p">Our Privacy Policy explains:</Item>
          <Item as="p">
            <a href="#information-collect" className="link">
              Information That We Collect{' '}
            </a>
          </Item>
          <Item as="p">
            <a href="#how-use-share-info" className="link">
              How We Use and Share Your Information{' '}
            </a>
          </Item>
          <Item as="p">
            <a href="#access-your-info" className="link">
              Access to Your Information and Choices
            </a>
          </Item>
          <Item as="p">
            <a href="#security-your-info" className="link">
              Security of Your Information{' '}
            </a>
          </Item>
          <Item as="p">
            <a href="#personal-data" className="link">
              Consent to Processing of Personal Data in United States
            </a>
          </Item>
          <Item as="p">
            <a href="#change-our-policy" className="link">
              Changes to Our Privacy Policy
            </a>
          </Item>
          <Item as="p">
            <a href="#how-to-contact" className="link">
              Questions and How to Contact Us
            </a>
          </Item>
          <Item as="p">
            <b>Scope; Third Party Sites.</b> This Privacy Policy applies only to information we collect at and through the BetterNight
            site. Our BetterNight site also contains links to third party sites that are not owned or controlled by BetterNight and
            Better Night Provider Group. Please be aware that we are not responsible for the privacy practices of such other sites. We
            encourage you to be aware when you leave our BetterNight site and to read the privacy statements of each and every website
            that collects personal information.
          </Item>
          <Item as="p">
            <b>Terms of Use.</b> Please note that your use of our BetterNight site is also subject to our{' '}
            <a href="#termCondition" className="link">
              Terms of Use.
            </a>
          </Item>
          <Item as="p" id="information-collect">
            <b>2. INFORMATION THAT WE COLLECT</b>
          </Item>
          <Item as="p">
            <b>Information You Provide to Us</b>
          </Item>
          <Item as="p">
            We collect information you provide to us, for example when you create or modify your account, register to use our
            BetterNight site, purchase products or services from us, request information from us, contact customer support, or
            otherwise communicate with us. This information may include:
          </Item>
          <Item as="ul">
            <li>Name</li>
            <li>Address</li>
            <li>Billing Address</li>
            <li>Shipping Address</li>
            <li>Email Address</li>
            <li>Telephone number</li>
            <li>Date of birth</li>
            <li>Medical history / health related information</li>
            <li>Credit card number (solely for payment purposes)</li>
          </Item>
          <Item as="p">
            <b>Information We Collect Automatically Through Your Use of our BetterNight site</b>
          </Item>
          <Item as="p">
            <b>Google Analytics</b>
          </Item>
          <Item as="p">
            We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”) to collect certain information
            relating to your use of the BetterNight site. Google Analytics uses “cookies”, which are text files placed on your
            computer, to help the BetterNight site analyze how users use the BetterNight site. You can find out more about how Google
            uses data when you visit our BetterNight site by visiting “How Google uses data when you use our partners' sites or apps”,
            (located at{' '}
            <a className="link" href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noreferrer">
              www.google.com/policies/privacy/partners/
            </a>
            ). For more information regarding Google Analytics please visit Google's website, and pages that describe Google Analytics,
            such as{' '}
            <a className="link" href="https://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noreferrer">
              www.google.com/analytics/learn/privacy.html
            </a>
            .
          </Item>
          <Item as="p">
            <b>Information Collected Through Cookies and Similar Technologies</b>
          </Item>
          <Item as="p">
            We and our service providers use cookies, web beacons, and other technologies to receive and store certain types of
            information whenever you interact with our BetterNight site through your computer or mobile device. A cookie is a small
            file containing a string of characters that is sent to your computer when you visit a website. When you visit the
            BetterNight site again, the cookie allows the BetterNight site to recognize your browser. Cookies may store unique
            identifiers, user preferences and other information. You can reset your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some BetterNight site features or services may not function properly without cookies. We use
            cookies to improve the quality of our service, including for storing user preferences, and tracking user trends.
          </Item>
          <Item as="p">
            <b>No Information from Children Under Age 18</b>
          </Item>
          <Item as="p">
            If you are under the age of 18, please do not attempt to register with us at this BetterNight site or provide any personal
            information about yourself to us. If we learn that we have collected personal information from a child under the age of 18,
            we will promptly delete that information. If you believe we have collected personal information from a child under the age
            of 18, please call us at 1-866-801-9440.
          </Item>
          <Item as="p" id="how-use-share-info">
            <b>3. HOW WE USE AND SHARE YOUR INFORMATION</b>
          </Item>
          <Item as="p">
            <b>To Provide and Improve Products, Services, and Information.</b> We collect User Information and other information from
            you in order to provide and improve products and services that you purchase using the BetterNight site or we provide to
            others; register and service your online account; provide information that you request from us; contact you about your
            orders; process credit card and debit card transactions; ship products to you; send you promotional materials or
            advertisements about our products and services, as well as new features and offerings; and, administer surveys,
            sweepstakes, promotions and contests.
          </Item>
          <Item as="p">
            <b>Research Use:</b> BetterNight may use aspects of User Information in research so as to improve its Service, Products and
            Information. Prior to such research use, BetterNight will de-identify User Information. “De-identification” is a process of
            detecting identifiers (e.g., personal names and social security numbers) that directly or indirectly point to a person (or
            entity) and deleting those identifiers from the data. Health information containing personal identifiers that are linked to
            You (and part of User Information) is defined as protected health information (“Protected Health Information” or “PHI”) and
            is protected under the federal and state law. Only User Information that is de-identified (“Your De-Identified
            Information”) shall be used in BetterNight’s research as described in this paragraph. You acknowledge and agree that
            BetterNight shall have a royalty-free, irrevocable, worldwide, non-exclusive right and license to use Your De-identified
            Information to copy, modify, adapt, translate, create derivative works, improve the quality of our services and products,
            develop or create new services or products and/or incorporate Your De-Identified Information into any form, medium or
            technology now known or later developed throughout the world without compensation.
          </Item>
          <Item as="p">
            <b>Sharing with Third Parties.</b> We may provide User Information to our affiliates and certain third party service
            providers that help us operate and manage our BetterNight site, process orders, and fulfill and deliver products and
            services that you purchase from us. These affiliates and service providers will have access to your personal information in
            order to provide these services, but when this occurs we implement reasonable contractual and technical protections to
            limit their use of that information to helping us provide the service.
          </Item>
          <Item as="p">
            <b>Your Consent.</b> In addition to the sharing described elsewhere in this Policy, we will share personal information with
            companies, organizations or individuals outside of BetterNight and Better Night Provider Group when we have your consent to
            do so.
          </Item>
          <Item as="p">
            <b>Legal Proceedings.</b> We will share personal information with third party companies, organizations or individuals
            outside of BetterNight if we have a good-faith belief that access, use, preservation or disclosure of the information is
            reasonably necessary to:
          </Item>
          <Item as="ul">
            <li> Meet any applicable law, regulation, subpoena, legal process or enforceable governmental request. </li>
            <li> Enforce applicable Terms of Use, including investigation of potential violations. </li>
            <li> Detect, prevent, or otherwise address fraud, security or technical issues. </li>
            <li>
              {' '}
              Protect against harm to the rights, property or safety of BetterNight and Better Night Provider Group, our users,
              customers or the public as required or permitted by law.{' '}
            </li>
          </Item>
          <Item as="p">
            <b>Transfer in the Event of Sale or Change of Control.</b> If the ownership of all or substantially all of our business
            changes or we otherwise transfer assets relating to our business or the BetterNight site to a third party, such as by
            merger, acquisition, bankruptcy proceeding or otherwise, we may transfer or sell your personal information to the new
            owner. In such a case, unless permitted otherwise by applicable law, your information would remain subject to the promises
            made in the applicable privacy policy unless you agree differently.
          </Item>
          <Item as="p" id="access-your-info">
            <b>4. ACCESS TO YOUR INFORMATION AND CHOICES</b>
          </Item>
          <Item as="p">
            You can access and update certain information we have relating to your online account by signing into your account and
            going to the Account Settings section of our BetterNight site. If you have questions about personal information we have
            about you or need to update your information, you can call us at 1-866-801-9440. You can opt-out of receiving marketing and
            promotional emails from BetterNight and Better Night Provider Group by using the opt-out or unsubscribe feature contained
            in the emails.
          </Item>
          <Item as="p">
            You can close your online account by going to the Account Settings section of our BetterNight site. If you close your
            account, we will no longer use your online account information or share it with third parties. We may, however, retain a
            copy of the information for archival purposes, and to avoid identity theft or fraud.
          </Item>
          <Item as="p" id="security-your-info">
            <b>5. SECURITY OF YOUR INFORMATION</b>
          </Item>
          <Item as="p">
            We use industry standard physical, technical and administrative security measures and safeguards to protect the
            confidentiality and security of your personal information. However, since the Internet is not a 100% secure environment, we
            cannot guarantee, ensure, or warrant the security of any information you transmit to us. There is no guarantee that
            information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or
            managerial safeguards. It is your responsibility to protect the security of your login information. Please note that emails
            and other communications you send to us through our BetterNight site are not encrypted, and we strongly advise you not to
            communicate any confidential information through these means.
          </Item>
          <Item as="p" id="personal-data">
            <b>6. CONSENT TO PROCESSING OF PERSONAL DATA IN UNITED STATES</b>
          </Item>
          <Item as="p">
            If you are a resident of the European Economic Area (EEA) or other jurisdiction outside of the U.S., in order to provide
            our BetterNight site, products and services to you, we may send and store your personal information (also commonly referred
            to as personal data) outside of the EEA, including to the United States. Accordingly, your personal information may be
            transferred outside of the country where you reside or are located, including to countries that may not or do not provide
            the same level of protection for your personal information. We are committed to protecting the privacy and confidentiality
            of personal information when it is transferred. Where such transfers occur, we take appropriate steps to provide the same
            level of protection for the processing carried out in any such countries as within the EEA to the extent feasible under
            applicable law. By using and accessing our BetterNight site, users who reside or are located in countries outside of the
            United States agree and consent to the transfer to and processing of personal information on servers located outside of the
            country where they reside, and that the protection of such information may be different than required under the laws of
            their residence or location.
          </Item>
          <Item as="p" id="change-our-policy">
            <b>7. CHANGES TO OUR PRIVACY POLICY</b>
          </Item>
          <Item as="p">
            Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your
            consent in accordance with applicable law. We will post any privacy policy changes on this page and, if the changes are
            significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy
            changes). We will also keep prior versions of this Privacy Policy in an archive for your review.
          </Item>
          <Item as="p" id="how-to-contact">
            <b>8. QUESTIONS AND HOW TO CONTACT US</b>
          </Item>
          <Item as="p">
            If you have any questions, concerns, complaints or suggestions regarding our Privacy Policy or otherwise need to contact
            us, please email us at{' '}
            <a className="link" href="mailto:info@betternight.com">
              {' '}
              info@betternight.com
            </a>
            , call us at 1-866-801-9440, or contact us by US postal mail at the following address:{' '}
          </Item>
          <Item as="p">
            {' '}
            Better Night Services <br /> 5471 Kearny Villa Road, Suite 200 <br /> San Diego, CA 92123{' '}
          </Item>
        </Segment>
        <Segment basic>
          <Header as="h3" id="noticePrivacy">
            NOTICE OF PRIVACY PRACTICES
          </Header>
          <Item as="p">
            <b>Effective April 14, 2003</b>
          </Item>
          <Item as="p">
            The privacy of your Protected Health Information is important to us. Please review this notice carefully, as it describes
            how your medical information is used and maintained by our organization and by our offices and work staff. It also
            describes your rights as to the information and how you can get access to it.
          </Item>
          <Item as="p">
            PLEASE CONTACT OUR PRIVACY OFFICER (contact information below) WITH ANY QUESTIONS THAT YOU MAY HAVE REGARDING THIS NOTICE.
            WE URGE YOU TO REVIEW THIS NOTICE CAREFULLY AND ASK ANY QUESTIONS THAT YOU MAY HAVE ABOUT THE USE OR SHARING OF YOUR
            PROTECTED HEALTH INFORMATION.
          </Item>
          <Item as="p">
            <b>Your Protected Health Information (PHI)</b>
          </Item>
          <Item as="p">
            Whenever you receive or request durable medical equipment or services from our organization through a prescription, we
            receive and create personal medical information about you, and about the equipment or services you receive or request. We
            need this information in order to provide equipment or services to you and to comply with certain legal requirements. It is
            our goal to make sure that the personal medical information we receive or create about you is kept strictly private. It is
            necessary, however, to use it or share this information with others from time to time, but only under proper circumstances.
            This Notice describes how we may properly use and share your medical information, which we refer to as “protected health
            information” or “PHI”. This Notice also describes your rights to access and control your PHI. In reviewing this Notice, it
            may appear that your medical information is used or shared in many ways. But, this is a comprehensive list and certain
            events may never occur or might happen only once or a few times. For the most part, your medical information is used or
            shared only in connection with the equipment or services that we provide you. We have an obligation to make sure that we
            give you a copy of this Notice and follow its terms. This Notice applies to PHI generated at each of our offices. When we
            refer to “we” or “us” in this Notice, we mean our organization and our staff, and also refer to each of our offices, and
            the technicians and other work force staff who contribute to your care. We will provide you with a list of the persons and
            locations covered by this Notice upon your request.
          </Item>
          <Header as="h3">HOW WE USE OR SHARE PROTECTED HEALTH INFORMATION</Header>
          <Item as="p">
            <b>TYPICAL USES & SHARING</b>
          </Item>
          <Item as="p">
            Your protected health information may be used or disclosed for these typical situations, without your prior authorization
          </Item>
          <Item as="p">
            <b>Treatment:</b> We will use and disclose your protected health information in order to provide durable medical equipment
            and services or to assist physicians or other health care providers assess you medical condition or treat you. We may
            disclose your protected health information to your primary care or family physician, to a Pacific Sleep Medicine physician,
            to a specialist, or to another clinic, physician, hospital or other health care provider who requests this information in
            connection with your care and treatment. Your shared protected health information may include information that we receive
            from Pacific Sleep Medicine or from other physicians or health care providers. For example, we may have receive and
            maintain an order from your physician and sleep study test results, which we may need in order to determine the durable
            medical equipment and services that you require. We may in turn disclose information regarding your durable medical
            equipment and services and other protected health information in our possession to your personal physician or other health
            care provider who is treating you.
          </Item>
          <Item as="p">
            <b>Payment:</b> We may use and disclose your protected health information in order to obtain payment for our services or to
            allow insurance companies, health plans, government agencies and managed care companies to process claims for services
            rendered by us to you. For example, we may need to give your health plan information about your health condition in order
            to obtain authorization for you to receive durable medical equipment or services.
          </Item>
          <Item as="p">
            <b>Health Care Operations:</b> We will use and disclose your protected health information in order to evaluate the quality
            and appropriateness of care provided by our physicians and health care professionals. We may need to use and disclose
            protected health information in connection with our licensing, payment certification, and other status. We may use and
            disclose your protected health information in our organization’s day-to-day operations to enable it to operate smoothly,
            efficiently and in compliance with applicable laws. As examples, your protected health information may be used for routine
            activities such as calling you to remind you of a scheduled test. We may also consider your information in planning, as
            well as use your information to assist in training.
          </Item>
          <Item as="p">
            <b>Employer/Plan Sponsors:</b> We may disclose your protected health information to your employer or other group health
            plan sponsor in connection with administration of the health plan and/or payment for services. Information to your employer
            that falls outside of these purposes may require your prior written authorization.
          </Item>
          <Item as="p">
            <b>Healthcare Information:</b> We may use your protected health information to contact you from time to time with
            information about services that we offer, to coordinate your care with other health care providers, or with treatment
            alternatives. If you do not wish to receive this type of information, you may opt out of receiving this information by
            contacting the Privacy Officer in writing. However, even if you elect not to receive this information, you may still
            continue to receive information made available to patients generally, such as newsletters or updates.
          </Item>
          <Item as="p">
            <b>You, Family and Close Friends:</b> We may disclose your protected health information to you, unless there is information
            in your file that we are not legally authorized to release to you, such as information related to psychotherapy. We may
            also disclose information to a family member, friend or other person if you are incapacitated such as in a medical
            emergency or disaster relief. We will disclose this information only to the extent necessary to help with your health care
            or with payment for your health care.
          </Item>
          <Item as="P">
            <b>Public Health and Safety; Research:</b> We may use and disclose your protected health information to the extent
            necessary to avert a serious and imminent threat to your health or safety or the health or safety of others. We may use or
            disclose your protected health information for limited research purposes.
          </Item>
          <Item as="p">
            <b>Outside Service:</b> We may also need to share your protected health information with outside individuals or companies
            that perform services for us. For example, if we use a vendor or contractor to perform such things as billing or practice
            management, they may need access to your protected health information. We ask that any outside service or vendor safeguard
            the privacy of your protected health information in their possession. We do not intend to share your information with any
            outside service that does not need your information to do its job, such as maintenance crews.
          </Item>
          <Item as="p">
            <b>Research Use:</b> As set forth in Section 3 above, BetterNight may use aspects of User Information in research so as to
            improve its Services, Products and Information. Provided, however, BetterNight will de-identify User Information, including
            your PHI prior to any such research as described above.
          </Item>
          <Item as="p">
            <b>Unintended Disclosure:</b> We will try our best to prevent this, but it is possible that others may learn of protected
            health information because they hear or see information that is not meant for them. For example, another patient might
            overhear a conversation between you and a durable medical equipment or service technician. We use reasonable efforts to try
            to prevent any such disclosure from occurring.
          </Item>
          <Item as="p">
            <b>Authorized Use or Disclosure:</b>
          </Item>
          <Item as="p">
            If you specifically authorize us to do so in writing, we will share your protected health information to persons who are
            not involved with your care and not included in one of the categories listed above. This might include, for example, your
            employer (for reasons other than related to health plan administration), a life insurance company or a distant relative.
            Our Privacy Officer or our staff will provide the necessary form for this authorization. You may cancel this authorization
            at any time.
          </Item>
          <Header as="h3">UNUSUAL USES OR DISCLOSURES:</Header>
          <Item as="p">Among the unusual uses or disclosures that may occur without your prior authorization are the following:</Item>
          <Item as="p">
            <b>Required by Law:</b> We will use or disclose your protected health information when we are required to do so by law. For
            example, we would be required to share such information with a government agency in connection with an audit or
            investigation, or if we are required by law to report a health condition to a federal, state or local agency.
          </Item>
          <Item as="p">
            <b>Process and Proceedings:</b> We may disclose your protected health information in response to a court or administrative
            order, subpoena, discovery request or other lawful process.
          </Item>
          <Item as="p">
            <b>Law Enforcement:</b> We may disclose limited information to a law enforcement official concerning the protected health
            information of a suspect, fugitive, material witness, crime victim or missing person. We may disclose the protected health
            information of an inmate or other person in lawful custody to a law enforcement official or correctional institution under
            certain circumstances.
          </Item>
          <Item as="p">
            <b>Military and National Security:</b> We may disclose to Military authorities the protected health information of Armed
            Forces personnel. We may disclose to authorized federal officials protected health information required for lawful
            intelligence, counterintelligence and other national security activities.
          </Item>
          <Header as="h3">INDIVIDUAL RIGHTS</Header>
          <Item as="p">
            You have rights with respect to your protected health information. If you have any questions about these rights or want to
            exercise any of these rights, please contact our Privacy Officer (see contact information below), who will assist you. You
            may have to pay a fee, depending on your request.
          </Item>
          <Item as="p">
            <b>Inspect and Copy Your Records:</b> Except for certain mental health information, if any, included in your records, you
            may inspect and receive a copy of part or all of your protected health information. Your request must be in writing, and we
            will charge a fee to provide a copy. We also will need a reasonable time to provide the copy, as permitted by law.
          </Item>
          <Item as="p">
            <b>Request Restrictions:</b> You may request restrictions on how your protected health information is used or disclosed.
            You can request, in writing, that we place additional restrictions on the use or disclosure of your protected health
            information. We are not required to agree to these additional restrictions, but if we elect to do so, we will abide by our
            agreement (except in an emergency).
          </Item>
          <Item as="p">
            <b>Receive Confidential Communications:</b> You can specify how and where we should send protected health information. For
            example, you may want all such information in writing, rather than left as a voice message. Or you may request that we send
            all correspondence for you to your work address. We will accommodate reasonable requests.
          </Item>
          <Item as="p">
            <b>Amend Your Record:</b> If you feel that health information we have about you is incorrect or incomplete, you may ask us
            to amend or change the information. However, we might not agree to your request. There are various reasons why we may deny
            your request for an amendment. If you submit a request for amendment, we will provide you with more information about the
            process. We will notify you in writing regarding our action on your request.
          </Item>
          <Item as="p">
            <b>Log of Unusual Events:</b> You have the right to request a log of unusual events that resulted in our sharing protected
            health information. We are required to maintain events on this list for six years, starting April 14, 2003. The log will
            only list those disclosures that you have not authorized, and which were not related to treatment, payment or our
            operations. This log might include, for example, the sharing of information with the police or with a government agency,
            which was necessary without your permission.
          </Item>
          <Item as="p">Copy of This Notice: You may receive, upon request, a paper copy of this notice at any time.</Item>
          <Item as="p">
            <b>OUR RIGHT TO CHANGE NOTICE</b>
          </Item>
          <Item as="p">
            We reserve the right to change this notice. We may modify or change our privacy practices from time to time, particularly
            as new laws and regulations become effective. Any changes will be effective for all the protected health information that
            we maintain, even information in existence before the change. If we materially modify our privacy practices, we will
            provide you with a new notice advising you of these changes when you next obtain services from us.
          </Item>
          <Item as="p">
            <b>COMPLAINTS</b>
          </Item>
          <Item as="p">
            If you believe that your protected health information was not handled properly or feel that we have not allowed you to
            exercise your rights, you may file a complaint with the Privacy Officer (see contact information below). All complaints
            must be submitted in writing. You can also contact Region IX of the Office of Civil Rights of the Department of Health and
            Human Services, at (415) 437-8310 voice, (415) 437-8329 fax, or at e-mail address OCRComplaint@hhs.gov. We respect your
            rights and will not retaliate against you or stop your care if you feel it necessary to file a complaint.
          </Item>
          <Item as="p">
            {' '}
            Contact Information <br /> Privacy Officer: <br /> Bretton Lane <br /> 5471 Kearny Villa Road, Suite 200 | San Diego, CA
            92123 <br /> Telephone 1-866-801-9440 <br />
          </Item>
        </Segment>
      </main>
    </>
  );
};
