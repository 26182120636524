import { Outlet, useLocation, Navigate } from 'react-router-dom';
import './AccountManagement.scss';

const AccountManagement = () => {
  const location = useLocation();

  if (location.pathname === '/account-management') {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default AccountManagement;
