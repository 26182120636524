import { useState } from 'react';
import { Item } from 'semantic-ui-react';
import ProviderFooter from '../../ProviderFooter/ProviderFooter';
import ProviderHeader from '../../ProviderHeader/ProviderHeader';
import ProviderSidebar from '../../ProviderSidebar/ProviderSidebar';

import './PrivateLayout.scss';

type PrivateLayoutProps = {
  children: React.ReactNode;
};

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <Item as="div">
      <ProviderHeader toggleMenu={() => setVisible(!visible)} />
      <ProviderSidebar visible={visible} onHide={() => setVisible(false)} />
      {children}
      <ProviderFooter />
    </Item>
  );
};

export default PrivateLayout;
