import { gql } from '@apollo/client/core';

export default gql`
  query WorklistsCountByType {
    worklistsCountByType {      
      worklistTypeCode
      worklistItemsCount
    }
  }
`;
