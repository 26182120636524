import './Loader.scss';

type LoadingProps = {
  show?: boolean;
};

export const Loading = ({ show = false }: LoadingProps) => {
  return (
    <div id="loader" className={show ? 'loader-spin' : 'hide'}>
      <span></span>
    </div>
  );
};

export default Loading;
