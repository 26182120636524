import { Form, Item, Checkbox, CheckboxProps } from 'semantic-ui-react';
import './InputCheckbox.scss';

export interface InputCheckboxProps {
  text?: string;
  toggle?: boolean;
  label?: string;
  inline?: any;
  addClasses?: string;
  requiredHintText?: Boolean;
  circular?: any;
  checked: boolean;
  disabled?: boolean;
  dataTrue?: string;
  dataFalse?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

export const InputCheckbox = ({
  text,
  label,
  inline = true,
  addClasses,
  toggle,
  disabled,
  onChange,
  checked,
  dataTrue,
  dataFalse,
}: InputCheckboxProps) => {
  return (
    <Form.Field className={`${addClasses} custom-checkbox`} inline={inline}>
      {label ? <label>{label}</label> : ''}
      <Item as="div">
        <Checkbox
          data-true={dataTrue}
          data-false={dataFalse}
          toggle={toggle}
          disabled={disabled}
          checked={ checked}
          onChange={onChange}
          label={addClasses?.includes('switch-button') ? '' : text}
        />
        {text && addClasses?.includes('switch-button') ? <span className="text">{text}</span> : ''}
      </Item>
    </Form.Field>
  );
};
