import { useLazyQuery, useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Item } from 'semantic-ui-react';

import { InputButton } from '../../components/controls';
import { ActionProp, FieldProp, Filter, Loading, ModalPopup, ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useDownloadFile, useProviders } from '../../hooks';
import { queryDownloadCSVPatients, queryLastSyncedPatientDate } from '../../services';
import { Provider } from '../../types';
import { ROLES } from '../../utilities';
import { convertToPST } from '../../utilities/sharedFunctions';
import { PatientStatusTable } from './PatientStatusTable';

import './PatientStatus.scss';

type FilterFormProps = {
  name: string;
  dob: string;
  physician?: string;
  providerGroup?: string;
  submitted?: boolean;
  brightreeId?: string;
  patientBrightreeId?: string;
};

const filterByLastStatusChange = [
  { value: 'all', text: 'Show all patients', key: 1 },
  {
    value: 'IN_LAST_7_DAYS',
    text: 'Show patients with status changes in the last 7 days',
    key: 2,
  },
  {
    value: 'IN_LAST_30_DAYS',
    text: 'Show patients with status changes in the last 30 days',
    key: 3,
  },
  {
    value: 'NO_CHANGE_IN_LAST_7_DAYS',
    text: 'Show patients with no status changes in the last 7 days',
    key: 4,
  },
];

const filterByOrderStatusChange = [
  { value: 'all', text: 'Show all orders', key: 1 },
  {
    value: 'OPEN_ORDERS',
    text: 'Show only open orders',
    key: 2,
  },
  {
    value: 'CLOSED_ORDERS',
    text: 'Show only closed orders',
    key: 3,
  },
];

const PageTitle = [{ key: 'Patient Status', content: 'Patient Status ', active: true }];
const showAllProviders = 'Show All Providers';

export const PatientStatus = () => {
  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const { user: currentLoggedInUser } = useContext(GlobalContext);
  const { isProvider = false, role, provider } = currentLoggedInUser || {};

  const downloadFile = useDownloadFile();

  const [searchOptions, setSearchOptions] = useState<{
    [k: string]: any;
  } | null>(null);

  const [downloadPatientsCSV, { error }] = useLazyQuery(queryDownloadCSVPatients, {
    fetchPolicy: 'no-cache',
  });
  const { data: lastSyncData } = useQuery(queryLastSyncedPatientDate);

  const providers = useProviders();

  const providersForDropdown = useMemo(() => {
    const array = uniqBy(
      (isProvider
        ? providers.filter(
            (providerInfo: Provider) =>
              (provider?.group?.name && providerInfo.group?.name === provider.group.name) ||
              (!provider?.group && providerInfo.name === provider?.name),
          )
        : providers) as Provider[],
      'name',
    );

    const result = array
      .map(({ name }: Provider) => {
        const trimmedName = (name || '').trim();
        return {
          value: trimmedName,
          text: trimmedName,
          key: trimmedName,
        };
      })
      ?.sort((a: any, b: any) => a.text.localeCompare(b.text));

    return result;
  }, [isProvider, provider?.group, provider?.name, providers]);

  const providersGroup = useMemo(() => {
    const providerGroupNameArr: { value: string; text: string; key: string }[] = [];

    providers.forEach(({ group }: Provider) => {
      if (
        group &&
        (isProvider ? group.isCatchGroup === false : true) &&
        !providerGroupNameArr.find((item) => item.text === group.originalName)
      ) {
        const groupName = group.originalName;
        providerGroupNameArr.push({
          value: groupName,
          text: groupName,
          key: groupName,
        });
      }
    });
    return providerGroupNameArr?.sort((a: any, b: any) => a.text.localeCompare(b.text));
  }, [isProvider, providers]);

  const filterFields: FieldProp[] = useMemo(() => {
    const isAdminRole = ['accountadmin', 'superadmin'].includes(role?.code || '');

    const getStatusBTWIPField = (): FieldProp => ({
      name: 'statusBTWIP',
      option: [{ value: 'BT_WIP' }],
      type: 'checkbox',
      addClasses: 'checkbox-status',
      label: 'Only show patients missing requested documentation',
      skipTagging: true,
    });

    const getStatusUnknownField = (): FieldProp | null => {
      if (!isAdminRole) return null;
      return {
        name: 'statusUnknown',
        option: [{ value: 'Unknown' }],
        type: 'checkbox',
        addClasses: 'checkbox-status',
        label: 'Only show patients with status unknown',
        skipTagging: true,
      };
    };

    const getPatientBrightreeIdField = (): FieldProp | null => {
      if (!isAdminRole) return null;
      return {
        name: 'patientBrightreeId',
        type: 'numberString',
        label: 'Patient BT ID',
        placeholder: 'Patient BT ID',
      };
    };

    const getNameField = (): FieldProp => ({
      name: 'name',
      type: 'textbox',
      label: 'Patient Name',
      placeholder: 'Patient Name',
    });

    const getDOBField = (): FieldProp => ({
      name: 'dob',
      type: 'date',
      label: 'Patient Date of Birth',
      placeholder: 'mm/dd/yyyy',
      openCalender: false,
    });

    const getPhysicianField = (): FieldProp | null => {
      if (!isProvider && !isAdminRole) return null;

      if (providersForDropdown.length === 1) {
        const providerGroup = providers.find(
          ({ name: providerName }: Provider) => providerName === providersForDropdown[0].text,
        )?.group;
        if (!providerGroup) return null;

        const remainingProviderInGroup = providers.filter(
          (providerInfo: Provider) =>
            providerInfo.name !== providersForDropdown[0].text && providerInfo.group?.id === providerGroup.id,
        );

        if (remainingProviderInGroup.length === 0) {
          return null;
        }
      }

      let updatedProviders;

      if (providersForDropdown.length > 0) {
        updatedProviders = [
          {
            key: showAllProviders,
            text: showAllProviders,
            value: showAllProviders,
          },
          ...providersForDropdown,
        ];
      } else {
        return null;
      }

      return {
        name: 'physician',
        type: 'select',
        label: 'Provider Name',
        placeholder: 'Provider',
        hint: <i>(50 characters maximum)</i>,
        option: updatedProviders,
      };
    };

    const getDoctorGroupField = (): FieldProp | null => {
      if (!isAdminRole) return null;
      return {
        name: 'doctorGroup',
        type: 'select',
        label: 'Provider Group',
        upward: true,
        placeholder: 'Provider Group',
        hint: <i>(50 characters maximum)</i>,
        option: providersGroup,
      };
    };

    const getLastStatusChangeField = (): FieldProp => ({
      name: 'lastStatusChange',
      type: 'radioList',
      label: 'Filter by Last Status Change',
      option: filterByLastStatusChange,
      skipTagging: true,
      addClasses: 'filter-label',
    });

    const getOrderStatusField = (): FieldProp => ({
      name: 'orderStatus',
      type: 'radioList',
      label: 'Filter by Order Status',
      option: filterByOrderStatusChange,
      skipTagging: true,
      addClasses: 'filter-label',
    });

    return [
      getStatusBTWIPField(),
      getStatusUnknownField(),
      getPatientBrightreeIdField(),
      getNameField(),
      getDOBField(),
      getPhysicianField(),
      getDoctorGroupField(),
      getLastStatusChangeField(),
      getOrderStatusField(),
    ].filter((field) => field !== null) as FieldProp[];
  }, [role?.code, isProvider, providersForDropdown, providers, providersGroup]);

  const [filterBy, updateFilterBy] = useState({} as FilterFormProps);

  const ActionButtons = (
    <InputButton
      text="Export"
      onClick={async () => {
        setLoadingOnTableAction(true);
        downloadPatientsCSV({
          variables: {
            searchOptions: { ...searchOptions },
          },
        }).then(({ data }) => {
          setLoadingOnTableAction(false);
          downloadFile(data.downloadPatientsCSV, 'Patient_Status', 'csv');
        });
      }}
    />
  );

  const filterActions: ActionProp[] = [
    {
      name: 'search',
      label: 'FILTER',
      addClasses: 'mb-0',
      onClick: (_, data) => {
        const searchQuery = Object.entries(data)
          .filter(([, value]) => value !== '')
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        updateFilterBy({
          ...filterBy,
          ...searchQuery,
          submitted: true,
        });
        setSearchParams(searchQuery);
      },
    },
    {
      name: 'clearFilter',
      label: 'Clear filters',
      addClasses: 'mb-0 btn-secondary',
      onClick: () => {
        setSearchParams({
          lastStatusChange: filterByLastStatusChange[0].value,
          orderStatus: filterByOrderStatusChange[0].value,
        });
        updateFilterBy({
          name: '',
          dob: '',
          physician: '',
          providerGroup: '',
          submitted: false,
          patientBrightreeId: '',
        });
      },
    },
  ];

  useEffect(() => {
    const params = Array.from(searchParams.entries());

    if (params.length === 0) {
      let defaultSearchParams: { [k: string]: any } = {
        lastStatusChange: filterByLastStatusChange[0].value,
        orderStatus: filterByOrderStatusChange[0].value,
      };

      if (isProvider && provider?.name) {
        defaultSearchParams = {
          ...searchParams,
          ...defaultSearchParams,
          physician: provider.name,
        };
      }
      setSearchParams(defaultSearchParams);
    }

    setIsComponentMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastSynced = useMemo(() => {
    const isSuperAdmin = currentLoggedInUser?.role.code === ROLES.superadmin;

    let message = '';

    const lastSyncedInfo = lastSyncData?.lastSynced;

    if (lastSyncedInfo && lastSyncedInfo.length) {
      const status = lastSyncedInfo[0].status;
      let lastSuccessfulSyncedAt = lastSyncedInfo[0].lastSyncedAt;

      if (status === 'FAILED' && lastSyncedInfo.length === 2) {
        const lastFailedSyncedAt = lastSyncedInfo[0].lastSyncedAt;
        lastSuccessfulSyncedAt = lastSyncedInfo[1].lastSyncedAt;

        if (lastFailedSyncedAt && lastSuccessfulSyncedAt) {
          message = `Data was last updated on ${convertToPST(lastSuccessfulSyncedAt)} PT${
            isSuperAdmin ? ` - most recent data sync attempt <b>failed</b> on ${convertToPST(lastFailedSyncedAt)} PT` : ''
          }`;
        }
      } else if (status === 'SUCCESS') {
        message = `Data was last updated on ${convertToPST(lastSuccessfulSyncedAt)} PT${
          isSuperAdmin ? ' - most recent data sync attempt <b>succeeded</b>' : ''
        }`;
      }
    }

    return message;
  }, [currentLoggedInUser?.role.code, lastSyncData?.lastSynced]);

  if (error) {
    console.log(error);
    setShowErrorModal(true);
  }

  return (
    <>
      <Item as="div" className="body-content Provider-Status">
        <ProviderSubHeader pageTitle={PageTitle} pageTitleHint={lastSynced} ActionButton={ActionButtons} />
        {!isComponentMounted || providers.length === 0 ? (
          <Loading show={true} />
        ) : (
          <Container fluid className="with-sidebar">
            <Filter fields={filterFields} actions={filterActions} />
            <Item as="div" id="scrollTo" className="content">
              <PatientStatusTable onSearchOptionsChange={(selectedSearchOptions) => setSearchOptions(selectedSearchOptions)} />
            </Item>
          </Container>
        )}
      </Item>
      {isLoadingOnTableAction && <Loading show={true} />}

      {showErrorModal && (
        <ModalPopup
          showPopup
          title="Error"
          body="Error occurred while downloading the Patients, please try again.  If same error repeats, please reach support team."
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </>
  );
};
