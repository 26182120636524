import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import { PAGE_LINKS } from '../../utilities';

const Home = () => {
  const { user: currentLoggedInUser } = useContext(GlobalContext);
  const userRole = currentLoggedInUser?.role?.code;

  if (!currentLoggedInUser) {
    return <Navigate to={PAGE_LINKS.login} />;
  }

  if (userRole === 'registrationadmin') {
    return <Navigate to={PAGE_LINKS.administrators} />;
  }

  if (userRole === 'missinginformation' || userRole === 'superadmin') {
    return <Navigate to={PAGE_LINKS.missingInformation} />;
  }

  return <Navigate to={PAGE_LINKS.patients} />;
};

export default Home;
