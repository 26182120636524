import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Image, Item } from 'semantic-ui-react';
import logo from '../../../assets/img/bn-logo.png';
import { useCountdownTimer } from '../../../hooks';
import './ProviderHeader.scss';

interface ProviderHeaderProps {
  toggleMenu?: () => any;
  showAppName?: boolean;
}

const ProviderHeader = ({ toggleMenu, showAppName }: ProviderHeaderProps) => {
  const [isActive, setActive] = useState(false);
  const countDownTimer = useCountdownTimer();
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setActive(!isActive);
    if (toggleMenu) {
      toggleMenu();
    }
  };
  return (
    <Item as="div" className="provider-header">
      {toggleMenu && (
        <Item as="div" className="header-content">
          <Item as="div" className="toggle-btn" onClick={toggleSidebar}>
            <Item as="span"></Item>
            <Item as="span"></Item>
            <Item as="span"></Item>
          </Item>
          <Header as="h3">BetterNight Provider Portal</Header>
          <p style={{ display: 'none' }}>{countDownTimer}</p>
        </Item>
      )}

      {showAppName && (
        <Item as="div" className="header-content ">
          <Item as="div" className="titles"></Item>
          <Header as="h3">BetterNight Provider Portal</Header>
        </Item>
      )}

      <div onClick={() => navigate('/', { replace: true })}>
        <Image src={logo} />
      </div>
    </Item>
  );
};

export default ProviderHeader;
