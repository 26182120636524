import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Modal } from 'semantic-ui-react';
import { InputButton, InputDateField, InputField } from '../../../components/controls';
import { mutationUpdatePatientMissingInfoNextContactDate, mutationUpdatePatientMissingInfoSalesOrder } from '../../../services';

export type ModalPopupContactingProps = {
  patientMissingInfoId: number;
  name: string;
  brightreePatientId: number;
  mrn: number;
  value: string;
  modalFor: 'salesOrder' | 'nextContactDate';
  onAction: (actionName: 'save' | 'cancel') => void;
};

const ModalPopupContacting = ({
  patientMissingInfoId,
  name,
  brightreePatientId: brightreeId,
  mrn,
  value,
  modalFor,
  onAction,
}: ModalPopupContactingProps) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [errorMessage, setError] = useState('');

  const toastId = React.useRef<string | number | null>(null);
  const showToaster = (message: string, color = false) => {
    if (toastId.current !== null) {
      toast.dismiss(toastId.current);
    }

    let toasterOptions: any = { autoClose: 5000 };

    if (color) {
      toasterOptions = { ...toasterOptions, theme: 'colored' };
    }

    toastId.current = toast.error(message, toasterOptions);
  };

  const [updatePatientMissingInfoNextContactDate, { loading: apiUpdateNextContactDateLoading }] = useMutation(
    mutationUpdatePatientMissingInfoNextContactDate,
    {
      onError() {
        const message = `Error occurred while updating the record.`;
        showToaster(message, true);
      },
    },
  );

  const [updatePatientMissingInfoSalesOrder, { loading: apiUpdateSalesOrderLoading }] = useMutation(
    mutationUpdatePatientMissingInfoSalesOrder,
    {
      onError(errors) {
        const { graphQLErrors } = errors;
        let showToasterMessage = true;
        let errorMessageDetails = '';
        if (graphQLErrors && graphQLErrors.length > 0) {
          graphQLErrors.forEach((error) => {
            if (error.message.toLowerCase().includes('sales order')) {
              showToasterMessage = false;
              setError(error.message);
              return;
            }

            errorMessageDetails = error.message;
            return;
          });
        } else {
          if (errors && Array.isArray(errors)) {
            errorMessageDetails = errors.map((error) => error.message).join(', ');
          }
        }

        if (showToasterMessage) {
          showToaster(errorMessageDetails, true);
        }
      },
    },
  );

  const handleAction = async (selectedAction: 'save' | 'cancel') => {
    if (selectedAction === 'cancel') {
      onAction(selectedAction);
      return;
    }

    let isFormValid = true;

    if (modalFor === 'nextContactDate') {
      if ((selectedValue + '').trim() === '') {
        setError('The next contact date is required.');
        isFormValid = false;
      } else {
        const currentDate = new Date();
        const givenDate = new Date(selectedValue);
        currentDate.setHours(0, 0, 0, 0);
        givenDate.setHours(0, 0, 0, 0);
        if (givenDate < currentDate) {
          setError('The next contact date must not be in the past.');
          isFormValid = false;
        }
      }

      if (isFormValid) {
        const result = await updatePatientMissingInfoNextContactDate({
          variables: {
            patientMissingInfoInput: {
              id: patientMissingInfoId,
              nextContactDate: selectedValue,
            },
          },
        });

        if (
          result?.data?.updatePatientMissingInfoNextContactDate ===
          'Patient Missing info record Next contact date updated successfully.'
        ) {
          if (toastId.current !== null) {
            toast.dismiss(toastId.current);
          }

          toastId.current = toast('Update successful.');
          onAction(selectedAction);
        }
      }
    } else if (modalFor === 'salesOrder') {
      if ((selectedValue + '').trim() === '') {
        setError('A Brightree sales order number is required.');
        isFormValid = false;
      }

      if (isFormValid) {
        const result = await updatePatientMissingInfoSalesOrder({
          variables: {
            patientMissingInfoInput: {
              id: patientMissingInfoId,
              salesOrder: +selectedValue,
            },
          },
        });

        if (
          result?.data?.updatePatientMissingInfoSalesOrder === 'Patient Missing info record Sales order updated successfully.'
        ) {
          if (toastId.current !== null) {
            toast.dismiss(toastId.current);
          }

          toastId.current = toast('Update successful.');
          onAction(selectedAction);
        }
      }
    }
  };

  return (
    <Modal
      className="modal-popup-patient-provider-contacting"
      closeIcon
      size="tiny"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={true}
      onClose={() => handleAction('cancel')}
    >
      <Modal.Header>
        Edit {modalFor === 'nextContactDate' ? 'Next Contact Date' : modalFor === 'salesOrder' ? 'Brightree Sales Order' : ''}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <div className="patient-data">
            <label>MRN</label>
            <span>{mrn}</span>
            <label>Name</label>
            <span>{name}</span>
            <label>Brightree ID</label>
            <span>{brightreeId}</span>
            {modalFor === 'nextContactDate' ? (
              <>
                <label className="label-input-field">Next Contact Date</label>
                <div className="date-picker">
                  <InputDateField
                    name="nextContactDate"
                    AddClass="inline"
                    label=""
                    placeholder="mm/dd/yyyy"
                    maxDate={new Date('12/31/9999')}
                    value={selectedValue === '' ? null : new Date(selectedValue)}
                    onChange={(selectedDate) => {
                      setError('');
                      setSelectedValue(selectedDate + '');
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <label className="label-input-field">Brightree Sales Order</label>
                <InputField
                  type="numberString"
                  name="salesOrder"
                  label=""
                  placeholder="Enter SO ID"
                  value={selectedValue}
                  maxLength={10}
                  onChange={(e) => {
                    setError('');
                    if (e.target.value.length <= 10) {
                      setSelectedValue(e.target.value);
                    }
                  }}
                />
              </>
            )}
          </div>
          {errorMessage !== '' && <div className="error-message">{errorMessage}</div>}
        </Form>
      </Modal.Content>
      <Modal.Actions className="has-custom-buttons">
        <InputButton
          type="button"
          addCssClasses="btn-secondary"
          name="cancel"
          text="Cancel"
          onClick={() => handleAction('cancel')}
          inline={true}
        />
        <InputButton
          type="button"
          addCssClasses=""
          name="save"
          text="Save"
          disabled={value === selectedValue}
          loading={apiUpdateNextContactDateLoading || apiUpdateSalesOrderLoading}
          onClick={() => handleAction('save')}
          inline={true}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalPopupContacting;
