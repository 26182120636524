import React, { useState } from 'react';
import { Form, Item, Modal, ModalProps } from 'semantic-ui-react';
import { InputButton, InputField, StringField, TextAreaField } from '../../components/controls';
import { GlossaryPatientStatus } from '../../types/glossary-patient-status.type';
import './Glossary.scss';

export type GlossaryEditProp = {
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  showPopup: boolean;
  closeOnEscape?: boolean;
  closeOnDimmerClick?: boolean;
  onSave?: (udpatedData: GlossaryPatientStatus[]) => void;
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>, data?: ModalProps) => void;
  data: GlossaryPatientStatus;
};

const btWIPs = ['BT_CONSULT_WIP', 'BT_HST_WIP', 'BT_THERAPY_WIP'];
const btVoids = ['BT_CONSULT_VOID', 'BT_HST_VOID', 'BT_THERAPY_VOID'];

export const GlossaryEdit = ({
  showPopup,
  size = 'tiny',
  closeOnDimmerClick = false,
  closeOnEscape = false,
  onClose,
  onSave,
  data,
}: GlossaryEditProp) => {
  const { stateId, stateDescription, patientStatus, patientStatusDefinition, stateCode } = data;

  const [ps, setPS] = useState(patientStatus);
  const [psDesc, setPSDesc] = useState(patientStatusDefinition);

  const handleSave = () => {
    const udpatedData: GlossaryPatientStatus[] = [];

    stateCode?.split(', ').forEach((stateCodeValue) => {
      udpatedData.push({
        ...data,
        stateCode: stateCodeValue,
        patientStatus: (ps + '').trim(),
        patientStatusDefinition: (psDesc + '').trim(),
      });
    });

    if (onSave) {
      onSave(udpatedData);
    }
  };

  const btState = btWIPs.includes(stateCode) ? 'Brightree Missing Information' : btVoids.includes(stateCode) ? 'Brightree Voided' : '';

  const getStateCode = () => {
    if (!stateCode.includes('CLOSED_')) {
      return stateCode;
    }

    const stateCodes = stateCode.split(', ').map((record) => (record.includes('CLOSED_') ? 'CLOSED' : record));
    return [...new Set(stateCodes)].join(', ');
  };

  return (
    <Modal
      size={size}
      closeOnEscape={closeOnEscape}
      closeOnDimmerClick={closeOnDimmerClick}
      open={showPopup}
      onClose={onClose}
      className="GlossaryEditModal"
    >
      <Modal.Header>Edit Glossary</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field inline>
            <label>Backend state</label>
            <Item className="sub-fields">
              <StringField label="ID:" text={stateId + ''} />
              <StringField label="State:" text={btState !== '' ? btState : getStateCode()} />
              <StringField label="Description:" text={btState !== '' ? btState : stateDescription} />
            </Item>
          </Form.Field>
          <InputField
            type="text"
            name="label"
            label="Label"
            placeholder="Label"
            hint="(50 characters maximum)"
            required
            maxLength={50}
            value={ps}
            onChange={({ target: { value } }) => setPS(value)}
            // error={formSubmitted && renderError('patientStatus')}
          />
          <TextAreaField
            name="description"
            label="Description"
            placeholder="Description"
            hint="(600 characters maximum)"
            required
            maxLength={600}
            value={psDesc}
            onChange={({ target: { value } }) => {
              setPSDesc(value);
            }}
            // error={formSubmitted && renderError('description')}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <InputButton addCssClasses="btn-secondary" onClick={(e) => onClose && onClose(e)} text="CANCEL" />
        <InputButton requiredHintText text="SAVE" disabled={!(ps + '').trim() || !(psDesc + '').trim()} onClick={handleSave} />
      </Modal.Actions>
    </Modal>
  );
};
